import React, { Component } from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DotsIcon from '../../../../../assets/images/module/general/DotsIcon.svg';
import PenIcon from '../../../../../assets/images/module/general/PenIcon.svg';
import PrintIcon from '../../../../../assets/images/module/general/PrintIcon.svg';
import RedTrashIcon from '../../../../../assets/images/module/general/RedTrashIcon.svg';
import LockOpenIcon from '../../../../../assets/images/module/general/LockOpenIcon.svg';
import LockIcon from '../../../../../assets/images/module/general/LockIcon.svg';
import ResetIcon from '../../../../../assets/images/module/general/ResetIcon.svg';
import { ValueText } from '../../../../index';
import Autoload from '../../../../Autoload';
import { Group } from '@mantine/core';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default].general;

export class RecordOptions extends Component {
  state = {
    dropdownOpen: false,
  };
  toggle = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };
  render() {
    const { showDeleteButton = true, showEditButton = true, showLockButton = false, showPrintButton = false, isSecured = false, style, menuStyle, showAllocationKeyValuesEditButton=false, editButtonText=null, deleteButtonText=null, disabled=false, buttonStyle, listOfItems=null, customButtonAction=null, printButtonText=null } = this.props;
    return (
      <>
        <ButtonDropdown className="record-options" isOpen={this.state.dropdownOpen} style={style} 
        toggle={(e) => {
          if(!customButtonAction){
            e.stopPropagation(); !disabled && this.toggle()
          }
        }}>
          <DropdownToggle style={buttonStyle} onClick={()=> !disabled && this.props.getRecord && this.props.getRecord()} className="record-dropdown">
            <img src={DotsIcon} alt="dots-icon" width="16px" style={{minWidth: '16px'}} height="8px" />
          </DropdownToggle>
          <DropdownMenu className="record-dropdown-menu edit-delete-dropdown" style={menuStyle}>
            {listOfItems ?
            <>
              {listOfItems.map((item)=>
              <DropdownItem disabled={item.disabled} className={item.className} style={item.style}  onClick={() => item.openFunction()}>
                <Group noWrap>
                  {item.leftImage}
                  <span>{item.text}</span>
                </Group>
              </DropdownItem>)}
              {showEditButton && (
              <DropdownItem onClick={() => this.props.toggleEditModal()}>
                {/* <ValueText text={editButtonText ? editButtonText : lang.text_edit} leftImage={{ img: PenIcon }} /> */}
                <Group noWrap>
                  <img src={PenIcon} width={'15px'} />
                  <span>{editButtonText ? editButtonText : lang.text_edit}</span>
                </Group>
              </DropdownItem>
              )}
              {showDeleteButton && (
                <DropdownItem onClick={() => this.props.toggleDeleteModal()}>
                  {/* <ValueText className="tc-red" text={deleteButtonText ? deleteButtonText : lang.text_delete} leftImage={{ img: RedTrashIcon }} /> */}
                  <Group className="tc-red" noWrap>
                    <img src={RedTrashIcon} width={'15px'} />
                    <span>{deleteButtonText ? deleteButtonText : lang.text_delete}</span>
                  </Group>
                </DropdownItem>
              )}
            </>
            :
            <>
            {showEditButton && (
              <DropdownItem onClick={() => this.props.toggleEditModal()}>
                <ValueText text={editButtonText ? editButtonText : lang.text_edit} leftImage={{ img: PenIcon }} />
              </DropdownItem>
            )}
            {showAllocationKeyValuesEditButton && (
              <DropdownItem onClick={() => this.props.toggleAllocationKeyValuesEditModal()}>
                <ValueText text={lang.text_allcation_key_values_edit} leftImage={{ img: PenIcon }} />
              </DropdownItem>
            )}
            {showDeleteButton && (
              <DropdownItem onClick={() => this.props.toggleDeleteModal()}>
                <ValueText className="tc-red" text={deleteButtonText ? deleteButtonText : lang.text_delete} leftImage={{ img: RedTrashIcon }} />
              </DropdownItem>
            )}
            {/* <DropdownItem onClick={()=>this.props.toggleDeleteModal()} disabled={showDeleteButton}>
                <ValueText className='tc-red' text={lang.text_delete} leftImage={{img:RedTrashIcon}} />
              </DropdownItem> */}
            {/* {data.isSecured && <img src={LockIcon} />} */}
            {showLockButton && (
              <DropdownItem onClick={() => this.props.lockUtilityBilling()}>
                <ValueText text={isSecured ? lang.text_unfreez : lang.text_freez} leftImage={{ img: isSecured ? LockOpenIcon : LockIcon, style:{width: 18, height: 20, marginLeft: -2} }} />
              </DropdownItem>
            )}
            {showPrintButton && (
              <DropdownItem onClick={() => this.props.onPrint()}>
                <ValueText text={printButtonText ? printButtonText : 'Drucken'} leftImage={{img: PrintIcon}} />
              </DropdownItem>
            )}
            </>
            } 
          </DropdownMenu>
        </ButtonDropdown>
      </>
    );
  }
}
RecordOptions.defaultProps = {
  toggleEditModal: () => {},
  toggleDeleteModal: () => {},
};
export default RecordOptions;
