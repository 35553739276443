import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { ButtonGroup, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { Group, Popover, Progress, RingProgress, Skeleton, Stack, Text, Tooltip as Ttip } from '@mantine/core';
import { IconButton, Tooltip } from '@material-ui/core';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import AddIcon from '@material-ui/icons/Add';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import InfoIcon from '@material-ui/icons/Info';
import DocumentsList from './DocumentsList';
import HeadingContainerNew from '../../../../neoverv/components/Module/General/HeadingContainerNew';
import breadcrumbsContent from '../../../../assets/breadcrumbs.json';
import HelpLogo from '../../../../assets/images/module/general/book.svg';
import TourIcon from '../../../../assets/images/module/general/TourIcon.svg';
import FileMoveIcon from '../../../../assets/images/module/general/FileMoveIcon.svg';
import GoldenLockIcon from '../../../../assets/images/module/general/GoldenLockIcon.svg';
import { ColorButton, FiltersContainer, HeadingContainer, SearchContainer } from '../../../../neoverv';
import Autoload from '../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import { formatNumber } from '../../../../helpers/stringsManipulations';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

class List extends Component {
  state = {
    skeletonLoading: false,
    hoverMemory: false,
    memoryStatus: {usedSpaceInBytes: 0},
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
  };

  componentDidMount(){
    this.getDocumentsStatusByAccountId();
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(){
    if(this.props.refreshDocumentsData){
      this.getDocumentsStatusByAccountId();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {

    // TODO: this causes data refetch on the meters reading details screen...
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
    if (window.innerWidth <= TABLET_SIZE) {
      this.setState({ isTablet: true });
    } else {
      this.setState({ isTablet: false });
    }
    
  };

  convertFileSize=(sizeString)=>{
    if(sizeString){
      if(sizeString.includes('KB')){
        return parseFloat(sizeString) / 1000;
      }else if(sizeString.includes('GB')){
        return parseFloat(sizeString) * 1000;
      }else if(sizeString.includes('TB')){
        return parseFloat(sizeString) * 1000000;
      }else if(sizeString.includes('B') && (!sizeString.includes('M') || !sizeString.includes('G') || !sizeString.includes('T') || !sizeString.includes('K'))){
        return parseFloat(sizeString) / 1000000;
      }else {
        return parseFloat(sizeString);
      }
    }else{
      return 0;
    }
  }

  getDocumentsStatusByAccountId=()=>{
    const {accountData} = this.props;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.document_api_base_url + 'documents/status?accountId=' + accountData.accountId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetDocumentsStatusByAccountId',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleGetDocumentsStatusByAccountId(response){
    if(response.status && response.statuscode === 200){
      this.setState({memoryStatus: response.result});
      window.Intercom("update", {
        api_base: "https://api-iam.intercom.io",
        app_id: "vvrs5bx3",
        //'Documents file size': response.result.usedSpace
        'document file size': (Math.round(response.result.usedSpaceInBytes / (1024*1024) * 100) / 100)
      });
    }
  }

  renderStorageCapacity=()=>{
    const {hoverMemory, isMobile} = this.state;
    return(
      <div onTouchStart={()=> this.setState({hoverMemory: !hoverMemory})} onMouseEnter={()=> this.setState({hoverMemory: true})} onMouseLeave={()=> this.setState({hoverMemory: false})}>
        {isMobile ?
        <Group style={{padding: '3px 9px 0px'}}>
          <InfoIcon
          style={{
            fontSize: '18px',
            color: '#5555FF'
          }}
          />
        </Group>
        :
        this.renderRingProgress()}
      </div>
    )
  }

  renderRingProgress=()=>{
    const {memoryStatus, isMobile} = this.state;
    const {currentSubscription} = this.props;
    return(
      <RingProgress
      sections={[{ value: (memoryStatus?.usedSpaceInBytes/1000000) / (currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER' ? 100 : currentSubscription?.subscriptionKey.split('_')[0] === 'PREMIUM' ? 1000 : currentSubscription?.subscriptionKey.split('_')[0] === 'PROFESSIONAL' && 2000) * 100 //this.convertFileSize(memoryStatus?.usedSpace) 
      , color: '#5555FF' }]}
      label={
      <Text color="#5555FF" weight={700} align="center" size={isMobile ? "sm" : "md"}>
      {`${(Math.round((memoryStatus?.usedSpaceInBytes/1000000) / (currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER' ? 100 : currentSubscription?.subscriptionKey.split('_')[0] === 'PREMIUM' ? 1000 : currentSubscription?.subscriptionKey.split('_')[0] === 'PROFESSIONAL' && 2000) * 100 * 10) / 10).toString().replace('.', ',')}%`}
      </Text>}
      size={isMobile ? 60 : 80}
      thickness={5}
      roundCaps
      styles={{
        label: { color: 'red' },
      }}
      />
    )
  }

  render() {
    const {skeletonLoading, hoverMemory, memoryStatus, isMobile} = this.state;
    const {propertySelected, currentSubscription, accountData} = this.props;

    return (
      <div className="documents-main-container">
        <HeadingContainerNew
          heading={!isMobile && 'Dokumente'}
          headingClassName={'documents-heading tooltips'}
          
          className="documents-heading-container"
          rightContent={
            <Group>
              <Popover
              opened={hoverMemory}
              //label={renderTtip}
              //transitionDuration={1000}
              target={this.renderStorageCapacity()}
              withArrow
              styles={{
                body: { //background: '#E3E3FC', 
                  boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 10px' },
                arrow: { //background: '#E3E3FC' 
              },
              }}
              style={{width: '100%', cursor: 'pointer'}}
              position="left" 
              placement="center"
              transitionDuration={800}
              >
                <Stack style={{width: isMobile ? '250px' : '190px'}} spacing={5} onMouseEnter={()=> this.setState({hoverMemory: true})} onMouseLeave={()=> this.setState({hoverMemory: false})}>
                  <Group noWrap>
                    <Stack spacing={10} position={'apart'}>
                      <Stack spacing={5}>
                        <span className='documents-header-right-section' style={{fontWeight: '500'}}><span style={{fontWeight: 'bold', color: '#5555FF'}}>{memoryStatus?.usedSpace ? (memoryStatus.usedSpace.includes('M') || memoryStatus.usedSpace.includes('K') || memoryStatus.usedSpace.includes('G') || memoryStatus.usedSpace.includes('T')) ? memoryStatus.usedSpace.replace('.', ',').split(/(?=.{2}$)/).join(' ') : memoryStatus.usedSpace.replace('.', ',').split(/(?=.{1}$)/).join(' ') : '0 KB'}</span> von <span style={{fontWeight: 'bold', color: '#5555FF'}}>{currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER' ? '100 MB' : currentSubscription?.subscriptionKey.split('_')[0] === 'PREMIUM' ? ((memoryStatus?.usedSpaceInBytes/1000000) > 1000 ? Math.round((memoryStatus?.usedSpaceInBytes/1000000)) + 1 : '1 GB') : currentSubscription?.subscriptionKey.split('_')[0] === 'PROFESSIONAL' && ((memoryStatus?.usedSpaceInBytes/1000000) > 2000 ? Math.round((memoryStatus?.usedSpaceInBytes/1000000)) +1 : '2 GB')}</span> verwendet.</span>
                        {currentSubscription?.subscriptionName === 'Starter' ? 
                        <span className='documents-header-right-section' style={{fontWeight: '400'}}>Schalten Sie <span style={{fontWeight: 'bold', color: '#5555FF'}}>1 GB</span> Speicherplatz frei mit <span style={{fontWeight: 'bold'}}>hellohousing Premium.</span><img src={GoldenLockIcon} width='11px' style={{marginLeft: '6px', marginTop: '-4px'}} /></span> 
                        :
                        <>
                          <span className='documents-header-right-section' style={{fontWeight: '400'}}>Ihr Abonnement beinhaltet <span style={{fontWeight: 'bold', color: '#5555FF'}}>{currentSubscription?.subscriptionKey.split('_')[0] === 'PREMIUM' ? '1 GB' : '2 GB'}</span> Speicherplatz. Jedes GB zusätzlicher Speicherplatz nur 1 EUR / Monat.</span>
                          {currentSubscription?.subscriptionKey.split('_')[0] === 'PREMIUM' && (memoryStatus?.usedSpaceInBytes/1000000) > 1000 ?
                            <span className='documents-header-right-section' style={{fontWeight: '400'}}>Sie nutzen derzeit <span style={{fontWeight: 'bold', color: '#5555FF'}}>{(memoryStatus?.usedSpaceInBytes/1000000) - 1000}</span> zusätzlichen Speicherplatz.</span>
                            :
                            currentSubscription?.subscriptionKey.split('_')[0] === 'PROFESSIONAL' && (memoryStatus?.usedSpaceInBytes/1000000) > 2000 &&
                            <span className='documents-header-right-section' style={{fontWeight: '400'}}>Sie nutzen derzeit <span style={{fontWeight: 'bold', color: '#5555FF'}}>{(memoryStatus?.usedSpaceInBytes/1000000) - 2000}</span> zusätzlichen Speicherplatz.</span>
                          }
                        </>
                        }
                      </Stack>
                        {currentSubscription?.subscriptionName === 'Starter' && 
                        <ColorButton
                        onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})}
                        className={"purple-btn"}
                        text={currentSubscription ? (currentSubscription?.subscriptionName === 'Starter' ? (accountData.isPremiumPlanTested === false ? 'Jetzt freischalten' : 'Jetzt freischalten') : currentSubscription?.subscriptionName === 'Premium' ? (accountData.isProfessionalPlanTested === false ? 'Jetzt freischalten' : 'Jetzt freischalten') : 'Jetzt freischalten') : 'Jetzt freischalten'}
                        />}
                    </Stack>
                    {isMobile && this.renderRingProgress()}
                  </Group>
                </Stack>
                {/* <Progress
                style={{width: '100%'}}
                size={'lg'}
                // sections={[
                //   { value: 40, color: '#13135C' },
                //   { value: 20, color: '#5555FF' },
                //   { value: 15, color: '#8B88F5' },
                //   { value: 10, color: '#E3E3FC' }
                // ]}
                value={75}
                label={'75%'}
                color={'#5555FF'}
                /> */}
              </Popover>
            </Group>
          }
          breadcrumbsClassName={'documents-breadcrumbs'}
          breadcrumbs={<div style={{display: 'flex'}}>
          {breadcrumbsContent.map((item)=> item.area.includes('documents') && 
          item.breadcrumbs.map((breadcrumb)=>
          breadcrumb.type === 'tour' ? breadcrumb.condition === 'rental_unit_created' && breadcrumb.view === 'desktop' &&
          <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('startTour', breadcrumb.id)}>
            <Skeleton visible={skeletonLoading}>
              <img src={TourIcon} style={{marginTop: '-3px'}} />
              <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
            </Skeleton>
          </div>
          :
          breadcrumb.view === 'desktop' &&
          <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('showArticle', breadcrumb.id)}>
            <Skeleton visible={skeletonLoading}>
              <img src={HelpLogo} style={{marginTop: '-3px'}} />
              <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
            </Skeleton>
          </div>
          ))}
          </div>}
        />
        <div>
          <Route path={this.props.match.url} exact //component={DocumentsList} 
          render={(props)=> <DocumentsList {...props} isMobile={isMobile}/>}/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
    currentSubscription: state.generalReducer.currentSubscription,
    accountData: state.generalReducer.accountData,
    refreshDocumentsData: state.generalReducer.refreshDocumentsData,
  };
};

export default connect(mapStateToProps, null)(withRouter(List));
