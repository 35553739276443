import React, { Component } from 'react';
import Autoload from '../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import MeterEmailCard from './MeterEmailCard';
import { Box, Group, Overlay, Skeleton, Stack } from '@mantine/core';
import MeterDataMapping from './MeterDataMapping';
import MeterUploadsLog from './MeterUploadsLog';
import MeterManualData from './MeterManualData';

import { AddPropertyModal, ColorButton, EmptyStateContainer } from '../../../../neoverv';
import MeterIcon from '../../../../assets/images/module/general/blueEmptyMeter.svg';
import HelpLogo from '../../../../assets/images/module/general/book.svg';
import TourIcon from '../../../../assets/images/module/general/TourIcon.svg';
import breadcrumbsContent from '../../../../../src/assets/breadcrumbs.json';
import { TENANT_ROUTE } from '../../../../routes/module/RouteName';
import GlobalServices from '../../../../neoverv/helpers/services/GlobalServices';
import GeneralDataHandler from '../../../../neoverv/helpers/DataHandler/General';
import AddPropertyTypeModal from '../../../../neoverv/components/Module/General/CustomModal/AddPropertyTypeModal';

var Setting = Autoload.Setting;
var {loader} = Autoload.MainHelper;
var lang = Setting[Setting.language_default];
const MOBILE_SIZE = 992;
const TABLET_SIZE = 1420;

export class MeterDataTransfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= MOBILE_SIZE,
      isTablet: window.innerWidth <= TABLET_SIZE,
      addPropertyModal: false,
      rentalUnits: [],
      skeletonLoading: false,
      addPropertyTypeModal: false,
      selectedPropertyType: '',
      meterMappings: null,
      refreshLogs: false
    };

  }

  componentDidMount = () => {
    window.addEventListener('resize', this.handleResize);
    this.setState({skeletonLoading: true});
    this.props.propertySelected && GlobalServices._getRentalUnitsByProperty(this, '_handle_rental_unit_response', this.props.propertySelected);
    this.getMeterReadingMappings();
  };

  getMeterReadingMappings = () => {
    Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.meter_api_base_url + 'meterReadingMappings?propertyId='+ this.props.propertySelected.id,
        'GET',
        null,
        null,
        false,
        true,
        true,
        this,
        '_handleGetMeterReadingMappings',
        null,
        false,
        '',
        { 'Content-Type': 'application/json', Authorization: Autoload.Utils.getAuthToken() }
      );
  }

  _handleGetMeterReadingMappings(response) {
    console.log('_handleGetMeterReadingMappings: ', response);
    if(response.status){
        this.setState({meterMappings: response.result.map((meterMapping)=> {
          return {
            ...meterMapping,
            value: meterMapping.id,
            label: meterMapping.name
          }
        })});
    }
    this.setState({skeletonLoading: false});
  }

  //@desc rental units response handler
  _handle_rental_unit_response(response) {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Error :  ' + response.message);
    }
    const rentalUnits = GeneralDataHandler.getRantalUnits(response.result);

    this.setState({ rentalUnits });
  }
  
  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
    if (window.innerWidth <= TABLET_SIZE) {
      this.setState({ isTablet: true });
    } else {
      this.setState({ isTablet: false });
    }
  };

  toggleAddPropertyModal = () => {
    this.setState({ addPropertyModal: !this.state.addPropertyModal });
  };
  toggleAddPropertyTypeModal = () => {
    this.setState({ addPropertyTypeModal: !this.state.addPropertyTypeModal });
  };

  
  render() {
    const {isMobile, isTablet, rentalUnits, skeletonLoading, meterMappings} = this.state;
    const {currentSubscription, accountData} = this.props;
    return (
        <>
        <div style={{display: 'flex', marginBottom: '14px'}}>
            {!skeletonLoading && breadcrumbsContent.map((item)=> item.area === '/meter/data_transfer' && 
            item.breadcrumbs.map((breadcrumb)=>
            breadcrumb.type === 'tour' ? breadcrumb.view === (isMobile ? 'mobile' : 'desktop') &&
            <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('startTour', breadcrumb.id)}>
              <img src={TourIcon} />
              <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
            </div>
            :
            breadcrumb.view === (isMobile ? 'mobile' : 'desktop') &&
            <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('showArticle', breadcrumb.id)}>
              <img src={HelpLogo} />
              <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
            </div>
            ))}
        </div>
        <div className="divider"></div>
        
            <Box sx={{ height: '100%', position: 'relative', marginTop: currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER' && '40px'}}>
            {(currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER' || currentSubscription?.subscriptionKey.split('_')[0] === 'PREMIUM') &&
            <Stack align={'center'} style={{ zIndex: 50, position: 'absolute', left: 0, right: 0, marginRight: 'auto', marginLeft: 'auto', marginTop: '200px'}}>
              <Stack spacing={0}>
                <span style={{color: '#0E0E1D', fontSize: '25px', fontWeight: 'bold', textAlign: 'center'}}>Funkzählerdaten automatisiert empfangen und nutzen</span>
                <span style={{color: '#5555FF', fontSize: '25px', fontWeight: 'bold', textAlign: 'center'}}>Mit hellohousing Professional.</span>
              </Stack>
              <Group>
                <div>
                  <ColorButton
                  text={currentSubscription ? (currentSubscription?.subscriptionName === 'Starter' ? (accountData.isPremiumPlanTested === false ? 'Jetzt freischalten' : 'Jetzt freischalten') : currentSubscription?.subscriptionName === 'Premium' ? (accountData.isProfessionalPlanTested === false ? 'Jetzt freischalten' : 'Jetzt freischalten') : 'Jetzt freischalten') : 'Jetzt freischalten'}
                  className='purple-btn'
                  onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})}
                  />
                </div>
              </Group>
              <Group style={{width: '460px'}}>
                <span style={{color: '#615C7E', fontSize: '12px', textAlign: 'center'}}>Verbräuche immer aktuell ohne aufwändige Vor-Ort-Ablesungen - mit der automatisierten Zählerdatenverarbeitung von hellohousing.</span>
              </Group>
              <Group>
                <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px', cursor: 'pointer'}} onClick={()=> window.Intercom('showArticle', '6776182')}>Zählerdatenverarbeitung kennenlernen</span>
              </Group>
            </Stack>}
          {(currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER' || currentSubscription?.subscriptionKey.split('_')[0] === 'PREMIUM') && 
          <Overlay opacity={0.5} blur={4} zIndex={5} color={'#F7F7FA'}/>}
          {this.props.properties.length !== 0 ?
        <Stack align={'flex-start'} style={{
          //background: 'red'
          }}>
              {/* <Group grow style={{width: '100%'}} position='apart'>
                <MeterDataMapping isMobile={isMobile} isTablet={isTablet}/>
                <MeterEmailCard isMobile={isMobile} isTablet={isTablet}/>
            </Group> */}
            <Group grow position='left' style={{width: '100%'}}>
              <Skeleton visible={skeletonLoading}>
                <MeterDataMapping 
                meterMappings={meterMappings}
                isMobile={isMobile} 
                isTablet={isTablet}
                updateMappings={()=> {
                  //this.setState({skeletonLoading: true});
                  this.getMeterReadingMappings();
                  }}/>
              </Skeleton>
            </Group>
            <Group grow position='left' style={{width: '100%'}}>
              <Skeleton visible={skeletonLoading}>
                <MeterManualData 
                meterMappings={meterMappings}
                isMobile={isMobile} 
                isTablet={isTablet}
                refreshLogs={()=> this.setState({refreshLogs: true})}/>
              </Skeleton>
            </Group>
            <Group grow position='left' style={{width: '100%'}}>
              <Skeleton visible={skeletonLoading}>
                <MeterEmailCard 
                meterMappings={meterMappings}
                isMobile={isMobile} 
                isTablet={isTablet}/>
              </Skeleton>
            </Group>
            <Group grow position='left' style={{width: '100%'}}>
              <Skeleton visible={skeletonLoading}>
                <MeterUploadsLog 
                history={this.props.history}
                isMobile={isMobile} 
                isTablet={isTablet} 
                logsLoaded={()=> this.setState({skeletonLoading: false})}
                refresh={this.state.refreshLogs} 
                refreshLogsDisable={()=> this.setState({refreshLogs: false})}/>
              </Skeleton>
            </Group>
        </Stack>
        :
        <>
        <EmptyStateContainer
                // className="empty-meter-reading"
                heading={'Datentransfer nicht verfügbar.'}//Bitte legen Sie zunächst Mietverhältnisse an, zu denen Ihre Zähler zuzuordnen sind"
                subheading='Legen Sie zunächst eine Immobilie an, um den Datentransfer zu nutzen.'
                icon={MeterIcon}
                btnHandler={() => {
                  if (Autoload.MainHelper.checkEmpty(this.props.properties)) {
                    this.toggleAddPropertyTypeModal();
                  } else {
                    this._navigateTo(TENANT_ROUTE);
                  }
                  }}
                btnText={Autoload.MainHelper.checkEmpty(this.props.properties)
                      ? lang.general.text_add_property
                      :'Einheit hinzufügen'}
          />
          {/* add property */}
          {this.state.addPropertyModal && (
              <AddPropertyModal
                className="add_proprty_modal"
                edit={false}
                open={this.state.addPropertyModal}
                toggle={() => {
                  this.toggleAddPropertyModal();
                }}
                propertyType={this.state.selectedPropertyType}
              />
            )}
          {this.state.addPropertyTypeModal &&
          <AddPropertyTypeModal
            className="add_proprty_type_modal"
            open={this.state.addPropertyTypeModal}
            toggle={() => {
              this.toggleAddPropertyTypeModal();
            }}
            next={(selectedPropertyType)=> {
              this.setState({selectedPropertyType});
              this.toggleAddPropertyTypeModal();
              this.toggleAddPropertyModal();
            }}
          />
          }
          </>
        }
      </Box>
        </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
    properties: state.generalReducer.allProperties,
    currentSubscription: state.generalReducer.currentSubscription,
    accountData: state.generalReducer.accountData
  };
};
export default connect(mapStateToProps)(MeterDataTransfer);
