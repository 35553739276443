import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import { Checkbox, Group, Loader, Skeleton, Stack, TextInput } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';
import Bank from '../../../../../assets/images/module/general/Bank.svg';
import { NumericFormat } from 'react-number-format';
import SearchContainer from '../SearchContainer/SearchContainer';
import Autoload from '../../../../Autoload';
import { config } from '../../../../config/Setting';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

export class AddBankConnectionModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    search: null,
    banks: [],
    selectedBank: null,
    loader: false,
    fetch: 10,
    offset: 0,
    searchApiLoader: false,
    notFound: false,
    apiLoader: false,
    newBankConnectionData: null
  };
  
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    const inputElement = document.getElementById('search-input-for-banks');
    const getBanksBySearch=()=> this.getBanksBySearch();
    const debounce=(func, delay)=> {
      let timeoutId;
      return function(...args) {
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => {
              func.apply(this, args);
          }, delay);
      };
    }
    inputElement.addEventListener('input', debounce(function(event) {
      // Code to execute after user stops typing
      console.log('User stopped typing, value:', event.target.value);
      getBanksBySearch();
    }, 1000));
  }

  getBanksBySearch() {
    const {search, fetch, offset} = this.state;
    console.log('getBanksBySearch: ', config.financeApi);
    if(search){
      this.setState({apiLoader: true});
      Autoload.HttpRequestAxios.HttpRequest(
        config.financeApi + 'banks?search=' + search+ '&fetch=10&offset='+ offset,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        'handleGetSearchBanks',
        null,
        false,
        '',
        {
          'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    }
  }

  handleGetSearchBanks(response){
    const {banks} = this.state;
    console.log('handleGetSearchBanks: ', response);
    if(response.status && response.statuscode === 200){
      let result = response.result.filter((resultBank)=> banks.find((bank)=> bank.id === resultBank.id) === undefined);
      this.setState({banks: [...banks, ...result]});
    }else if(response.message === 'Not Found' && response.statuscode === 404){
      this.setState({notFound: true});
    }else{
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='red-cross'/>
      });
    }
    this.setState({loader: false, searchApiLoader: false, apiLoader: false});
  }

  postBankConnections=()=>{
    const {selectedBank} = this.state;
    let propertyId = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + 'bankConnections',
      'POST',
      {
        propertyId,
        bankId: selectedBank
      },
      null,
      false,
      true,
      false,
      this,
      'handlePostBankConnections',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handlePostBankConnections(response){
    const {currentBankConnectionData, deleteBankConnection} = this.props;
    if(response.status && response.statuscode === 200){
      console.log('handlePostBankConnections: ', response);
      // if(currentBankConnectionData){
      //   deleteBankConnection(currentBankConnectionData.id);
      // }
      this.setState({newBankConnectionData: response.result}, ()=> this.getBankConnectionUrl())
    }else{
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='red-cross'/>,
        id: 'PostBankConnectionsError'
      });
    }
  }

  getBankConnectionUrl=()=>{
    const {newBankConnectionData} = this.state;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + 'bankConnections/'+ newBankConnectionData.id + '/Url',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetBankConnectionUrl',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleGetBankConnectionUrl(response){
    if(response.status && response.statuscode === 200){
      console.log('handleGetBankConnectionUrl: ', response);
      this.props.openWebForm(response.result, this.state.newBankConnectionData);
    }else{
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='red-cross'/>
      });
    }
  }

  handleScroll = (e) => {
    const {loader, offset, notFound} = this.state;
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && loader === false && notFound === false) { 
      this.setState({offset: offset + 10},()=> this.getBanksBySearch());
    }
  }
  
  render() {
    const { open, toggle, className, bankConnectionsData, updateBankConnectionImportNewAccounts } = this.props;
    const { search, banks, selectedBank, loader, searchApiLoader, apiLoader, notFound, isMobile } = this.state;
    
    return (
      <CustomModal
        heading={'Bank suchen'}
        headingClassName={'add-bank-connection-modal-heading'}
        open={open}
        toggle={() => {
          toggle();
        }}
        className={`${className}`}
        style={{minWidth: !isMobile && '600px'}}
        styleBody={{maxHeight: '600px'}}
      >
        <Stack spacing={10}>
          <Group>
          <div className='filters' style={{width: '100%'}}>
            <div className='section-1' style={{width: '100%'}}>
              <SearchContainer
              value={search}
              id={'search-input-for-banks'}
              onChange={(e)=> {
                let value= e.target.value;
                if(e.target.value.length > 0){
                  this.setState({search: e.target.value, searchApiLoader: true}, ()=> {
                    if(searchApiLoader === false){
                      setTimeout(() => {
                        console.log('onChange: ', value, search);
                        if(value !== search){
                          //this.setState({loader: true, offset: 0, banks: [], notFound: false}, ()=> this.getBanksBySearch());
                          this.setState({loader: true, offset: 0, banks: [], notFound: false});
                        }
                        }, 0//2000
                        );
                    }
                    
                  });
                }else{
                  this.setState({search: e.target.value, loader: false, offset: 0, banks: [], notFound: false, searchApiLoader: false});
                }
                
            }}
              disabled={false}
              placeholder={'Suche nach Bankname, -ort oder -BLZ'}
              style={{width: '100%'}}
              //loader={loader}
              />
            </div>
          </div>
          </Group>
          {loader ?
          <Group align={'center'} position={'center'} >
            <Stack spacing={10} style={{width: '100%', maxHeight: '380px', overflow: 'hidden'}}>
              <Group>
                <span style={{fontWeight: '400', fontSize: '14px', fontFamily: 'Inter, sans-serif'}}>Bitte wählen Sie Ihre Bank und klicken Sie auf <span style={{fontWeight: 'bold'}}>Weiter</span>, um fortzufahren. Sie werden im Anschluss an unseren Partner FinAPI weitergeleitet, um die Bankverbindung einzurichten.</span>
              </Group>
              <Skeleton visible={true} style={{height: '21px', width: '100%', padding: '16px 0px 5px'}}/>
              {[...Array(6)].map((_) => <Skeleton style={{height: '62px'}}/>)}
            </Stack>
          </Group>
          :
          <Group>
            {(search && banks.length === 0 && notFound) ?
            <Group style={{width: '100%', marginBottom: '60px'}} position='center'>
              <EmptyStateContainer
              heading={'Es wurden keine Ergebnisse gefunden.'}
              subheading={'Bitte ändern Sie die Suche und probieren Sie es erneut.'}
              showBtn={false}
              icon={Bank}
              />
            </Group>
            :
            banks.length > 0 ?
            <Stack style={{width: '100%'}}>
              <Group>
                <span style={{fontWeight: '400', fontSize: '14px', fontFamily: 'Inter, sans-serif'}}>Bitte wählen Sie Ihre Bank und klicken Sie auf <span style={{fontWeight: 'bold'}}>Weiter</span>, um fortzufahren. Sie werden im Anschluss an unseren Partner FinAPI weitergeleitet, um die Bankverbindung einzurichten.</span>
              </Group>
              <TableContainer style={{maxHeight: isMobile ? '240px' : '340px'}} id='banks-search-table' onScroll={this.handleScroll}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className='banks-table-header' >
                        Name
                      </TableCell>
                      <TableCell className='banks-table-header'>
                        Stadt
                      </TableCell>
                      <TableCell className='banks-table-header'>
                        BIC
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {banks
                  .map((bank)=>
                  <TableRow className={`bank-cards ${selectedBank === bank.id && 'selected-bank'} banks-table-row`} onClick={()=> this.setState({selectedBank: bank.id})}>
                    <TableCell className='banks-table-cell'>
                      <Group spacing={10} noWrap>
                        {bank.iconUrl ? 
                        <img src={bank.iconUrl} style={{maxWidth: '30px'}}/>
                        :
                        <Group style={{width: '30px', height: '30px', background: '#5555FF', lineHeight: '34px', borderRadius: '4px'}} position='center' align={'center'} >
                          <span style={{color: 'white', fontWeight: 'bold'}}>{bank.name[0]}</span>
                        </Group>}
                        <span style={{wordBreak: 'break-word', fontWeight: '400'}}>{bank.name}</span>
                      </Group>
                      
                    </TableCell>
                    <TableCell className='banks-table-cell'>
                      {bank.city}
                    </TableCell>
                    <TableCell className='banks-table-cell'>
                      {bank.bic}
                    </TableCell>
                  </TableRow>
                  )}
                    <TableRow>
                      <TableCell style={{textAlign: 'center'}} colSpan={3}>
                        {apiLoader && <Loader variant='dots' size={'md'}/>}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
            
            :
            <Group style={{width: '100%', marginBottom: '60px'}} position='center'>
              <EmptyStateContainer
              heading={'Suchen Sie nach Ihrer Bank'}
              subheading={'Geben Sie Bankname, -ort oder -BLZ ein, um Ihre Bank zu suchen.'}
              showBtn={false}
              icon={Bank}
              />
            </Group>}
          </Group>}
          <Group position='apart' style={{marginTop: '20px'}}>
            <div>
                <ColorButton
                text={'Abbrechen'}
                className='gray-btn'
                onClick={()=> toggle()}
                />
            </div>
            <div>
                <ColorButton
                text={'Weiter'}
                className='purple-btn'
                onClick={()=> this.postBankConnections()} //bankConnectionsData.find((bankConnection)=> bankConnection.bankId === selectedBank) !== undefined ? updateBankConnectionImportNewAccounts(bankConnectionsData.find((bankConnection)=> bankConnection.bankId === selectedBank)?.id) : 
                disabled={selectedBank === null}
                />
            </div>
          </Group>
        </Stack>
      </CustomModal>
    );
  }
}

export default AddBankConnectionModal;
