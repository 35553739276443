import React, { Component } from 'react';
import { ColorButton, TransparentButton, DeleteModal } from '../../../../../neoverv';
import { Badge } from 'react-bootstrap';
import Autoload from '../../../../../neoverv/Autoload';
import moment from 'moment';
import { connect } from 'react-redux';
import { Group, SimpleGrid, Skeleton, Stack } from '@mantine/core';
import StarterPackageCard from '../../../../../assets/images/module/general/StarterPackageCard.svg';
import PremiumPackageCard from '../../../../../assets/images/module/general/PremiumPackageCard.svg';
import ProfessionalPackageCard from '../../../../../assets/images/module/general/ProfessionalPackageCard.svg';
import CrossIcon from '../../../../../assets/images/module/general/CrossIcon.svg';
import StarterBadgeIcon from '../../../../../assets/images/module/general/StarterBadgeIcon.svg';
import PremiumBadgeIcon from '../../../../../assets/images/module/general/PremiumBadgeIcon.svg';
import ProfessionalBadgeIcon from '../../../../../assets/images/module/general/ProfessionalBadgeIcon.svg';
import UtilityBillingAssignedIcon from '../../../../../neoverv/components/SVGs/utilityBillingAssigned';
import PaymentTemplateModal from '../../../../../neoverv/components/Module/General/CustomModal/PaymentTemplateModal';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty } = Autoload.MainHelper;
export class NewSubscription extends Component {
  state = {
    subscriptionPlan: null,
    subscriptionlastDate: '',
    openModal: false,
    subscriptionData: [
      {
        subscriptionRenewDate: '',
        subscriptionAmount: '',
        subscriptionBilling: '',
        subscriptionKey: '',
      },
    ],
    skeletonLoading: false,
    currentSubs: null,
    openPaymentRedirectModal: false,
    subscribtionSelected: null,
    subscribtionSelectedName: null,
    subscriptionTypes: [],
    paymentMandate: null,
    nextSubscription: null
  };

  onInputChange = (field, value) => {
    this.setState({ [field]: value });
  };

  componentDidMount() {
    this.setState({currentSubs: this.props.currentSubscription}, ()=> {
      if(this.props.history?.location?.state){
        const {subscriptionData, subscribtionSelected, paymentMandate, subscriptionTypes, nextSubscription} = this.props.history.location.state;
        this.setState({subscriptionData, subscribtionSelected, paymentMandate, subscriptionTypes, nextSubscription});
      }else{
        this.getsubscriptionsTypes();
      }
    });
  }

  getsubscriptionsTypes = async () => {
    const userAccountID = this.props.profile.accountId;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_reg_api_baseurl + 'subscriptions',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_subscriptions_types',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handle_subscriptions_types(response){
    console.log('handle_subscriptions_types: ', response);
    if(response.status){
      this.setState({subscriptionTypes: response.result});
    }
    this.getPaymentDetails();
  }

  getPaymentDetails = () => {
    console.log('this.props.profile', this.props.profile.accountId, this.props.profile);
    const userAccountID = this.props.profile.accountId;
    // console.log(userAccountID, 'userAccountID from redux - payment details page');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_api_baseurl + userAccountID + '/paymentMandate',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_account_payment_details',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handle_account_payment_details(response) {
    console.log('handle_account_payment_details response', response);
    if (response.status == true && response.statuscode == 200) {
      this.setState({
        paymentMandate: response.result
      });
    }
    this.getsubscriptionsAssignments();
  }

  getsubscriptionsAssignments = async () => {
    const userAccountID = this.props.profile.accountId;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_profile_subscription_api_baseurl + 'subscriptionAssignments?accountId=' + userAccountID,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_subscriptions_Assignments_details',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handle_subscriptions_Assignments_details(response) {
    console.log('profile ssubscription api', response);
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      this.setState({
        subscriptionData: response.result.map((data, index) => {
          return {
            subscriptionRenewDate: moment(data.nextRenewalDate).format('DD.MM.YYYY'),
            subscriptionAmount: checkProperty(checkProperty(data, 'subscriptionType'), 'price'),
            subscriptionBilling: checkProperty(checkProperty(data, 'subscriptionType'), 'billing'),
            subscriptionKey: checkProperty(checkProperty(data, 'subscriptionType'), 'subscriptionKey'),
            subscriptionTypeId: checkProperty(checkProperty(data, 'subscriptionType'), 'subscriptionTypeId'),
            subscriptionStatus: checkProperty(data, 'subscriptionStatus'),
            testPeriodEnd: checkProperty(data, 'testPeriodEndDate'),
            subscriptionEndDate: moment(data.testPeriodEndDate).format('DD.MM.YYYY'),
            subscriptionAssignment: checkProperty(data, 'id'),
            nextPaymentDue: moment(data.nextPaymentDate).format('DD.MM.YYYY'),
            cancellatinoDate: moment(data.cancelationDate).format('DD.MM.YYYY'),
          };
        }),
      });
    }

    this.ShowLoader();
  }
  toggleModal = (subscriptionStatus, testPeriodEnd, id, subscriptionRenewDate) => {
    var date = moment(new Date()).format('YYYY-MM-DD');
    var testDate = moment(testPeriodEnd).format('YYYY-MM-DD');
    var testPeriod = moment(date).isSameOrBefore(testDate);

    this.setState({
      subsStatus: subscriptionStatus,
      subsEndPeriod: testPeriodEnd,
      subsId: id,
      testPeriodCheck: testPeriod,
      renewDate: subscriptionRenewDate,
      openModal: !this.state.openModal,
    });
  };
  reactivateCancelSub() {
    const { subsStatus, subsId, testPeriodCheck } = this.state;
    if (subsStatus === 'ACTIVE' && testPeriodCheck === true) {
      //active
      this.cancelSubscriptionAPi(subsId);
    } else if (subsStatus === 'ACTIVE' && testPeriodCheck === false) {
      //over
      this.cancelSubscriptionAPi(subsId);
    } else if (subsStatus === 'CANCELLED') {
      this.reactivateSubscriptinApi(subsId);
    }
  }
  cancelSubscriptionAPi(id) {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_profile_subscription_api_baseurl + 'subscriptionAssignments/' + id + '/cancel',
      'PUT',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_cancel_subscription',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }
  handle_cancel_subscription(response) {
    this.setState({ openModal: !this.state.openModal });

    this.getsubscriptionsAssignments();
  }
  reactivateSubscriptinApi(id) {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_profile_subscription_api_baseurl + 'subscriptionAssignments/' + id + '/reactivate',
      'PUT',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_reactivate_cancel_subscription',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }
  handle_reactivate_cancel_subscription = (response) => {
    if (response.statuscode === 200) {
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        'Willkommen zurück. Ihr Abonnement wurde wieder hergestellt',
        6000
      );
    }

    this.getsubscriptionsAssignments();
  };

  ShowLoader = () => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    this.setState({skeletonLoading: false});
  };

  render() {
    const {closeNewSubscription, currentSubscription, accountData, isMobile} = this.props;
    const {currentSubs, openPaymentRedirectModal, subscriptionData, subscribtionSelected, paymentMandate, subscriptionTypes, nextSubscription} = this.state;
    console.log('subscriptionTypes: ', subscriptionTypes);
    return (
      <div>
        <Group style={{justifyContent: 'flex-end'}}>
          <div>
            <ColorButton
            text={'Zurück'}
            className={'gray-btn'}
            onClick={()=> //closeNewSubscription()
            this.props.history.push('yourSubscription')
            }
            />
          </div>
        </Group>
        <Group id="response-user-account-subs-details" style={{ marginTop: '30px', marginBottom: '60px', padding: '0px 40px'}} align='flex-start' position='center' spacing={'xl'}>
          <SimpleGrid  
          cols={4}     
          breakpoints={[
            { maxWidth: 1600, cols: 3, spacing: 'md' },
            { maxWidth: 1400, cols: 2, spacing: 'sm' },
            { maxWidth: 770, cols: 1, spacing: 'sm' },
          ]}>
            {subscriptionTypes.filter((subscription)=> subscription?.subscriptionKey.includes('2024') || subscription?.subscriptionKey.includes(currentSubscription?.subscriptionKey))
            .sort((a,b)=> a.price - b.price)
            .map((subscriptionType)=>{
              const paidPlan= subscriptionType.price > 0 ? true : false;
              return (<Stack spacing={5} style={{cursor: subscriptionType.subscriptionTypeId !== currentSubscription?.subscriptionTypeId && paidPlan && subscriptionType.subscriptionTypeId !== nextSubscription?.subscriptionTypeId && 'pointer'}}>
                <span className='current-subscription-title' style={{marginBottom: '7px', textAlign: 'center', height: '17px'}}>{subscriptionData.find((item)=> item.subscriptionAssignment === currentSubscription?.subscriptionAssignment && item.subscriptionKey === subscriptionType.subscriptionKey) !== undefined && 'Aktuelles Abonnement'}</span>
                <Stack className='new-subscriptions-card' style={{borderColor: subscriptionType.subscriptionTypeId !== nextSubscription?.subscriptionTypeId ? subscriptionType.subscriptionTypeId === subscribtionSelected && '#5555FF' : 'none', borderWidth: subscriptionType.subscriptionTypeId === subscribtionSelected && '2px'}}>
                  <Stack style={{padding: '20px'}} onClick={()=> subscriptionType.subscriptionTypeId !== currentSubscription?.subscriptionTypeId &&  paidPlan && subscriptionType.subscriptionTypeId !== nextSubscription?.subscriptionTypeId && (paymentMandate ? this.props.history.push('yourSubscription', {selectedSubscription: subscriptionType.subscriptionTypeId})//closeNewSubscription(subscriptionType.subscriptionTypeId) 
                  : this.setState({openPaymentRedirectModal: true, subscribtionSelected: subscriptionType.subscriptionTypeId, subscribtionSelectedName: subscriptionType.name}))}>
                    <Group style={{justifyContent: 'center'}}>
                      <img src={subscriptionType.subscriptionKey.split('_')[0] === 'STARTER' ? StarterBadgeIcon : subscriptionType.subscriptionKey.split('_')[0] === 'PREMIUM' ? PremiumBadgeIcon : ProfessionalBadgeIcon} height={'24px'}/>
                    </Group>
                    <Group className='new-subscriptions-card-text' style={{height: '70px', alignItems: 'flex-start'}}>
                    {subscriptionType.subscriptionKey.split('_')[0] === 'STARTER' ?
                    'Alles was Sie zum Start mit hellohousing benötigen. Alle Grundfunktionen zur digitalen Verwaltung und Nebenkostenabrechnung Ihrer Immobilie.'
                    :
                    subscriptionType.subscriptionKey.split('_')[0] === 'PREMIUM' ?
                    'Alle Funktionen des Starter Paketes und zusätzlich die Möglichkeit eine weitere Immobilie zu verwalten, Heizkosten professionell abzurechnen und telefonisch Expertenunterstützung zu erhalten.'
                    :
                    'Alle Funktionen des Premium Paketes mit der Möglichkeit weitere Immobilien zu verwalten, Funkzählerdaten zu verarbeiten und Verbräuche und Informationen für die Mieter im Mieterportal bereitzustellen.'
                    }
                    </Group>
                    <Group>
                    {subscriptionType.subscriptionKey.split('_')[0] === 'STARTER' ?
                      <Stack>
                        <Stack spacing={2}>
                          <Group><span style={{fontWeight: '600', color: '#13135C'}}>Inhalte</span></Group>
                          <Group><div className='divider' style={{width: '57px', borderColor: '#13135C', margin: '0px'}}/></Group>
                        </Stack>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>1 Immobilie</span></Group>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>9 Wohneinheiten</span></Group>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>Alle Daten in der Cloud</span></Group>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>Dokumentenablage (100 MB)</span></Group>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>Belegmanager</span></Group>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>Nebenkostenabrechnung als PDF</span></Group>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>{'Kundenservice via Chat & E-Mail'}</span></Group>
                      </Stack>
                      :
                      subscriptionType.subscriptionKey.split('_')[0] === 'PREMIUM' ?
                      <Stack>
                        <Stack spacing={2}>
                          <Group><span style={{fontWeight: '600', color: '#13135C'}}>Inhalte</span></Group>
                          <Group><div className='divider' style={{width: '57px', borderColor: '#13135C', margin: '0px'}}/></Group>
                        </Stack>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>2 Immobilien</span></Group>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>25 Mieteinheiten je Immobilie</span></Group>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>Alle Daten in der Cloud</span></Group>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>Dokumentenablage (1 GB)</span></Group>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>Belegmanager mit KI-Texterkennung</span></Group>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>Bankkonto einbinden</span></Group>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>Datenexport für Anlage V</span></Group>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>Nebenkostenabrechnung als PDF</span></Group>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>{'Kundenservice via Chat, E-Mail & Telefon'}</span></Group>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>{'Heizkostenabrechnung nach HKVO'}</span></Group>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>{'Eigenes Logo & Branding'}</span></Group>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>{'Nebenkostenabrechnung für Gewerbe'}</span></Group> 
                      </Stack>
                      :
                      <Stack>
                        <Stack spacing={2}>
                          <Group><span style={{fontWeight: '600', color: '#13135C'}}>Inhalte</span></Group>
                          <Group><div className='divider' style={{width: '57px', borderColor: '#13135C', margin: '0px'}}/></Group>
                        </Stack>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>3-5 Immobilien<span style={{verticalAlign: 'super', fontSize: '9px'}}>1</span></span></Group>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>Unbegrenzte Wohneinheiten</span></Group>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>Alle Daten in der Cloud</span></Group>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>Dokumentenablage (2 GB)</span></Group>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>Belegmanager mit KI-Texterkennung</span></Group>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>Bankkonto einbinden</span></Group>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>Datenexport für Anlage V</span></Group>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>Nebenkostenabrechnung als PDF</span></Group>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>{'Kundenservice via Chat, E-Mail & Telefon'}</span></Group>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>{'Heizkostenabrechnung nach HKVO'}</span></Group>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>{'Eigenes Logo & Branding'}</span></Group>
                        <Group noWrap spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>{'Nebenkostenabrechnung für Gewerbe'}</span></Group>
                        <Group spacing={'xs'} noWrap>
                          <Group>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}</Group>
                          <Group><span style={{fontSize: '12px', color: '#13135C', wordBreak: 'break-word'}}>{'Monatliche Verbrauchsinformationen'}</span></Group>
                        </Group>
                        <Group spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>{'Smart-Meter-Einbindung'}</span></Group>
                        <Group spacing={'xs'}>{UtilityBillingAssignedIcon('#5555FF', '12.87', '12.87')}<span style={{fontSize: '12px', color: '#13135C'}}>{'Mieterportal'}</span></Group>
                        {/* <span style={{color: '#727293'}}> (demnächst verfügbar)</span> */}
                      </Stack>}
                    </Group>
                  </Stack>
                  <Stack style={{background: '#F2F2F2', padding: '20px 0px', borderEndStartRadius: '7px', borderEndEndRadius: '7px'}}>
                    <Group style={{justifyContent: 'center'}}>
                      {subscriptionType.price === 0 ? 'Kostenlos' : (subscriptionType.subscriptionKey.split('_')[0] === 'TEST' && subscriptionType.subscriptionKey.split('_')[1] === 'PROFESSIONAL') ? 'Täglich kündbar' : 'Monatlich kündbar'}
                    </Group>
                    <Group style={{justifyContent: 'center'}}>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <span className='upgrade-subscription-card-value'>{subscriptionType.price.toString().split('.')[0]}<span style={{fontSize: '13px'}}>{paidPlan && subscriptionType.price.toString().split('.')[1]+'0'}</span>€</span>
                        <span className='upgrade-subscription-card-monat'> / {subscriptionType.billing === 'daily' ? 'täglich' : 'Monat'}</span>
                      </div>
                    </Group>
                    {subscriptionType.subscriptionTypeId !== currentSubscription?.subscriptionTypeId && subscriptionType.price !== 0 && subscriptionType.subscriptionTypeId !== nextSubscription?.subscriptionTypeId &&
                    <Group style={{justifyContent: 'center'}}>
                      <div>
                        <ColorButton
                        text={'Jetzt freischalten'}
                        className={'purple-btn'}
                        disabled={subscriptionType.status !== 'Available'}
                        onClick={()=> paymentMandate ? this.props.history.push('yourSubscription', {selectedSubscription: subscriptionType.subscriptionTypeId})//closeNewSubscription(subscriptionType.subscriptionTypeId) 
                          : this.setState({openPaymentRedirectModal: true, subscribtionSelected: subscriptionType.subscriptionTypeId, subscribtionSelectedName: subscriptionType.name})//window.open('/paymentInitialize?type=authorize', '_blank')
                      }
                        />
                      </div>
                    </Group>}
                    {subscriptionType.subscriptionKey.split('_')[0] === 'PROFESSIONAL' && 
                    <Group spacing={'xs'} style={{padding: '0 20px'}}>
                      <span style={{fontSize: '10px', color: '#13135C', fontWeight: '400', lineHeight: '14px'}}>1: Für jedes weitere Objekt berechnen wir 1,50 € pro Monat. Bitte wenden Sie sich an den <span style={{cursor: 'pointer', color: '#5555FF'}} onClick={()=> window.Intercom('show')}>Kundenservice</span>, um weitere Immobilien freischalten zu lassen.</span>
                    </Group>}
                  </Stack>
                </Stack>
              </Stack>)
            })}
          </SimpleGrid>
          
        </Group>
        {openPaymentRedirectModal &&
        <PaymentTemplateModal
        open={openPaymentRedirectModal}
        toggle={()=>this.setState({openPaymentRedirectModal: false})}
        type={'authorize'}
        route={'/profile/yourSubscription'}
        subscribtionSelected={this.state.subscribtionSelected}
        subscribtionSelectedName={this.state.subscribtionSelectedName}
        />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.generalReducer.profile,
    AccountApi: state.generalReducer.account_api_id,
    currentSubscription: state.generalReducer.currentSubscription,
    accountData: state.generalReducer.accountData,
  };
};

export default connect(mapStateToProps, null)(NewSubscription);
