import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import { Row, Col, Form, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import { Checkbox, Grid, Group, SimpleGrid, Stack, TextInput, Tooltip } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIcon from '../../../../../assets/images/module/general/TenantIcon.svg';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';
import CloseButtonIcon from '../../../../../assets/images/module/general/CloseButtonIcon.svg';
import { NumericFormat } from 'react-number-format';
import CardSelection from '../CardSelection/CardSelection';
import BankBlue from '../../../../../assets/images/module/general/Bank.svg';
import Bank from '../../../../../assets/images/module/general/BankGrey.svg';
import AddIcon from '@material-ui/icons/Add';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, IconButton } from '@material-ui/core';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

export class SelectOptionFromBankConnectionOrAccountModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    hover1: false,
    hover2: false,
    selectedType: null,
    selectedBankConnectionId: null
  };
  
  constructor(props) {
    super(props);
  }

  handleMouseEnter=(type)=>{
    this.setState({[type]: true});
  }

  handleMouseLeave=(type)=>{
    this.setState({[type]: false});
  }

  hasEllipsis=(element)=> {
    console.log('element: ', element);
    if(element){
      return element.scrollWidth > element.clientWidth;
    }else{
      return false;
    }
  }
  renderBankName=(name)=>{
    return <span style={{fontWeight: '400', textAlign: 'center', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', display: 'block'}}>{name}</span>;
  }

  renderConnectionOwner=(name)=>{
    return <span style={{fontWeight: '300', textAlign: 'center', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', display: 'block'}}>{name}</span>;
  }
  renderBankConnectionsTable=()=>{
    const {bankConnectionsData, openAddBankModal, getBankAccountByBankConnectionId, deleteBankConnection} = this.props;
    const {selectedBankConnectionId, isMobile} = this.state;
    return(
      <Stack style={{width: '100%', marginBottom: '10px'}}>
        <SimpleGrid cols={2}>
          {bankConnectionsData.map((bankConnectionData)=> 
            <Stack style={{maxHeight: 'auto'}} className={`bank-details-card ${selectedBankConnectionId === bankConnectionData?.id && 'selected-bank'}`} onClick={()=> this.setState({selectedBankConnectionId: bankConnectionData.id})}>
              <Group style={{width: '100%'}} position='center'>
                <img src={bankConnectionData?.bankData?.iconUrl} style={{width: isMobile ? 'auto' : 'fit-content', height: isMobile ? 'auto' : '93.3px' }}/>
              </Group>
              <Stack spacing={5}>
                <Tooltip
                label={bankConnectionData?.bankData?.name}
                styles={{
                  body: {
                    background: '#f2f2fc'
                  }
                }}
                position={'bottom'}
                placement={'center'}
                zIndex={9999}>
                  {this.renderBankName(bankConnectionData?.bankData?.name)}
                </Tooltip>
                {bankConnectionData?.owners?.length > 0 &&
                <Tooltip
                label={`Inhaber: ${bankConnectionData?.owners[0]?.title}${bankConnectionData?.owners[0]?.firstName} ${bankConnectionData?.owners[0]?.lastName}`}
                styles={{
                  body: {
                    background: '#f2f2fc'
                  }
                }}
                position={'bottom'}
                placement={'center'}
                zIndex={9999}>
                  {this.renderConnectionOwner(`Inhaber: ${bankConnectionData?.owners[0]?.title}${bankConnectionData?.owners[0]?.firstName} ${bankConnectionData?.owners[0]?.lastName}`)}
                </Tooltip>}
              </Stack>
            </Stack>
          )}

          <Stack style={{maxHeight: bankConnectionsData.length%2 === 0 ? '153px' : 'auto', height: bankConnectionsData.length%2 === 0 ? '153px' : 'auto'}} className={`bank-details-card ${selectedBankConnectionId === 'add' && 'selected-bank'}`} onClick={()=> this.setState({selectedBankConnectionId: 'add'})}>
            <Group position='center' align='center' style={{height: '100%'}}>
              <IconButton 
                aria-label="filter list"
                style={{
                  background: '#5655FE',
                  border: '1px solid #5555FF', 
                  boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
                  padding: '5px',
                  cursor: 'pointer',
                  opacity: 1
                  }}>
                  <AddIcon 
                  style={{
                    color: 'white',
                    fontSize: '30px'
                    }}/>
              </IconButton>
            </Group>
            <span style={{fontWeight: 'bold', textAlign: 'center'}}>Weitere Bank hinzufügen</span>
          </Stack>
        </SimpleGrid>
      </Stack>
    )
  }
  render() {
    const { open, toggle, className, openAddBankModal, getBankAccountByBankConnectionId } = this.props;
    const { hover1, hover2, selectedType, isMobile, selectedBankConnectionId } = this.state;
    
    return (
      <CustomModal
        heading={'Bank wählen oder hinzufügen'}
        headingClassName={'update-received-rent-modal-heading'}
        open={open}
        toggle={() => {
          toggle();
        }}
        className={`${className}`}
        // style={{maxWidth: '450px'}}
      >
        <Stack>
            <Stack>
                <Group spacing={0}>
                    <p style={{fontFamily: 'Inter, sans-serif', fontWeight: '400'}}>Wählen Sie eine bestehende Bankverbindung, wenn Sie ein weiteres Konto der Bank einbinden wollen.</p>
                    <p style={{fontFamily: 'Inter, sans-serif', fontWeight: '400'}}>Wählen Sie weitere Bankverbindung hinzufügen, wenn Sie ein Bankkonto einer anderen Bank einbinden wollen.</p>
                </Group>
                {this.renderBankConnectionsTable()}
            </Stack>
            <Group position='apart'>
                <div>
                    <ColorButton
                    text={'Abbrechen'}
                    className='gray-btn'
                    onClick={()=> toggle()}
                    />
                </div>
                <div>
                    <ColorButton
                    text={'Weiter'}
                    className='purple-btn'
                    onClick={()=> selectedBankConnectionId === 'add' ? openAddBankModal() : selectedBankConnectionId && getBankAccountByBankConnectionId(selectedBankConnectionId)}
                    disabled={selectedBankConnectionId === null}
                    />
                </div>
            </Group>
        </Stack>
      </CustomModal>
    );
  }
}

export default SelectOptionFromBankConnectionOrAccountModal;
