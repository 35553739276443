import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { hideNotification, showNotification } from '@mantine/notifications';
import { Stack, Group, TextInput, Loader } from '@mantine/core';
import RichTextEditor from '@mantine/rte';

import Quill from 'quill';
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';
import Autoload from '../../../../neoverv/Autoload';
import DisplayModalForMobileView from '../../../../neoverv/components/Module/General/CustomModal/DisplayModalForMobileView';

Quill.register('modules/imageResize', ImageResize);
window.Quill = Quill;

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

function hideResizeContainers() {
  console.log('resizeContainers: ');
  const editorContainer = document.querySelector('.ql-container'); // Adjust selector
  if (!editorContainer) return;

  // Find divs that match the likely structure of the resize container
  const resizeContainers = Array.from(editorContainer.querySelectorAll('div')).filter(
    (el) => el.style.position === 'absolute' && el.style.width && el.style.height
  );

  console.log('resizeContainers: ', resizeContainers);

  resizeContainers.forEach((container) => {
    container.style.display = 'none'; // Hide the container
  });
}

export class TilesForTenantPortal extends Component {
    state={
        value: null,
        noSaveDuringImageUpload: false,
        allImagesData: [],
        openDisplayModalForMobileView: false,
        headingForMobileModal: null,
        contentForMobileModal: null,
        firstOnChange: false
    }

    modules = {
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
      }
    };

      constructor(props) {
        super(props);
        this.typingTimeout = null; // Timer to handle the debounce
        this.editorRef = createRef();
      }

      componentDidMount(){
        const {body, id, updateHtml, allImagesData} = this.props;
        if(body){
          this.setState({allImagesData});
        }

        const editorContainer = document.querySelector('.ql-container .ql-editor'); // Adjust selector
        
        if (editorContainer) {
          console.log('editorContainer: ', editorContainer);
          editorContainer.addEventListener('scroll', hideResizeContainers);
        }

        const editor = this.editorRef.current?.getEditor();
        if (editor) {
          // Make sure ImageResize module is correctly loaded in Quill
          const imageResize = editor.getModule("imageResize");
          console.log('imageResize: ', imageResize);
          if (imageResize) {
            // You can access and configure the imageResize module here if needed
          }
        }
      }

      componentDidUpdate(prevProps){
        const {body, allImagesData} = this.props;

        if(prevProps.allImagesData !== allImagesData){
          this.setState({allImagesData});
        }

      }

      replaceImgTags=(value)=>{
        let newValue = value;
        newValue= newValue.replaceAll('<img src=', '<img width=\'200px\' src=');
        this.props.updateHtml(newValue);
      }

    handleChange = (value, delta, source, editor) => {
      const {noSaveDuringImageUpload, allImagesData} = this.state;
      console.log('handleChange: ', source);
      if(source !== 'api'){
      let parser = new DOMParser();
      const valueCopy = value;
      let doc = parser.parseFromString(valueCopy, 'text/html');
        if(noSaveDuringImageUpload === false){
          //if(allImagesData.length > 0 && doc.querySelectorAll('img').length > 0){
            console.log('handleChange: ', 'inside');
  
            doc.querySelectorAll('img').forEach((img, index) => {
              let newImageData = allImagesData.find((imageData)=> imageData.fileLocation === img.src);
              if(newImageData !== undefined){
                img.id = newImageData.id;
              }
            });
  
            let modifiedHtmlString = doc.body.innerHTML;
            this.props.updateHtml(modifiedHtmlString);//modifiedHtmlString);
          //}else{
            //this.props.updateHtml(value);
            // Clear the previous timeout if user is still typing
            if (this.typingTimeout) {
              clearTimeout(this.typingTimeout);
            }
      
            // // Set a new timeout to trigger the API call after 2 seconds of inactivity
            this.typingTimeout = setTimeout(this.handleUserStoppedTyping, 2000);
          //}
        }
      }
    };
  
    handleUserStoppedTyping = () => {
      console.log('User stopped typing. Making API call...');
      // Replace this with your API call
      this.props.updateHtml(this.props.body, true);
      this.typingTimeout = null;
    };

    handleImageUpload = async (file) =>
    new Promise((resolve, reject) => {
      const {propertySelected, id, body} = this.props;
      const {allImagesData} = this.state;
      this.setState({noSaveDuringImageUpload: true});
      const formData = new FormData();
      formData.append('file', file);
      formData.append('accountId', propertySelected.accountId);
      formData.append('propertyId', propertySelected.id);
      formData.append('name', file.name);
      formData.append('isDirectory', false);
      formData.append('isReadOnly', false);
      formData.append('isShared', false);
      formData.append('fileType', 'NoteDocument');
      formData.append('referencedObjectId', id);

      fetch(Autoload.Setting.document_api_base_url + 'documents', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: Autoload.Utils.getAuthToken()
        }
      })
        .then((data) => data.json())
        .then((response) => 
        fetch(Autoload.Setting.document_api_base_url + 'documents/' + response?.result?.id, //+ '/download', //resolve(result.data.url)
        {
          method: 'GET',
          headers: {
            'Authorization': Autoload.Utils.getAuthToken()
          }
        })
        .then(data2 => {
          data2.json()
          .then(response2 => {
            this.setState({
              allImagesData: [
                ...allImagesData, 
                {
                  id: response2?.result?.id,
                  fileLocation: response2?.result?.fileLocation
                }
              ],
              noSaveDuringImageUpload: false
            }, ()=> resolve(response2?.result?.fileLocation));
          })
        }).catch(()=> reject(new Error('Download failed')))
        )
        .catch(() => reject(new Error('Upload failed')));
    });

    uploadFile=(file)=>{
      const {propertySelected, id} = this.props;
      const data = new FormData();
      data.append('file', file);
      data.append('accountId', propertySelected.accountId);
      data.append('propertyId', propertySelected.id);
      data.append('name', file.name);
      data.append('isDirectory', false);
      data.append('isReadOnly', false);
      data.append('isShared', false);
      data.append('fileType', 'NoteDocument');
      data.append('referencedObjectId', id);

      Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.document_api_base_url + 'documents',
      'POST',
      data,
      null,
      false,
      true,
      false,
      this,
      'handleUploadFile',
      null,
      false,
      '',
      {
        //'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
      )
    }

    handleUploadFile(response){
      let {body} = this.props;
      if(response.status && response.statuscode === 200){
        // Create a DOM parser
      let parser = new DOMParser();
      let doc = parser.parseFromString(body, 'text/html');

      // Select all img elements and manipulate them
      doc.querySelectorAll('img').forEach((img, index) => {
          img.removeAttribute('src'); // Remove the src attribute
          img.setAttribute('id', response.result?.id); // Add a new id attribute
      });

      // Convert the modified DOM back to a string
      let modifiedHtmlString = doc.body.innerHTML;

      console.log('handleUploadFile: ', modifiedHtmlString, response.result);
      //this.props.updateHtml(modifiedHtmlString)
      this.setState({noSaveDuringImageUpload: false}, ()=> {
        this.props.updateHtml(modifiedHtmlString, true);
        setTimeout(() => {
          this.getDocument(response.result?.id);
        }, 1000);
      })
      
      }
    }

    getDocument=(id)=>{
      const {body} = this.props;
      fetch(Autoload.Setting.document_api_base_url + 'documents/' + id + '/download',
      {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/pdf',
          'Authorization': Autoload.Utils.getAuthToken()
        }
      })
      .then(response => {
        response.blob()
        .then(data => {
          let url = window.URL.createObjectURL(data);
          let parser = new DOMParser();
          let doc = parser.parseFromString(body, 'text/html');

          doc.querySelectorAll('img').forEach((img, index) => {
            console.log('querySelectorAll: ', img.id, id);
            if(img.id === id){
              img.src = url;
            }
          });

          //doc.getElementById(id).setAttribute('src', url);
          
          let modifiedHtmlString = doc.body.innerHTML;
          this.props.updateHtml(modifiedHtmlString);
        })
      })
    }

    addClassToResizeContainer=()=> {
      const editorContainer = document.querySelector('.ql-container'); // Replace with actual editor container class
      if (!editorContainer) return;
    
      const potentialResizeContainers = editorContainer.querySelectorAll('div'); // Or adjust the tag as needed
      potentialResizeContainers.forEach((container) => {
        if (
          container.childElementCount === 4 && // Assuming it has 4 handles for resizing
          container.style.position === 'absolute' // Check for common inline styles
        ) {
          container.classList.add('custom-resize-container');
        }
      });
    }

    renderRTE=()=>{
      const {body, title, updateTitle} = this.props;
      return(
      <Stack>
        <TextInput
        label={'Titel'}
        value={title}
        styles={{
            label: {
                fontSize: '12px',
                fontWeight: 400,
                color: '#727293'
            }
        }}
        onChange={(e)=> updateTitle(e.target.value)}
        onBlur={()=> updateTitle(title, true)}
        />
        <Stack spacing={0}>
          <span className='rich-text-editor-box-label'>Inhalte</span>
          <RichTextEditor
          value={body}
          ref={this.editorRef}
          onChange={this.handleChange} //value.includes('<img src=') ? this.replaceImgTags(value) : updateHtml(value)
          //onBlur={()=> updateHtml(body, true)}
          onImageUpload={this.handleImageUpload}
          controls={[
              ['bold', 'italic', 'underline'],
              ['alignLeft', 'alignCenter', 'alignRight'],
              ['unorderedList', 'orderedList'],
              ['h1', 'h2'],
              ['image', 'link']
          ]}
          modules={this.modules}
          onFocus={()=> this.addClassToResizeContainer()}
          />
        </Stack>
      </Stack>
      )
    }

    setMobileViewStates=(openDisplayModalForMobileView, contentForMobileModal, headingForMobileModal)=>{
      this.setState({openDisplayModalForMobileView, contentForMobileModal, headingForMobileModal});
    }

    render() {
        const {title, body, displayOrder, loader, dragStart, isMobile} = this.props;
        const {openDisplayModalForMobileView, headingForMobileModal, contentForMobileModal} = this.state;

        return (
            <Stack 
            spacing={10}
            style={{
                width: '100%'
            }}>
                {loader ?//loader.find((tileId)=> tileId === id) !== undefined ?
                <Group position='center' align={'center'}>
                    <Loader/>
                </Group>
                :
                !dragStart ?
                  isMobile ? 
                  <Stack>
                    {title &&
                    <Stack spacing={0}>
                      <span className='rich-text-editor-box-label'>Titel</span>
                      <span onClick={()=> this.setMobileViewStates(true, this.renderRTE(), 'Aushänge')}>{title}</span>
                    </Stack>}
                    <Stack spacing={0}>
                      <span className='rich-text-editor-box-label'>Inhalte</span>
                      <div className='mobile-notes-content' dangerouslySetInnerHTML={{__html: body}} onClick={()=> this.setMobileViewStates(true, this.renderRTE(), 'Aushänge')}/>
                    </Stack>
                  </Stack>
                  :
                  this.renderRTE()
                :
                <Group position='center' align={'center'} style={{height: '100%'}}>
                    <span style={{fontWeight: 'bolder', fontSize: '20px', opacity: 0.5}}>{displayOrder}</span>
                </Group>}

                {openDisplayModalForMobileView &&
                <DisplayModalForMobileView
                open={openDisplayModalForMobileView}
                toggle={()=> this.setMobileViewStates(false, null, null)}
                heading={headingForMobileModal}
                content={contentForMobileModal}
                className={'display-modal-for-mobile-view'}
                />}
            </Stack>
        );
    }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};
export default connect(mapStateToProps)(TilesForTenantPortal);
