import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import { Checkbox, Grid, Group, Stack, TextInput } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';
import { NumericFormat } from 'react-number-format';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import accounting from 'accounting';
import dayjs from 'dayjs';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

export class TransactionDetailModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE
  };
  
  constructor(props) {
    super(props);
  }
  
  render() {
    const { open, toggle, className, transaction, renderTransactionAssigmentBlock, isMobile } = this.props;
    
    return (
      <CustomModal
        heading={transaction?.counterpartName ? transaction?.counterpartName : '-'}
        headingClassName={'update-received-rent-modal-heading'}
        open={open}
        toggle={() => {
          toggle();
        }}
        className={`${className}`}
        //style={{maxWidth: '450px'}}
      >
        <Stack>
            <Stack>
                <Grid gutter={'xl'} grow>
                    <Grid.Col span={6}>
                        <Stack spacing={0}>
                            <span className='app-label text-xs'>Sender / Empfänger</span>
                            <span className='text-img-wrapper' style={{fontWeight: '300'}}>{transaction?.counterpartName ? transaction?.counterpartName : '-'}</span>
                        </Stack>
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Stack spacing={0}>
                            <span className='app-label text-xs'>Datum</span>
                            <span className='text-img-wrapper' style={{fontWeight: '300'}}>{dayjs(transaction?.bankBookingDate).format(dayjs(transaction?.bankBookingDate).isSame(dayjs()) ? 'hh:mm Uhr' : 'DD.MM.YYYY')}</span>
                        </Stack>
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Stack spacing={0}>
                            <span className='app-label text-xs'>Bankname</span>
                            <span className='text-img-wrapper' style={{fontWeight: '300'}}>{transaction?.counterpartBankName ? transaction?.counterpartBankName : '-'}</span>
                        </Stack>
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Stack spacing={0}>
                            <span className='app-label text-xs'>IBAN</span>
                            <Group spacing={10} noWrap>
                                <span className='text-img-wrapper' style={{fontWeight: '300', width: 'auto'}}>{transaction?.counterpartIban ? transaction?.counterpartIban : '-'}</span>
                                {transaction?.counterpartIban &&
                                <div 
                                style={{cursor: 'pointer', marginTop: '-3px'}}
                                onClick={()=> {
                                navigator.clipboard.writeText(transaction?.counterpartIban);
                                showNotification({
                                    message: 'Information kopiert.',
                                    icon: <img src={GreenTick} alt='green-tick'/>,
                                    id: 'dataCopied'
                                });
                                }}>
                                    <CopyIcon fill={'#5555FF'} width='14px' height='14px'/>
                                </div>}
                            </Group>
                        </Stack>
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Stack spacing={0}>
                            <span className='app-label text-xs'>BIC</span>
                            <span className='text-img-wrapper' style={{fontWeight: '300'}}>{transaction?.counterpartBic ? transaction?.counterpartBic : '-'}</span>
                        </Stack>
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Stack spacing={0}>
                            <span className='app-label text-xs'>Transaktionsart</span>
                            <span className='text-img-wrapper' style={{fontWeight: '300'}}>{transaction?.transactionType ? transaction?.transactionType : '-'}</span>
                        </Stack>
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Stack spacing={0}>
                            <span className='app-label text-xs'>Betrag</span>
                            <span className='text-img-wrapper' style={{fontWeight: '300'}}>{accounting.formatMoney(transaction?.amount, '€', 2, '.', ',', '%v %s')}</span>
                        </Stack>
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Stack spacing={0}>
                            <span className='app-label text-xs'>Beschreibung</span>
                            <span className='text-img-wrapper' style={{fontWeight: '300'}}>{transaction?.purpose ? transaction?.purpose : '-'}</span>
                        </Stack>
                    </Grid.Col>
                    {isMobile &&
                    <Grid.Col span={12}>
                        <Stack spacing={0}>
                            <span className='app-label text-xs'>Ausgabe oder Miete erfassen</span>
                            <span className='text-img-wrapper' style={{fontWeight: '300'}}>{renderTransactionAssigmentBlock()}</span>
                        </Stack>
                    </Grid.Col>}
                </Grid>
            </Stack>
            {/* <Group position='right'>
                <div>
                    <ColorButton
                    text={'Schl'}
                    className='gray-btn'
                    onClick={()=> toggle()}
                    />
                </div>
            </Group> */}
        </Stack>
      </CustomModal>
    );
  }
}

export default TransactionDetailModal;
