import React, { Component, createRef } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import { Row, Col, Form, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { hideNotification, showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import RedTick from '../../../../../assets/images/module/general/RedTick.svg';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import { Checkbox, CloseButton, Group, Loader, MultiSelect, Select, SimpleGrid, Stack, TextInput, Menu, NumberInput, Tooltip as MantineTooltip } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';
import RentIconBlue from '../../../../../assets/images/module/general/RentIcon.svg';
import RentIconGreen from '../../../../../assets/images/module/general/RentIconGreen.svg';
import ReceiptsIcon from '../../../../../assets/images/module/general/ReceiptsIconGray.svg';
import RentIcon from '../../../../../assets/images/module/general/RentIconGray.svg';
import ReceiptsIconBlue from '../../../../../assets/images/module/general/ReceiptsIconBlue.svg';
import RightArrowPartner from '../../../../../assets/images/module/general/RightArrowPartner.svg';
import CloseButtonIcon from '../../../../../assets/images/module/general/CloseButtonIcon.svg';
import Up from '../../../../../assets/images/module/general/SelectArrowUp.svg';
import Down from '../../../../../assets/images/module/general/SelectArrowDown.svg';
import EmptyIcon from '../../../../../assets/images/EmptyReciept.svg';
import { NumericFormat } from 'react-number-format';
import CardSelection from '../CardSelection/CardSelection';
import { connect } from 'react-redux';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import Autoload from '../../../../Autoload';
import dayjs from 'dayjs';
import accounting from 'accounting';
import SelectDropdown from '../SelectDropdown/SelectDropdown';
import SelectReceiptsForTransactionAssigmentModal from './SelectReceiptsForTransactionAssigmentModal';
import SwitchContainer from '../SwitchContainer';
import { push } from 'draft-js/lib/EditorState';
import ReceiptDetailModal from './ReceiptDetailModal';
import AddIcon from '@material-ui/icons/Add';
import ShowModal from './ShowModal';
import ImageModal from './ImageModal';
import LaunchIcon from '@material-ui/icons/Launch';
import GlobalServices from '../../../../helpers/services/GlobalServices';
import costType from '../../../../../views/pages/Module/Property/costType';
import UtilityBillingAssignedIcon from '../../../SVGs/utilityBillingAssigned';
import TruncatedTextHandler from '../../../../../components/TruncatedTextHandler';
import AutoAssignReceiptTenantTransaction from './AutoAssignReceiptTenantTransaction';
import DeleteAllTransactionMappingConfirmationModal from './DeleteAllTransactionMappingConfirmationModal';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

const tableCellStyle = {
    whiteSpace: 'nowrap', 
    overflow: 'hidden', 
    textOverflow: 'ellipsis', 
    fontSize: '14px', 
    fontFamily: 'Inter, sans-serif', 
    height: 'auto !important',
    fontWeight: '400',
    borderTop: '8px solid white'
}

function Value({
  value,
  label,
  onRemove,
  classNames,
  ...others
}) {
  console.log('Value: ', others);
  return (
    <div {...others}>
      <Tooltip label={others.type === 'tenant' ? `Mietzahlung: ${label}` : `Beleg: ${label}`}>
        <Group
          style={{
            cursor: 'pointer',
            backgroundColor: others.type === 'tenant' ? 'rgb(247, 227, 205)' : '#E3E3FC',
            border: 'none',
            borderRadius: '10px',
            padding: '5px 12px',
          }}
          position={'center'}
          align={'center'}
          noWrap
          spacing={8}
        >
          <Group align={'center'} position={'apart'} noWrap spacing={8}>
            <img src={others.type === 'tenant' ? RentIcon : ReceiptsIcon} style={{width: '14px', height: '14px'}} />
            <span style={{ lineHeight: 1, fontSize: '12px', whiteSpace: 'nowrap', fontWeight: '300', color: '#111147' }}>{label}</span>
          </Group>
          {<Group align={'center'} position='right'>
            <CloseButton
              onMouseDown={onRemove}
              variant="transparent"
              size={14}
              iconSize={14}
              tabIndex={-1}
              style={{marginLeft: 8, marginRight: -4}}
            />
          </Group>}
        </Group>
      </Tooltip>
    </div>
  );
}

export class AssignTransactionModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    hover1: false,
    hover2: false,
    hover3: false,
    hover4: false,
    selectedType: null,
    tableHeader: ['Mieter', 'Mieteinheit', 'Einzug', 'Auszug'],
    receiptsTableHeader: ['Name', 'Betrag (€)', 'Datum'],
    selectedTenant: [],
    selectedReceiptType: null,
    receiptsList: [],
    selectedReceipts: [],
    receiptsLoader: false,
    vatTypes: [
        {label: '19,00 %'},
        {label: '7,00 %'},
        {label: 'anderer'}
    ],
    taxCostTypes: [
    // {label: 'Abschreibungen für GWG'},
    {label: 'Schuldzinsen'},
    {label: 'Geldbeschaffungskosten'},
    {label: 'Erhaltungsaufwendungen'},
    {label: 'Laufende Betriebskosten'},
    {label: 'Verwaltungskosten'},
    {label: 'Sonstige Kosten'},

    {label: 'Erstattete Vorauszahlungen'},
    {label: 'Erhaltene Nachzahlungen'},
    {label: 'Vereinnahmte Kautionen / Mieten aus Vorjahren'},
    {label: 'Vom Finanzamt erstattete Umsatzsteuer'},
    {label: 'Öffentliche Zuschüsse'},
    {label: 'AfA Gebäude'},
    {label: 'AfA bewegliche Wirtschaftsgüter'},
    {label: 'An Finanzamt gezahlte Umsatzsteuer'},
    {label: 'Renten, dauernde Lasten'},
    ],
    selectedVatType: [],
    customVatType: '0%',
    selectedTaxCostType: [],
    vatAmount: null, 
    netAmount: null,
    netInFocus: false,
    vatInFocus: false,
    vatPercentInFocus: false,
    selectReceiptsForTransactionAssigmentModalOpen: false,
    autoReceiptCreation: false,
    autoReceiptCreationOldStatus: false,
    tenants: [],
    dropdownIcon: Down,
    addReceiptOpen: false,
    ReceiptPage: true,
    rightSectionProps: {
      onClick: ()=> this.setState({selectReceiptsForTransactionAssigmentModalOpen: true})
    },
    showModal: false,
    ImageOpen: false,
    currentRecord: null,
    selectedMonth: null,
    dropdownIconForMonths: Down,
    isEarlyPaymentEnabled: false,
    mappings: [],
    costTypes: [],
    stopToggle: false,
    openCostTypeMenu: false,
    openTaxCostTypeMenu: false,
    openTaxRateMenu: false,
    customTaxRate: false,
    mappingLoader: false,
    openTenantMenu: false,
    openIsEarlyPaymentMenu: false,
    openAutoAssignReceiptTenantTransaction: false,
    currentBillingPeriod: null,
    openDeleteAllTransactionMappingConfirmationModal: false
  };

  constructor(props) {
    super(props);
    this.selectRef = createRef();
  }

  componentDidMount(){
    const {rentalUnits, edit, selectedTransaction} = this.props;
    console.log('selectedTransaction: ', selectedTransaction, edit);
    console.log('componentDidMount: ', rentalUnits);
    let tenants = [];
    rentalUnits.map((rentalUnit)=> rentalUnit.tenantsData.map((tenant)=> {
        if(tenant.vacant === false){
            let noOfMonthBetweenMoveInMoveOut= this.getMonthsBetween(tenant.in, tenant.out.length > 0 ? tenant.out : null);
            console.log('noOfMonthBetweenMoveInMoveOut: ', noOfMonthBetweenMoveInMoveOut);
            tenants.push({...tenant, rentalUnitName: rentalUnit.name, value: tenant.id, label: `${rentalUnit.name}: ${tenant.name} (${tenant.in} - ${tenant.out ? tenant.out : 'aktuell'})`, noOfMonthBetweenMoveInMoveOut});
        }
    }))
    if(edit){
      if(selectedTransaction?.transactionAssigments.receiptIds?.length > 0){
        this.setState({selectedReceipts: selectedTransaction?.transactionAssigments.receiptIds, selectedType: 'CREDIT_DEBIT_BALANCE'}, ()=> this.getReceipts());
      }else if(selectedTransaction?.transactionAssigments.tenantId){
        this.setState({selectedTenant: [selectedTransaction?.transactionAssigments.tenantId], selectedMonth: selectedTransaction?.transactionAssigments.rentalMonth && dayjs(selectedTransaction?.transactionAssigments.rentalMonth).format('MMMM YYYY'), selectedType: 'RENTAL_INCOME'});
      }
    }
    this.setState({tenants}, ()=> {
      this.getTransactionMapping(true);
      this.getUtilityBillingCostTypes();
      this.getCurrentBillingPeriod();
    }); //, selectedMonth: dayjs(selectedTransaction?.bankBookingDate).format('MMMM YYYY')
  }

  componentDidUpdate(prevProps, prevState){
    const {edit, selectedTransaction} = this.props;
    const {selectedMonth, selectedTenant, tenants} = this.state;
    if(selectedTenant.length === 0 && selectedMonth){
      this.setState({selectedMonth: null});
    }

    if((prevState.selectedTenant.length === 0 && selectedTenant.length > 0) || (prevState.selectedTenant.length > 0 && selectedTenant.length > 0 && prevState.selectedTenant[0] !== selectedTenant[0])){
      let sTenant = tenants.find((tenant)=> tenant.id === selectedTenant[0]);
      if(sTenant !== undefined){
        if(sTenant?.noOfMonthBetweenMoveInMoveOut?.length > 0){
          let sMonth= dayjs(sTenant?.noOfMonthBetweenMoveInMoveOut[0]?.value, 'MMMM YYYY').isAfter(dayjs(selectedTransaction?.bankBookingDate)) ? dayjs(sTenant?.noOfMonthBetweenMoveInMoveOut[sTenant?.noOfMonthBetweenMoveInMoveOut.length - 1]?.value).isBefore(dayjs(selectedTransaction?.bankBookingDate)) ? sTenant?.noOfMonthBetweenMoveInMoveOut[sTenant?.noOfMonthBetweenMoveInMoveOut.length - 1]?.value : dayjs(selectedTransaction?.bankBookingDate).format('MMMM YYYY') : sTenant?.noOfMonthBetweenMoveInMoveOut[0]?.value;
          if(edit && selectedMonth === null){
            this.setState({selectedMonth: selectedTransaction?.transactionAssigments?.rentalMonth && dayjs(selectedTransaction?.transactionAssigments.rentalMonth).format('MMMM YYYY')})
          }else if(!edit){
            this.setState({selectedMonth: sMonth});
          }
        }
      }
    }
  }

  openDropdown = () => {
    if (this.selectRef.current) {
      this.selectRef.current.openDropdown(); // Programmatically open the dropdown
    }
  };

  getMonthsBetween=(dateA, dateB)=> {
    const startDate = dayjs(dateA, 'DD.MM.YYYY');
    const endDate = dateB ? dayjs(dateB, 'DD.MM.YYYY') : dayjs();
    console.log('endDate: ', endDate, dateB);
    const months = [];
  
    // let current = startDate;
  
    // while (current.isBefore(endDate) || current.isSame(endDate, 'month')) {
    //   months.push({month: current, label: current.format('MMMM YYYY'), value: current.format('MMMM YYYY')}); // Customize the format as needed
    //   current = current.add(1, 'month');
    // }
  
    // return months;

    let current = endDate;

    while (current.isAfter(startDate) || current.isSame(startDate, 'month')) {
      months.push({month: current, label: current.format('MMMM YYYY'), value: current.format('MMMM YYYY')}); // Customize the format as needed
      current = current.subtract(1, 'month');
    }

    return months;
  }

  handleMouseEnter=(type)=>{
    this.setState({[type]: true});
  }

  handleMouseLeave=(type)=>{
      this.setState({[type]: false});
  }

  getReceipts=()=>{
    this.setState({receiptsLoader: true});
    Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.rm_api_base_url + 'property/' + this.props.propertySelected.id,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        'handleGetReceipts',
        null,
        false,
        '',
        {
          'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        },
        true
    );
  }

  handleGetReceipts(response){
    if(response.status && response.statuscode === 200){
        this.setState({receiptsList: response.result.map((receipt)=> ({...receipt, value: receipt.id, label: receipt.name}))});
    }
    // setTimeout(() => {
    //   this.setState({receiptsLoader: false});  
    // }, 5000);
    this.setState({receiptsLoader: false});  
  }

  getUtilityBillingCostTypes=()=>{
    GlobalServices._getCostTypeByProperty(this, 'handleGetUtilityBillingCostTypes', this.props.propertySelected);
  }

  handleGetUtilityBillingCostTypes(response){
    if(response.status && response.statuscode === 200){
      this.setState({costTypes: response.result.map((costType)=> ({...costType, label: costType.costTypeName, value: costType.id}))});
    }
  }

  postTransactionReceiptAssignment=(newReceiptsToAdd=[])=>{
    const {selectedTransaction, propertySelected} = this.props;
    const {selectedReceipts, selectedTenant, selectedType, autoReceiptCreation, autoReceiptCreationOldStatus, selectedMonth} = this.state;

    if(selectedType === 'CREDIT_DEBIT_BALANCE' && (selectedReceipts.length > 0 || newReceiptsToAdd.length > 0)){
      console.log('postTransactionReceiptAssignment: newReceiptsToAdd, selectedReceipts:', newReceiptsToAdd, selectedReceipts);
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.finance_api_base_url + 'ReceiptTenantTransactions',
        'POST',
        {
          transactionId: selectedTransaction?.id,
          receiptIds: newReceiptsToAdd.length > 0 ? newReceiptsToAdd : selectedReceipts,
          tenantId: null,
          propertyId: propertySelected.id,
          rentalMonth: null
        },
        null,
        false,
        true,
        false,
        this,
        'handlePostTransactionReceiptAssignment',
        {type: 'receipt', eol: true},
        false,
        '',
        {
          'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        },
        true
      );
      
    }else if(selectedType === 'RENTAL_INCOME' && selectedTenant.length > 0){
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.finance_api_base_url + 'ReceiptTenantTransactions',
        'POST',
        {
          transactionId: selectedTransaction?.id,
          receiptIds: null,
          tenantId: selectedTenant[0],
          propertyId: propertySelected.id,
          rentalMonth: dayjs(selectedMonth, 'MMMM YYYY').add(1, 'day').utc().format()
        },
        null,
        false,
        true,
        false,
        this,
        'handlePostTransactionReceiptAssignment',
        {type:'tenant', eol: true},
        false,
        '',
        {
          'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        },
        true
      );
    }
  }

  handlePostTransactionReceiptAssignment(response){
    const {edit, selectedTransaction, toggle} = this.props;
    const {autoReceiptCreation, selectedReceipts, autoReceiptCreationOldStatus} = this.state;
    
    if(response.status && response.statuscode === 200){
      showNotification({
        message: edit ? 'Änderungen übernommen.' : 'Beleg erfolgreich zugeordnet.',
        icon: <img src={GreenTick} alt='green-tick'/>,
        id: 'assignReceiptTransaction'
      });
      toggle(true);
    }else{
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='red-cross'/>,
        id: 'assignReceiptTransactionError'
      });
    }
  }

  checkForDeletion=()=>{
    const {selectedTransaction, toggle, edit} = this.props;
    const {selectedReceipts, selectedType, selectedTenant, autoReceiptCreation, autoReceiptCreationOldStatus, selectedMonth} = this.state;

    if(edit){
      //this.firstDeleteThanPostTransactionMapping();
      
      if(selectedType === 'RENTAL_INCOME'){
        if(selectedTransaction?.transactionAssigments?.receiptIds?.length > 0){
          this.deleteTransactionReceiptAssignment(selectedTransaction?.transactionAssigments.receiptIds);
        }else{
          this.deleteTransactionTenantAssignment(selectedTransaction?.transactionAssigments?.tenantId);
        }
        // else if(selectedTransaction?.transactionAssigments?.tenantId && (selectedTenant.length === 0 || selectedTenant[0] !== selectedTransaction?.transactionAssigments?.tenantId || selectedMonth !== selectedTransaction?.transactionAssigments?.rentalMonth)){
        //   this.deleteTransactionTenantAssignment(selectedTransaction?.transactionAssigments?.tenantId);
        // }
        // else if(selectedTransaction?.transactionAssigments?.tenantId && (selectedTenant.length > 0 || selectedTenant[0] !== selectedTransaction?.transactionAssigments?.tenantId || selectedMonth !== selectedTransaction?.transactionAssigments?.rentalMonth)){
        //   this.handleAddTenantReceiptAssigment();
        // }
      }else if(selectedType === 'CREDIT_DEBIT_BALANCE'){
        if(selectedTransaction?.transactionAssigments?.tenantId){
          this.deleteTransactionTenantAssignment(selectedTransaction?.transactionAssigments?.tenantId);
        }else{
          let oldReceiptsToDelete= selectedTransaction?.transactionAssigments?.receiptIds?.length > 0 ? selectedTransaction?.transactionAssigments.receiptIds.filter((receiptId)=> selectedReceipts.find((id)=> receiptId === id) === undefined) : [];
          if(oldReceiptsToDelete.length > 0){
            this.deleteTransactionReceiptAssignment(oldReceiptsToDelete);
          }else{
            this.handleAddTenantReceiptAssigment();
          }
        } 
      }else{
        toggle(true);
      }
    }else{
      //this.firstDeleteThanPostTransactionMapping();
      this.handleAddTenantReceiptAssigment();
    }
  }

  firstDeleteThanPostTransactionMapping=()=> this.deleteAllTransactionMapping();

  transactionMappingSetup=(getTransactionMapping=false)=>{
    const {selectedTransaction, selectedBankAccount, accountData, propertySelected} = this.props;
    const {selectedReceipts, selectedTenant, receiptsList, selectedType, isEarlyPaymentEnabled, autoReceiptCreation} = this.state;
    let payloads=[];
    if(selectedType === 'CREDIT_DEBIT_BALANCE' && selectedReceipts.length > 0){
      let receipts= receiptsList.filter((receipt)=> selectedReceipts.find((receiptId)=> receiptId === receipt.id) !== undefined)
      receipts.map((receipt)=> 
      payloads.push({
        accountId: accountData.accountId,
        propertyId: propertySelected.id,
        bankAccountId: selectedBankAccount.id,
        transactionType: 200,
        counterpartIban: selectedTransaction?.counterpartIban,
        counterpartName:	selectedTransaction?.counterpartName,
        utilityBillingCostTypeId: receipt?.selectedCostTypes.length > 0 ? receipt?.selectedCostTypes[0]?.id : null,
        taxCostType: receipt?.taxCostType,
        taxRate: receipt?.vatPercent,
        tenantId: null,
        isEarlyPaymentEnabled: false
      }));

      this.postTransactionMapping(payloads, getTransactionMapping);
    }else if(selectedType === 'RENTAL_INCOME' && selectedTenant){
      payloads.push({
        accountId: accountData.accountId,
        propertyId: propertySelected.id,
        bankAccountId: selectedBankAccount.id,
        transactionType: 100,
        counterpartIban: selectedTransaction?.counterpartIban,
        counterpartName:	selectedTransaction?.counterpartName,
        utilityBillingCostTypeId: null,
        taxCostType: null,
        taxRate: null,
        tenantId: selectedTenant[0],
        isEarlyPaymentEnabled: autoReceiptCreation ? isEarlyPaymentEnabled : false
      });

      this.postTransactionMapping(payloads, getTransactionMapping);
    }
  }

  postTransactionMapping=(payloads, getTransactionMapping=false)=>{
    hideNotification('postTrasactionMappingSuccess');
    payloads.map((payload)=> 
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + 'TransactionMappings',
      'POST',
      payload,
      null,
      false,
      true,
      false,
      this,
      'handlePostTransactionMapping',
      {getTransactionMapping},
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    ));
  }

  handlePostTransactionMapping(response){
    if(response.status && response.statuscode === 200){
      // showNotification({
      //   message: this.props.edit ? 'Änderungen übernommen.' : 'Beleg erfolgreich zugeordnet.',
      //   icon: <img src={GreenTick} alt='green-tick'/>,
      //   id: 'assignReceiptTransaction'
      // });
      if(response.other.getTransactionMapping){
        showNotification({
          message: 'Regel erfolgreich hinzugefügt.',
          icon: <img src={GreenTick} alt='green-tick'/>,
          id: 'postTrasactionMappingSuccess'
        });
        this.getTransactionMapping();
      }else{
        this.props.toggle(true);
      }
    }else{
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='red-cross'/>,
        id: 'PostTrasactionMappingError'
      });
    }
  }

  putTransactionMapping=(mappingId)=>{
    console.log('putTransactionMapping: ', mappingId);
    const {mappings} = this.state;
    const payload = mappings.find((mapping)=> mapping.id === mappingId);
    if(payload !== undefined){
      this.setState({mappingLoader: mappingId});
      hideNotification('putTrasactionMappingSuccess');
      hideNotification('putTrasactionMappingError');
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.finance_api_base_url + 'TransactionMappings/'+ mappingId,
        'PUT',
        payload,
        null,
        false,
        true,
        false,
        this,
        'handlePutTransactionMapping',
        null,
        false,
        '',
        {
          'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        },
        true
      );
    }
  }

  handlePutTransactionMapping(response){
    if(response.status && response.statuscode === 200){
      showNotification({
        message: 'Regel erfolgreich aktualisiert.',
        icon: <img src={GreenTick} alt='green-tick'/>,
        id: 'putTrasactionMappingSuccess'
      });
      this.getTransactionMapping();
    }else{
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='red-cross'/>,
        id: 'putTrasactionMappingError'
      });
      this.setState({mappingLoader: false});
    }
  }

  getTransactionMapping=(firstCall=false)=>{
    if(firstCall){
      this.setState({mappingLoader: true});
    }
    const {selectedBankAccount, selectedTransaction, propertySelected} = this.props;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + `TransactionMappings/Property/${propertySelected.id}/Iban/${selectedTransaction?.counterpartIban}`,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetTransactionMapping',
      {firstCall},
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }

  handleGetTransactionMapping(response){
    const {selectedType} = this.state;
    const {selectedTransaction} = this.props;
    if(response.status && response.statuscode === 200){
      console.log('selectedTransaction?.transactionAssigments: ', selectedTransaction?.transactionAssigments);
      this.setState({selectedType: (response.result.length > 0 && response.result[0].transactionType	=== 'Rent') ? 'RENTAL_INCOME' : 'CREDIT_DEBIT_BALANCE', 
        mappings: response.result, 
        autoReceiptCreation: true, 
        autoReceiptCreationOldStatus: true, 
        isEarlyPaymentEnabled: selectedType === 'RENTAL_INCOME' ? (response.result.length > 0 ? response.result[0].isEarlyPaymentEnabled : false) : false,
        openAutoAssignReceiptTenantTransaction: response.other.firstCall ? selectedTransaction?.transactionAssigments ? false : true : false
      });
    }else if(response.statuscode === 400 && response.message === 'NOT_FOUND'){
      this.setState({mappings: [], autoReceiptCreation: false});
    }
    this.setState({mappingLoader: false});
  }

  deleteAllTransactionMapping=()=>{
    const {selectedBankAccount, selectedTransaction, propertySelected} = this.props;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + `TransactionMappings/Property/${propertySelected.id}/Iban/${selectedTransaction?.counterpartIban}`,
      'DELETE',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleDeleteAllTransactionMapping',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }

  handleDeleteAllTransactionMapping(response){
    // const {autoReceiptCreation, selectedType, selectedTenant, selectedReceipts} = this.state;
    // console.log('handleDeleteTransactionMapping: ', response);
    // if(autoReceiptCreation && selectedType && (selectedTenant.length > 0 || selectedReceipts.length > 0)){
    //   this.transactionMappingSetup();
    // }
    this.getTransactionMapping();
  }

  deleteTransactionMapping=(mappingId)=>{
    const {selectedTransaction} = this.props;
    this.setState({mappingLoader: mappingId});
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + "TransactionMappings/" + mappingId,
      'DELETE',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleDeleteTransactionMapping',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }

  handleDeleteTransactionMapping(response){
    if(response.status && response.statuscode === 200){
      showNotification({
        message: 'Regel erfolgreich gelöscht.',
        icon: <img src={RedTick} alt='red-tick'/>,
        id: 'DeleteTrasactionMapping'
      });
      this.getTransactionMapping();
    }else{
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='red-cross'/>,
        id: 'DeleteTrasactionMappingError'
      });
      this.setState({mappingLoader: false});
    }
  }

  deleteTransactionReceiptAssignment=(receipts)=>{
    const {selectedTransaction} = this.props;

    receipts.map((receiptId, index)=> 
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + `ReceiptTenantTransactions/Transaction/${selectedTransaction?.id}/Receipt/${receiptId}`,
      'DELETE',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleDeleteTransactionReceiptAssignment',
      {eol: receipts.length === (index + 1), tenant: false},
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    ));
  }

  deleteTransactionTenantAssignment=(tenantId)=>{
    const {selectedTransaction} = this.props;

    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + `ReceiptTenantTransactions/Transaction/${selectedTransaction?.id}/Tenant/${tenantId}`,
      'DELETE',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleDeleteTransactionReceiptAssignment',
      {eol: true, tenant: true},
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }

  handleDeleteTransactionReceiptAssignment(response){
    if(response.other.eol){
      if(response.other.tenant){
        if(response.status && response.statuscode === 200){
          this.handleAddTenantReceiptAssigment();
        }else{
          showNotification({
            message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
            icon: <img src={RedCross} alt='red-cross'/>
          });
        }
      }else{
        this.handleAddTenantReceiptAssigment();
      }
    }
  }

  handleAddTenantReceiptAssigment=()=>{
    const {edit, selectedTransaction, toggle} = this.props;
    const {selectedReceipts, selectedType, selectedTenant, autoReceiptCreation, autoReceiptCreationOldStatus} = this.state;

    if(edit){
      if(selectedType === 'CREDIT_DEBIT_BALANCE' && selectedReceipts?.length > 0){
        let newReceiptsToAdd= selectedTransaction?.transactionAssigments?.receiptIds?.length > 0 ? selectedReceipts.filter((id)=> selectedTransaction?.transactionAssigments.receiptIds.find((receiptId)=> receiptId === id) === undefined) : selectedReceipts;
        if(newReceiptsToAdd.length > 0){
          this.postTransactionReceiptAssignment(newReceiptsToAdd);
        }else{
          toggle(true);
        }
      }else if(selectedType === 'RENTAL_INCOME' && selectedTenant.length > 0){
        this.postTransactionReceiptAssignment();
      }else{
        toggle(true);
      }
    }else{
      this.postTransactionReceiptAssignment();
    }
  }

  handleAddReceiptModalOpen=(e)=>{
    //e.preventDefault();
    this.setState({addReceiptOpen: true});
  }

  openShow = (id, props) => {

    console.log('openShow: ', props);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.rm_api_base_url + 'attachment/' + id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handle_get_show_file',
      id,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  
  _handle_get_show_file = (response) => {
    const {receiptsList} = this.state;
    if (response.status === false && response.message !== 'Not Found') {
      
    }

    if(response && response.result.includes('pdf')){
      fetch(Autoload.Setting.rm_api_base_url + 'attachment/' + response.other +'/download',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          'Authorization': Autoload.Utils.getAuthToken()
        }
      })
            .then(response1 => {
                response1.blob()
                .then(blob => {
                  console.log('blob: ', blob)
                  let currentRecord = receiptsList.find((receipt)=> receipt.id === response.other);
                  console.log('currentRecord: ', currentRecord, response, receiptsList);
                  if(currentRecord){
                    this.setState({
                      currentRecord: { ...currentRecord, file_name: blob.size > 0 ? blob : '' },
                      showModal: true,
                    });
                  }else{
                    showNotification({
                      message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
                      icon: <img src={RedCross} alt='red-cross'/>
                    });
                  }})
                  var loader = document.getElementById('hello-housing-loader');
                  loader.style.display = 'none';
              })
    } else {
      let currentRecord = receiptsList.find((receipt)=> receipt.id === response.other);
      if(currentRecord){
        this.setState({
          currentRecord: { ...currentRecord, file_name: response.result != null ? response.result : '' },
          showModal: true,
        });
      }else{
        showNotification({
          message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
          icon: <img src={RedCross} alt='red-cross'/>
        });
      }
      
      var loader = document.getElementById('hello-housing-loader');
      loader.style.display = 'none';
    }
  };

  getMappingTableHeader=()=>{
    const {selectedType} = this.state;
    return (
      <TableHead>
        {selectedType === 'CREDIT_DEBIT_BALANCE' ?
        <TableRow>
          <TableCell className='transaction-mapping-header'>Kostenart</TableCell>
          <TableCell className='transaction-mapping-header'>Steuerliche Kostenart</TableCell>
          <TableCell className='transaction-mapping-header'>MWst.-Satz</TableCell>
          <TableCell/>
        </TableRow>
        :
        <TableRow>
          <TableCell className='transaction-mapping-header'>Mietername</TableCell>
          <TableCell className='transaction-mapping-header'>Einheit</TableCell>
          <TableCell className='transaction-mapping-header'>Zahlt vorfällig</TableCell>
          <TableCell/>
        </TableRow>}
      </TableHead>
    )
  }

  getMappingTableBody=()=>{
    const {selectedType, mappings, costTypes, tenants} = this.state;
    console.log('tenants: ', tenants);
    return(
      <TableBody>
        {mappings.map((mapping)=> 
        selectedType === 'CREDIT_DEBIT_BALANCE' ? 
        <TableRow //className={`transaction-mapping-cards transaction-mapping-row`}
        >
          {/* <TableCell className='banks-table-cell'>{costTypes.find((costType)=> costType.id === mapping.utilityBillingCostTypeId)?.costTypeName}</TableCell>
          <TableCell className='banks-table-cell'>{mapping.taxCostType}</TableCell>
          <TableCell className='banks-table-cell'>{`${mapping.taxRate}%`}</TableCell>
          <TableCell className='banks-table-cell'>
            <button onClick={()=> null} type="button" class="close delete-cost-position" aria-label="Close"><span aria-hidden="true">×</span></button>
          </TableCell> */}
          <TableCell>
            <span style={{fontFamily: 'Inter, sans-serif', fontSize: '12px'}}>Beleg erzeugen (<span className='transaction-mapping-capsules-receipts'>{costTypes.find((costType)=> costType.id === mapping.utilityBillingCostTypeId)?.costTypeName}</span>, <span className='transaction-mapping-capsules-receipts'>{mapping.taxCostType}</span>, <span className='transaction-mapping-capsules-receipts'>{mapping.taxRate}%</span>)</span>
          </TableCell>
        </TableRow>
        :
        <TableRow className={`transaction-mapping-cards transaction-mapping-row`}>
          <TableCell className='banks-table-cell'>{tenants.find((tenant)=> tenant.id === mapping.tenantId)?.name}</TableCell>
          <TableCell className='banks-table-cell'>{tenants.find((tenant)=> tenant.id === mapping.tenantId)?.rentalUnitName}</TableCell>
          <TableCell className='banks-table-cell'>{mapping.isEarlyPaymentEnabled ? <Tooltip label='Zahlt vorfällig'>{UtilityBillingAssignedIcon('#5555FF')}</Tooltip> : <Tooltip label='nicht früh bezahlt'>{UtilityBillingAssignedIcon('#9A9AB5')}</Tooltip>}</TableCell>
          <TableCell className='banks-table-cell'>
            <button onClick={()=> null} type="button" class="close delete-cost-position" aria-label="Close"><span aria-hidden="true">×</span></button>
          </TableCell>
        </TableRow>)}
      </TableBody>
    )
  }

  handleMappingChange=(mappingId, field, selectedValue)=>{
    let {mappings} = this.state;
    mappings = mappings.map((mapping)=> {
      if(mapping.id === mappingId){
        mapping[field]= selectedValue;
      }
      return mapping;
    });
    this.setState({mappings}, ()=> this.putTransactionMapping(mappingId));
  }

  removeMapping=(mappingId)=>{
    this.deleteTransactionMapping(mappingId);
    // let {mappings} = this.state;
    // mappings = mappings.filter((mapping)=> mapping.id !== mappingId);
    // this.setState({mappings}, ()=> this.deleteTransactionMapping(mappingId));
  }

  renderMappingData=(hideDeleteButton=false)=>{
    const {selectedType, mappings, costTypes, tenants, openCostTypeMenu, taxCostTypes, openTaxCostTypeMenu, openTaxRateMenu, customTaxRate, mappingLoader, openTenantMenu, openIsEarlyPaymentMenu} = this.state;
    console.log('tenants: ', tenants);
    return(mappings.map((mapping)=> 
    mapping.transactionType === 'Expenditure' ?
    <Group noWrap position='apart' style={{width: '100%'}} className='tooltips transaction-mapping-item'>
      <div>
        <span className='transaction-mapping-receipts-text'>
          <span style={{fontWeight: '500'}}>Beleg erzeugen</span> (&nbsp;
            <Menu 
              style={{maxHeight: '150px'}}
              styles={{
                body: {
                  overflowY: 'scroll'
                }
              }}
              withinPortal={true} 
              zIndex={99999} 
              opened={openCostTypeMenu === mapping.id} 
              onOpen={()=> hideDeleteButton === false && this.setState({openCostTypeMenu: openCostTypeMenu === false ? mapping.id : false, stopToggle: openCostTypeMenu === false ? true : false})} 
              onClose={()=> this.setState({openCostTypeMenu: false, stopToggle: false})}
              control={
              <div>
                <TruncatedTextHandler 
                truncate={false} 
                removePointerCursor={hideDeleteButton}
                alwaysEnableTooltip={costTypes.find((costType)=> costType.id === mapping.utilityBillingCostTypeId) === undefined} 
                maxWidth={120} 
                alternateTooltipText={costTypes.find((costType)=> costType.id === mapping.utilityBillingCostTypeId) === undefined ? 'keine Angabe' : null} 
                className={costTypes.find((costType)=> costType.id === mapping.utilityBillingCostTypeId)?.costTypeName !== undefined ? 'transaction-mapping-capsules-receipts' : 'transaction-mapping-capsules-empty'} 
                text={costTypes.find((costType)=> costType.id === mapping.utilityBillingCostTypeId)?.costTypeName !== undefined ? costTypes.find((costType)=> costType.id === mapping.utilityBillingCostTypeId)?.costTypeName : 'Betriebskostenart'}/>
              </div>}>
              <Menu.Item style={{background: mapping.utilityBillingCostTypeId === null && '#e6e6ff', fontSize: '12px'}} styles={{itemLabel: {lineHeight: '15px'}}} onClick={()=> this.handleMappingChange(mapping.id, 'utilityBillingCostTypeId', null)}>keine Angabe</Menu.Item>
              {costTypes.map((costType)=> <Menu.Item style={{background: costType.value === mapping.utilityBillingCostTypeId && '#e6e6ff', fontSize: '12px'}} styles={{itemLabel: {lineHeight: '15px'}}} onClick={()=> this.handleMappingChange(mapping.id, 'utilityBillingCostTypeId', costType.id)}>{costType.label}</Menu.Item>)}
            </Menu>,&nbsp;
            <Menu 
              style={{maxHeight: '150px'}}
              styles={{
                body: {
                  overflowY: 'scroll'
                }
              }}
              withinPortal={true} 
              zIndex={99999} 
              opened={openTaxCostTypeMenu === mapping.id} 
              onOpen={()=> hideDeleteButton === false && this.setState({openTaxCostTypeMenu: openTaxCostTypeMenu === false ? mapping.id : false, stopToggle: openTaxCostTypeMenu === false ? true : false})} 
              onClose={()=> this.setState({openTaxCostTypeMenu: false, stopToggle: false})}
              control={
              <div>
                <TruncatedTextHandler 
                truncate={false} 
                removePointerCursor={hideDeleteButton}
                alwaysEnableTooltip={mapping.taxCostType === null}
                maxWidth={120} 
                alternateTooltipText={mapping.taxCostType === null ? 'keine Angabe' : null} 
                className={mapping.taxCostType ? 'transaction-mapping-capsules-receipts' : 'transaction-mapping-capsules-empty'} 
                text={mapping.taxCostType ? mapping.taxCostType : 'steuerliche Kostenart'}/> 
              </div>}>
              <Menu.Item style={{background: mapping.taxCostType === null && '#e6e6ff', fontSize: '12px'}} styles={{itemLabel: {lineHeight: '15px'}}} onClick={()=> this.handleMappingChange(mapping.id, 'taxCostType', null)}>keine Angabe</Menu.Item>
              {taxCostTypes.map((taxCostType)=> <Menu.Item style={{background: taxCostType.label === mapping.taxCostType && '#e6e6ff', fontSize: '12px'}} styles={{itemLabel: {lineHeight: '15px'}}} onClick={()=> this.handleMappingChange(mapping.id, 'taxCostType', taxCostType.label)}>{taxCostType.label}</Menu.Item>)}
            </Menu>,&nbsp;
            {customTaxRate ? 
            <span>
              <NumericFormat 
              // classNames={{input: 'transaction-mapping-capsules-receipts'}} 
              className='transaction-mapping-capsules-receipts'
              style={{maxWidth: '44px'}}
              //styles={{rightSection: {display: 'none'}, input: {maxWidth: '60px', padding: '5px', maxHeight: '24px', minHeight: '24px', fontSize: '12px'}, root: {maxWidth: '60px', padding: 0}}} 
              value={mapping.taxRate}
              thousandSeparator="." 
              decimalSeparator=","
              suffix={'%'}
              placeholder='0%'
              decimalScale={0}
              onValueChange={(values, sourceInfo) => this.handleMappingChange(mapping.id, 'taxRate', values.floatValue)}
              //onChange={(val)=> this.handleMappingChange(mapping.id, 'taxRate', val)}
              />
            </span>
            :
            <Menu 
              style={{maxHeight: '150px'}}
              styles={{
                body: {
                  overflowY: 'scroll'
                }
              }}
              withinPortal={true} 
              zIndex={99999} 
              opened={openTaxRateMenu === mapping.id} 
              onOpen={()=> hideDeleteButton === false && this.setState({openTaxRateMenu: openTaxRateMenu === false ? mapping.id : false, stopToggle: openTaxRateMenu === false ? true : false})} 
              onClose={()=> this.setState({openTaxRateMenu: false, stopToggle: false})}
              control={
              <div>
                <TruncatedTextHandler 
                truncate={false} 
                removePointerCursor={hideDeleteButton}
                alwaysEnableTooltip={mapping.taxRate === null}
                maxWidth={120} 
                alternateTooltipText={mapping.taxRate === null ? 'keine Angabe' : null} 
                className={mapping.taxRate ? 'transaction-mapping-capsules-receipts' : 'transaction-mapping-capsules-empty'} 
                text={mapping.taxRate ? `${mapping.taxRate}%` : 'MWst.-Satz'}/> 
              </div>}>
                <Menu.Item style={{background: mapping.taxRate === null && '#e6e6ff', fontSize: '12px'}} styles={{itemLabel: {lineHeight: '15px'}}} onClick={()=> this.handleMappingChange(mapping.id, 'taxRate', null)}>keine Angabe</Menu.Item>
                <Menu.Item style={{background: mapping.taxRate === 19 && '#e6e6ff', fontSize: '12px'}} styles={{itemLabel: {lineHeight: '15px'}}} onClick={()=> this.handleMappingChange(mapping.id, 'taxRate', 19)}>19%</Menu.Item>
                <Menu.Item style={{background: mapping.taxRate === 7 && '#e6e6ff', fontSize: '12px'}} styles={{itemLabel: {lineHeight: '15px'}}} onClick={()=> this.handleMappingChange(mapping.id, 'taxRate', 7)}>7%</Menu.Item>
                <Menu.Item style={{fontSize: '12px'}} styles={{itemLabel: {lineHeight: '15px'}}} onClick={()=> this.setState({customTaxRate: true})}>Andere</Menu.Item>
            </Menu>}
            &nbsp;)
        </span>
      </div>
      {hideDeleteButton === false &&
      (mappingLoader === mapping.id ?
      <div>
        <Loader size={'xs'}/>
      </div>
      :
      <div>
        <MantineTooltip zIndex={99999} label='Regel löschen'><button onClick={()=> this.removeMapping(mapping.id)} type="button" class="close delete-cost-position" aria-label="Close"><span aria-hidden="true">×</span></button></MantineTooltip>
      </div>)}
    </Group>
    :
    <Group noWrap position='apart' style={{width: '100%'}}>
      <span className='transaction-mapping-receipts-text'>
        <span style={{fontWeight: '500'}}>Mietzahlung erfassen</span> (&nbsp;
        <Menu 
          style={{maxHeight: '150px'}}
          styles={{
            body: {
              overflowY: 'scroll'
            }
          }}
          withinPortal={true} 
          zIndex={99999} 
          opened={openTenantMenu === mapping.id} 
          onOpen={()=> hideDeleteButton === false && this.setState({openTenantMenu: openTenantMenu === false ? mapping.id : false, stopToggle: openTenantMenu === false ? true : false})} 
          onClose={()=> this.setState({openTenantMenu: false, stopToggle: false})}
          control={
          <div>
            <TruncatedTextHandler 
            truncate={false} 
            removePointerCursor={hideDeleteButton}
            alwaysEnableTooltip={tenants.find((tenant)=> tenant.id === mapping.tenantId) === undefined}
            maxWidth={140} 
            alternateTooltipText={tenants.find((tenant)=> tenant.id === mapping.tenantId) === undefined ? 'keine Angabe' : null} 
            className={tenants.find((tenant)=> tenant.id === mapping.tenantId) !== undefined ? 'transaction-mapping-capsules-tenant' : 'transaction-mapping-capsules-empty'} 
            text={`${tenants.find((tenant)=> tenant.id === mapping.tenantId) !== undefined ? tenants.find((tenant)=> tenant.id === mapping.tenantId)?.label : 'Mieter'}`}/>
          </div>}>
          <Menu.Item style={{background: mapping.tenantId === null && '#e6e6ff', fontSize: '12px'}} styles={{itemLabel: {lineHeight: '15px'}}} onClick={()=> this.handleMappingChange(mapping.id, 'tenantId', null)}>keine Angabe</Menu.Item>
          {tenants.map((tenant)=> <Menu.Item style={{background: tenant.value === mapping.tenantId && '#e6e6ff', fontSize: '12px', lineHeight: '15px'}} styles={{itemLabel: {lineHeight: '15px'}}} onClick={()=> this.handleMappingChange(mapping.id, 'tenantId', tenant.id)}>{tenant.label}</Menu.Item>)}
        </Menu>,&nbsp;
        <Menu 
          style={{maxHeight: '150px'}}
          styles={{
            body: {
              overflowY: 'scroll'
            }
          }}
          withinPortal={true} 
          zIndex={99999} 
          opened={openIsEarlyPaymentMenu === mapping.id} 
          onOpen={()=> hideDeleteButton === false && this.setState({openIsEarlyPaymentMenu: openIsEarlyPaymentMenu === false ? mapping.id : false, stopToggle: openIsEarlyPaymentMenu === false ? true : false})} 
          onClose={()=> this.setState({openIsEarlyPaymentMenu: false, stopToggle: false})}
          control={
          <div>
            <TruncatedTextHandler 
            truncate={false}
            removePointerCursor={hideDeleteButton}
            maxWidth={130} 
            className={'transaction-mapping-capsules-receipts'} 
            text={mapping.isEarlyPaymentEnabled ? 'zahlt vorfristig' : 'zahlt regulär'}/> 
          </div>}>
          <Menu.Item className='tooltips transaction-mapping-rental-payment-early-payment-true' style={{background: mapping.isEarlyPaymentEnabled && '#e6e6ff', fontSize: '12px'}} styles={{itemLabel: {lineHeight: '15px'}}} onClick={()=> this.handleMappingChange(mapping.id, 'isEarlyPaymentEnabled', true)}>{'zahlt vorfristig'}</Menu.Item>
          <Menu.Item className='tooltips transaction-mapping-rental-payment-early-payment-false' style={{background: !mapping.isEarlyPaymentEnabled && '#e6e6ff', fontSize: '12px'}} styles={{itemLabel: {lineHeight: '15px'}}} onClick={()=> this.handleMappingChange(mapping.id, 'isEarlyPaymentEnabled', false)}>{'zahlt regulär'}</Menu.Item>
        </Menu>&nbsp;)
        
      </span>
      {hideDeleteButton === false &&
      (mappingLoader === mapping.id ?
      <div>
        <Loader size={'xs'}/>
      </div>
      :
      <div>
        <MantineTooltip zIndex={99999} label='Regel löschen'><button onClick={()=> this.deleteTransactionMapping(mapping.id)} type="button" class="close delete-cost-position" aria-label="Close"><span aria-hidden="true">×</span></button></MantineTooltip>
      </div>)}
    </Group>));
  }

  addMapping=()=>{
    const {selectedType} = this.state;
    const {selectedTransaction, propertySelected, accountData, selectedBankAccount} = this.props;
    let payload={
      accountId: accountData.accountId,
      propertyId: propertySelected.id,
      bankAccountId: selectedBankAccount.id,
      transactionType: selectedType === 'CREDIT_DEBIT_BALANCE' ? 200 : 100,
      counterpartIban: selectedTransaction?.counterpartIban,
      counterpartName:	selectedTransaction?.counterpartName,
      utilityBillingCostTypeId: null,
      taxCostType: null,
      taxRate: null,//selectedType === 'CREDIT_DEBIT_BALANCE' ? 19 : null,
      tenantId: null,
      isEarlyPaymentEnabled: false
    };
    this.postTransactionMapping([payload], true);
  }

  getCurrentBillingPeriod=()=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url +
      'currentBillingPeriod' +
      '?propertyId=' +
      this.props.propertySelected.id +
      '&offset=0&fetch=10',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      "handleGetCurrentBillingPeriodResponse",
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleGetCurrentBillingPeriodResponse(response){
    if(response.status && response.statuscode === 200){
      this.setState({currentBillingPeriod: response.result});
    }
  }

  calculateVatUsingAmount =(value, vatPercent)=> {
    return value - (value / parseFloat(`${1 + (vatPercent / 100)}`));
  }

  autoAddReceipts=()=>{
    const {selectedTransaction, propertySelected} = this.props;
    const {mappings, currentBillingPeriod} = this.state;
    mappings.map((mapping)=> {
      let netAmount = this.calculateVatUsingAmount(selectedTransaction?.amount, mapping.taxRate);
      let vatAmount = selectedTransaction?.amount - netAmount;

      console.log('autoAddReceipts: ', selectedTransaction?.amount, netAmount, vatAmount);
      const data = JSON.stringify({
        id: null,
        name: selectedTransaction?.counterpartName,
        receiptDate: `${dayjs(new Date(selectedTransaction?.bankBookingDate)).format('YYYY-MM-DD')}T00:00:00`,
        date: `${dayjs(new Date(selectedTransaction?.bankBookingDate)).format('YYYY-MM-DD')}T00:00:00`,
        costs: selectedTransaction?.amount,
        cost: selectedTransaction?.amount,
        utilityBillingRelevant: mapping.utilityBillingCostTypeId ? true : null,
        receiptPeriodStart: currentBillingPeriod?.startDate,
        receiptPeriodEnd: currentBillingPeriod?.endDate,
        propertyId: propertySelected.id,
        selectedCostTypes: mapping.utilityBillingCostTypeId ? [{ id: mapping.utilityBillingCostTypeId }] : [],
        //New attributes
        vatPercent: mapping.taxRate,
        vatAmount,
        netAmount,
        laborCost: null,
        taxCostType: mapping.taxCostType,
        receiptNumber: null,
        notes: selectedTransaction?.purpose
      });
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.rm_api_base_url + 'create',
        'POST',
        data,
        null,
        false,
        true,
        false,
        this,
        'handleAutoAddReceipt',
        null,
        false,
        '',
        {
          'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    });
  }

  handleAutoAddReceipt(response){
    const {selectedReceipts} = this.state;
    if(response.status && response.statuscode === 200){
      this.setState({selectedReceipts: [...selectedReceipts, response.result]},()=> this.getReceipts());
    }
  }

  autoAssignTenant=()=>{
    const {mappings} = this.state;
    this.setState({selectedTenant: [mappings[0].tenantId]});
  }
  
  render() {
    const { open, toggle, className, rentalUnits, selectedTransaction, edit } = this.props;
    const { hover1, hover2, selectedType, isMobile, receiptsLoader, receiptsList, selectedReceipts, selectedTenant, selectReceiptsForTransactionAssigmentModalOpen, autoReceiptCreation, tenants, dropdownIcon, addReceiptOpen, ReceiptPage, rightSectionProps, showModal, ImageOpen, currentRecord, selectedMonth, dropdownIconForMonths, isEarlyPaymentEnabled, mappings, stopToggle, openAutoAssignReceiptTenantTransaction, openDeleteAllTransactionMappingConfirmationModal, mappingLoader } = this.state;
    
    console.log('rentalUnits: ', rentalUnits);
    console.log('receiptsList: ', receiptsList);
    console.log('selectedReceipts_1234: ', selectedReceipts, receiptsList.filter((receipt)=> selectedReceipts.find((receiptId)=> receiptId === receipt.id) !== undefined));
    console.log('selectedTenant_1234: ', selectedTenant);

    console.log('tenants: ', tenants);

    console.log('edit: ', edit);

    console.log('selectedTransaction: ', selectedTransaction);
    console.log('stopToggle: ', stopToggle);
    return (
      <CustomModal
        heading={'Beleg zuordnen'}
        headingClassName={'assign-transaction-modal-heading'}
        modalTitleClassName={'assign-receipt-transaction-modal-heading tooltips'}
        open={open}
        toggle={() => {
          console.log('toggle');
          if(!stopToggle){
            toggle();
          }
        }}
        className={`${className}`}
      >
        <Stack spacing={20}>
          <Stack spacing={0}>
            <Group><span className='add-allocation-sub-headings'>Art der Zahlung</span></Group>
            {/* <Group grow noWrap style={{margin: '20px 0px 5px'}}> */}
            <SimpleGrid cols={2} style={{margin: '20px 0px 5px'}}>
                  <CardSelection
                  title={'Ausgabe'}
                  subText={'Bei der Zahlung handelt es sich um eine Ausgabe oder Erstattung.'}
                  icon={ReceiptsIcon}
                  blueIcon={ReceiptsIconBlue}
                  hover={hover1}
                  hoverName={'hover1'}
                  selectedType={selectedType}
                  type={'CREDIT_DEBIT_BALANCE'}
                  disabled={false}
                  onClick={()=> this.setState({selectedType: 'CREDIT_DEBIT_BALANCE', openDeleteAllTransactionMappingConfirmationModal: mappings.length > 0}, ()=> this.getReceipts())}
                  handleMouseEnter={(hoverName)=> this.handleMouseEnter(hoverName)}
                  handleMouseLeave={(hoverName)=> this.handleMouseLeave(hoverName)}
                  isMobile={isMobile}
                  //typeError={typeError}
                  />
                  <CardSelection
                  title={'Mieteinnahme'}
                  subText={'Bei der Zahlung handelt es sich um eine Mietzahlung.'}
                  icon={RentIcon}
                  blueIcon={RentIconBlue}
                  hover={hover2}
                  hoverName={'hover2'}
                  selectedType={selectedType}
                  type={'RENTAL_INCOME'}
                  disabled={false}
                  onClick={()=> this.setState({selectedType: 'RENTAL_INCOME', openDeleteAllTransactionMappingConfirmationModal: mappings.length > 0})}
                  handleMouseEnter={(hoverName)=> this.handleMouseEnter(hoverName)}
                  handleMouseLeave={(hoverName)=> this.handleMouseLeave(hoverName)}
                  isMobile={isMobile}
                  //typeError={typeError}
                  />
            </SimpleGrid>
            {/* </Group> */}
          </Stack>
            
            {selectedType === 'RENTAL_INCOME' &&
            <Stack>
                <Group>
                    <span className='modal-sub-headings'>Mieter auswählen</span>
                </Group>
                <Group grow>
                    <MultiSelect
                    data={tenants}
                    value={selectedTenant}
                    label={'Mieter'}
                    onChange={(value)=> this.setState({selectedTenant: value.length > 0 ? [value[value.length - 1]] : []})}
                    rightSection={<img src={dropdownIcon}/>}
                    valueComponent={(props)=> <Value {...props} type={'tenant'}/>}
                    className='multi-select-bank-transactions-receipt-assignment'
                    placeholder="Wählen"
                    styles={{
                        disabled: { background: 'white', cursor: 'pointer' },
                        input: {opacity: 1},
                        rightSection: {marginRight: '10px'},
                        //dropdown: {display: selectedTenant.length > 0 && 'none'},
                        //value: {maxWidth: '100px'}
                    }}
                    onClick={()=> tenants.length === 0 && 
                      showNotification({
                        message: 'Es wurden noch keine Mieter angelegt.', 
                        icon: <img src={RedCross} alt='red-cross'/>
                      })
                    }
                    maxSelectedValues={2}
                    //clearable
                    onDropdownOpen={()=> tenants.length > 0 && this.setState({dropdownIcon: Up})}
                    onDropdownClose={()=> tenants.length > 0 && this.setState({dropdownIcon: Down})}
                    zIndex={9999999}
                    //disabled={receiptsLoader}
                    />
                </Group>
                <Group grow>
                  {console.log('testing124: ',tenants.find((tenant)=> tenant.value === selectedTenant[0]), selectedTenant)}
                  <Select
                  placeholder="Wählen"
                  label={'Mietmonat'}
                  onChange={(value)=> this.setState({selectedMonth: value})}
                  data={tenants.find((tenant)=> tenant.value === selectedTenant[0]) ? tenants.find((tenant)=> tenant.value === selectedTenant[0])?.noOfMonthBetweenMoveInMoveOut : [{month: dayjs(selectedTransaction?.bankBookingDate), value: dayjs(selectedTransaction?.bankBookingDate).format('MMMM YYYY'), label: dayjs(selectedTransaction?.bankBookingDate).format('MMMM YYYY')}]}
                  value={selectedMonth}
                  searchable
                  // styles={{
                  //       item: {
                  //         color: '#0e0e1d',
                  //         fontSize: '14px',
                  //         fontWeight: '500',
                  //         fontFamily: 'sans-serif'
                  //       }
                  // }}
                  //className='multi-select-bank-transactions-receipt-assignment'
                  //maxSelectedValues={1}
                  rightSection={<img src={dropdownIconForMonths}/>}
                  onDropdownOpen={()=> this.setState({dropdownIconForMonths: Up})}
                  onDropdownClose={()=> this.setState({dropdownIconForMonths: Down})}
                  zIndex={9999999}
                  disabled={selectedTenant.length === 0}
                  />
                </Group>
            </Stack>}

            
            <Stack>
              {selectedType === 'CREDIT_DEBIT_BALANCE' &&
              <>
                <Group>
                    <span className='modal-sub-headings'>Beleg zuordnen</span>
                </Group>
                <Group position='apart' noWrap>
                  <Group grow style={{width: '100%', maxWidth: '411px'}}>
                    <MultiSelect
                    data={receiptsList}
                    value={selectedReceipts}
                    onChange={(values)=> this.setState({selectedReceipts: values})}
                    rightSection={receiptsLoader ? <Loader size={'xs'}/> : 
                    <LaunchIcon 
                    style={{fontSize: '15px', color: selectedReceipts.length > 0 ? '#5555FF' : '#9A9AB5'}}
                    onClick={()=> rightSectionProps.onClick()}
                    />}
                    valueComponent={(props)=> <Value {...props} onClick={()=> rightSectionProps.onClick()}/>} //onClick={()=> this.openShow(props.value, props)}
                    wrapperProps={selectedReceipts.length === 0 && rightSectionProps}
                    className='multi-select-bank-transactions-receipt-assignment'
                    placeholder="Wählen"
                    styles={{
                        disabled: { background: 'white', cursor: 'pointer' },
                        input: {opacity: 1},
                        rightSection: {
                          marginRight: '5px', 
                          marginLeft: '5px',
                          backgroundColor: 'white', 
                          borderTop: '1px solid #ced4da', 
                          borderBottom: '1px solid #ced4da',
                        },
                        dropdown: {display: 'none'},
                        wrapper: {width: '100%'},
                        values: {marginRight: '20px'}
                    }}
                    disabled={receiptsLoader}
                    />
                  </Group>  
                  <IconButton 
                  aria-label="filter list"
                  onClick={this.handleAddReceiptModalOpen}
                  style={{
                  background: '#5655FE',
                  border: '1px solid #5555FF', 
                  boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
                  padding: '5px',
                  cursor: 'pointer',
                  opacity: 1
                  }}>
                    <AddIcon 
                    style={{
                      color: 'white',
                      fontSize: '15px'
                      }}/>
                  </IconButton>
                </Group>
              </>}
              {selectedType &&
              <Stack style={{marginTop: '10px'}} spacing={'10px'}>
                <Group><span className='add-allocation-sub-headings tooltips remember-setting-for-transaction-assignment'>Regel merken</span></Group>
                {mappingLoader === true ?
                <Group noWrap position='center' style={{width: '100%'}}>
                  <Loader/>
                </Group>
                :
                mappings.length === 0 &&
                <Group>
                    <SwitchContainer
                    switchText={selectedType === 'RENTAL_INCOME' ? <span>Einstellungen merken und für Zahlungen mit IBAN <span style={{fontWeight: 'bold'}}>{selectedTransaction?.counterpartIban}</span> zukünftig automatisch Mietzahlung erzeugen.</span> : <span>Einstellungen merken und für Zahlungen mit IBAN <span style={{fontWeight: 'bold'}}>{selectedTransaction?.counterpartIban}</span> zukünftig automatisch Belege erzeugen</span>}
                    checked={autoReceiptCreation}
                    onChange={()=> {
                      if(selectedTransaction?.counterpartIban){
                        this.setState({autoReceiptCreation: !autoReceiptCreation}, ()=> {
                          if(!autoReceiptCreation === true && (selectedReceipts.length > 0 || selectedTenant.length > 0)){
                            this.transactionMappingSetup(true);
                          }
                        });
                      }else{
                        showNotification({
                          message: 'Für Transaktionen ohne IBAN können keine Regeln erstellt werden.', 
                          icon: <img src={RedCross} alt='red-cross'/>,
                          id: 'noCounterIbanError'
                        });
                      }
                    }}
                    disabled={mappingLoader || (selectedReceipts.length === 0 && selectedTenant.length === 0) || selectedTransaction?.counterpartIban === null}
                    textClassName={'transaction-assigment-modal-remember-switch-label'}
                    />
                </Group>}

                {/* {(selectedType === 'RENTAL_INCOME' && autoReceiptCreation && selectedTenant.length > 0) &&
                <Group>
                    <SwitchContainer
                    switchText={<span>Vorfristige Mietzahlungen berücksichtigen.</span>}
                    checked={isEarlyPaymentEnabled}
                    onChange={()=> this.setState({isEarlyPaymentEnabled: !isEarlyPaymentEnabled})}
                    disabled={!autoReceiptCreation}
                    textClassName={'transaction-assigment-modal-early-payments-switch-label tooltips'}
                    />
                </Group>} */}

                {autoReceiptCreation &&
                <Group>
                  {/* <TableContainer>
                    <Table>
                      {this.getMappingTableHeader()}
                      {this.getMappingTableBody()}
                    </Table>
                  </TableContainer> */}
                  <Stack style={{width: '100%'}}>
                    {mappings.length > 0 &&
                    <>
                      <span className='transaction-mapping-receipts-text'>Für Zahlungen mit IBAN <span style={{fontWeight: 'bold'}}>{`${selectedTransaction?.counterpartIban} (${selectedTransaction?.counterpartName})`}</span> wurden folgende Regeln angelegt:</span>
                      {this.renderMappingData()}
                    </>}
                    {selectedType === 'CREDIT_DEBIT_BALANCE' &&
                    <div>
                      {/* <ColorButton
                      text={'Weitere Regel hinzufügen'}
                      className='gray-btn'
                      onClick={()=> this.addMapping()}
                      /> */}
                      <Group noWrap style={{fontSize: '14px', fontWeight: '400', color: '#5555FF', cursor: 'pointer', margin: '5px 0px'}} spacing={3} onClick={()=> this.addMapping()}>
                        <span>Weitere Regel hinzufügen</span>
                        {/* <img src={RightArrowPartner} style={{marginTop: '2px', width: '12px'}}/> */}
                        <AddIcon style={{fontSize: '14px', color: '#5555FF', marginTop: '2px'}}/>
                      </Group>
                    </div>}
                  </Stack>
                </Group>}
              </Stack>}
            </Stack>
            <Group position='apart'>
                <div>
                    <ColorButton
                    text={'Abbrechen'}
                    className='gray-btn'
                    onClick={()=> toggle()}
                    />
                </div>
                <div>
                    <ColorButton
                    text={'Speichern'}
                    className='purple-btn'
                    onClick={()=> {
                      if(autoReceiptCreation && selectedReceipts.length > 1){
                        showNotification({
                          message: 'Um eine automatische Regel zu erstellen, wählen Sie bitte nur einen Beleg aus.',
                          icon: <img src={RedCross} alt='red-cross'/>,
                          id: 'transactionMappingError'
                        });
                      }else{
                        this.checkForDeletion();
                      }
                    }}
                    disabled={!edit && selectedReceipts.length === 0 && selectedTenant.length === 0}
                    //disabled={selectedType === 'RENTAL_INCOME' ? selectedTenant === null : selectedType === 'CREDIT_DEBIT_BALANCE' && selectedReceipts.length === 0}
                    />
                </div>
            </Group>
        </Stack>
        {selectReceiptsForTransactionAssigmentModalOpen &&
        <SelectReceiptsForTransactionAssigmentModal
        edit={edit}
        open={selectReceiptsForTransactionAssigmentModalOpen}
        toggle={()=> this.setState({selectReceiptsForTransactionAssigmentModalOpen: false})}
        selectedReceiptsFromParent={selectedReceipts}
        selectedTransaction={selectedTransaction}
        saveSelectedReceipts={(selectedReceipts)=> this.setState({selectedReceipts, selectReceiptsForTransactionAssigmentModalOpen: false})}
        getReceipts={(newAddedReceipt)=> {
          console.log('toggleAddReceiptModal: ', newAddedReceipt);
          if(newAddedReceipt){
            this.setState({selectedReceipts: [...selectedReceipts, newAddedReceipt.id]}, ()=> this.getReceipts());
          }else{
            this.getReceipts();
          }
        }}
        />}

        {addReceiptOpen && (
        <ReceiptDetailModal
          history={this.props.history}
          record={null}
          receiptPage={ReceiptPage}
          parent={this}
          className="add-edit-receipt-modal"
          edit={false}
          open={addReceiptOpen}
          toggle={(newAddedReceipt)=> {
            if(newAddedReceipt){
              this.setState({addReceiptOpen: false, selectedReceipts: [...selectedReceipts, newAddedReceipt.id]}, ()=> this.getReceipts());
            }else{
              this.setState({addReceiptOpen: false});
            }
            
          }}
          isMobile={isMobile}
          transactionAssigment={selectedTransaction}
        />
        )}

          {showModal &&
          <ShowModal
            //onEditClick={this.handleEditClick}
            onExpansionClick={()=> this.setState({ImageOpen: true})}
            record={currentRecord}
            className="show-receipt-modal"
            open={showModal}
            history={this.props.history}
            transactionAssigment={true}
            // toggle={this.toggleShowModal}
            closeShow={()=> this.setState({showModal: false, currentRecord: null})}
          />}
          {ImageOpen &&
          <ImageModal
            img={currentRecord && currentRecord.file_name !== '' ? currentRecord.file_name : Receipt}
            className="expandImageModal"
            open={ImageOpen}
            toggle={()=> this.setState({ImageOpen: false})}
          />}
          {openAutoAssignReceiptTenantTransaction &&
          <AutoAssignReceiptTenantTransaction
          renderMappingData={()=> this.renderMappingData(true)}
          selectedTransaction={selectedTransaction}
          open={openAutoAssignReceiptTenantTransaction}
          toggle={()=> this.setState({openAutoAssignReceiptTenantTransaction: false})}
          addNewReceiptTenantTransaction={()=> this.setState({openAutoAssignReceiptTenantTransaction: false},()=> selectedType === 'CREDIT_DEBIT_BALANCE' ? this.autoAddReceipts() : this.autoAssignTenant())}
          />}
          {openDeleteAllTransactionMappingConfirmationModal &&
          <DeleteAllTransactionMappingConfirmationModal
          open={openDeleteAllTransactionMappingConfirmationModal}
          toggle={()=> this.setState({openDeleteAllTransactionMappingConfirmationModal: false, selectedType: (mappings.length > 0 && mappings[0].transactionType	=== 'Rent') ? 'RENTAL_INCOME' : 'CREDIT_DEBIT_BALANCE'})}
          deleteAllMappings={()=> this.setState({openDeleteAllTransactionMappingConfirmationModal: false}, ()=> this.deleteAllTransactionMapping())}
          />}
      </CustomModal>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      propertySelected: state.generalReducer.property,
      rentalUnits: state.tenantDataReducer.rentalUnits,
      accountData: state.generalReducer.accountData
    };
  };
  
export default connect(mapStateToProps)(AssignTransactionModal);
