import { showNotification } from '@mantine/notifications';
import axios from 'axios';
import Autoload from '../Autoload';
import RedCross from '../../../src/assets/images/module/general/RedCross.svg';
import Setting from '../config/Setting';
import { Group } from '@mantine/core';
import { store, persistor } from "../../store/index";

var lang = Setting[Setting.language_default];

const avoidErrorEndpointsList = [
  'property-api/rentalUnits?offset=0&fetch=100&propertyId',
  'property-api/rentalUnits?propertyId',
  'meter-api/readings?propertyId',
  'meter-api/meters?propertyId',
  'receipt-api/receipt/property/',
  'property-api/previousBillingPeriod?billingPeriodId',
  'utility-api/utilityBillings?PropertyId',
  '/pdf',
  'utility-api/receiptAssignments/',
  'utility-api/receiptAssignments?costPositionId',
];

const HttpRequest = async (
  url = '',
  method = 'get',
  postdata = null,
  btn = null,
  loader = false,
  await_type = true,
  return_res = true,
  cb_object = null,
  cb_function_name = '',
  cb_function_data = null,
  alert_show = false,
  alert_message = '',
  header = {
    // "Content-Type": "application/jsonp; charset=utf-8",
    // "Accept": "application/jsonp, text/plain, */*",
  },
  returnTrueResponse= false,
  autoCloseKnownErrors= true,
  addRedirectToKnownErrors= ''
) => {

  var config = {
    method: method,
    url: url,
    headers: header,
  };

  if (btn != null) {
    btn.disabled = true;
  }

  if (postdata != null) {
    config.data = postdata;
  }

  // console.log(config);
  var statuscode = 404;
  var status = false;
  var message = 'Not Found';
  var result = [];

  if (await_type) {
    await axios(config)
      .then(function (response) {
        response = response.data;

        

        if (typeof response.message !== 'undefined') {
          message = response.message;
        }
        // temporary
        else if (typeof response.Message !== 'undefined') {
          message = response.Message;
        }

        if (typeof response.result !== 'undefined') {
          result = response.result;
        }
        // temporary
        else if (typeof response.Result !== 'undefined') {
          result = response.Result;
        }
        // else{

        //      //temporary
        // //  // ;
        //  if(url?.indexOf("signin") != -1){

        //      status = true;

        //      result = {data:"",user:null};
        //      if(typeof response.result !== 'undefined'){

        //          result.data = response.result;

        //      }
        //      if(typeof response.user !== 'undefined'){

        //          result.user = response.user;

        //      }

        //  }else{
        //     if(typeof response.result !== 'undefined'){

        //         result = response.result;

        //     }
        //  }

        // }

        if (typeof response.status !== 'undefined') {
          statuscode = 200;
          status = response.status;
        }
        // temporary
        else if (typeof response.Status !== 'undefined') {
          statuscode = 200;
          status = response.Status;
        }

        if (url?.indexOf('virtserver.swaggerhub.com/neoverv_berlin/NV-HH-API') != -1) {
          status = true;
          result = response;
          statuscode = 200;
          message = '';
          //      if(typeof response.result !== 'undefined'){

          //          result.data = response.result;

          //      }
          //      if(typeof response.user !== 'undefined'){

          //          result.user = response.user;

          //      }
        }

        if (alert_show) {
          alert(alert_message == '' ? alert_message : message);
        }

        if (btn != null) {
          btn.disabled = false;
        } 

        if(!response.status && response.code){
          let key = response.code.toString().toLowerCase();
          if(key !== 'central_warmwater_allocation_key_not_set' && key.length > 0 && lang.newKeys[key].props.children.length > 0) {
            showNotification({
              message: 
              <Group spacing={0}>
                <span>{lang.newKeys[key]}</span>
                {addRedirectToKnownErrors && <span>{addRedirectToKnownErrors}</span>}
              </Group>,
              icon: <img src={RedCross} alt='red-cross'/>,
              autoClose: autoCloseKnownErrors,
              id: 'knownErrors'
            })
          }
        }

        if (cb_object != null) {
          cb_object[cb_function_name]({
            statuscode: statuscode,
            status: status,
            message: message,
            result: result,
            other: cb_function_data,
          });
        }

        if (return_res) {
          return { statuscode: statuscode, status: status, message: message, result: result };
        }
      })
      .catch(function (error) {
        console.log('RequestAxios: ', error.response);
        const state = store.getState();
        if(error?.response?.status === 429){
          setTimeout(() => {
            HttpRequest(
              url,
              method,
              postdata,
              btn,
              loader,
              await_type,
              return_res,
              cb_object,
              cb_function_name,
              cb_function_data,
              alert_show,
              alert_message,
              header,
              returnTrueResponse,
              autoCloseKnownErrors,
              addRedirectToKnownErrors
            );
          }, 3000);
        }
        if (error.toString().includes('401')) {
          setTimeout(() => {
            localStorage.clear();
            localStorage.setItem('lastRoute', window.location.href.includes('localhost') ? window.location.href.split('http://localhost:3000')[1] : window.location.href.includes('dev.hellohousing.de') ? window.location.href.split('https://dev.hellohousing.de')[1] : window.location.href.split('https://app.hellohousing.de')[1]);
            window.location.reload();
          }, 500);
          window.Intercom('shutdown');
        }
        if(url?.includes('SignIn')){
          var metadata = {
            error: error.toString(),
            'end point name': url,
            response: error?.response,
            'request body': JSON.stringify(postdata),
            encryptedToken: state?.generalReducer?.profile?.auth?.encryptedToken
          };
          window.Intercom('trackEvent', 'log-in-failed', metadata);
        }else{
          var metadata = method === 'POST' || method === 'PUT' ? 
          !error.toString().includes('Error: Request aborted') &&
          {
            error: error.toString(),
            response: error?.response,
            'end point name': url,
            method: method,
            'request body': JSON.stringify(postdata),
            'time stamp': new Date(),
            url: window.location.href,
            encryptedToken: state?.generalReducer?.profile?.auth?.encryptedToken
          }
          :
          !error.toString().includes('Error: Request aborted') &&
          {
            error: error.toString(),
            response: error?.response,
            'end point name': url,
            method: method,
            'time stamp': new Date(),
            url: window.location.href,
            encryptedToken: state?.generalReducer?.profile?.auth?.encryptedToken
          };

          avoidErrorEndpointsList.find((endpoint)=> !url?.includes(endpoint) && !(error.toString().includes('400') || error.toString().includes('404')) && window.Intercom('trackEvent', 'endpoint-failed', metadata));
        }
        if (btn != null) {
          btn.disabled = false;
        }
        // Autoload.Redirection.redirection_error();

        if (cb_object != null) {
          if(returnTrueResponse){
            cb_object[cb_function_name]({
              statuscode: error?.response?.request?.status,
              status: error?.response?.data?.status,
              message: error?.response?.data?.message,
              result: error?.response?.data?.result === null ? result : error?.response?.data?.result,
              other: cb_function_data,
            });
          }else {
            cb_object[cb_function_name]({
              statuscode: error.toString().includes('409') ? 409 : statuscode,
              status: status,
              message: message,
              result: result,
              other: cb_function_data,
            });
          }
          // cb_object[cb_function_name]({
          //   statuscode: error.toString().includes('409') ? 409 : statuscode,
          //   status: status,
          //   message: message,
          //   result: result,
          //   other: cb_function_data,
          // });
        }

        if (return_res) {
          return { statuscode: error.response.request.status, status: error.response.data.status, message: error.response.data.message, result: error.response.data.result };
        }
      });
  } else {
    alert('error');
  }
};
const RequestAxios = {
  HttpRequest,
};

export default RequestAxios;
