import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import { Row, Col, Form, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import { Checkbox, Grid, Group, Stack, TextInput, Tooltip } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIcon from '../../../../../assets/images/module/general/TenantIcon.svg';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';
import RightArrowPartner from '../../../../../assets/images/module/general/RightArrowPartner.svg';
import { NumericFormat } from 'react-number-format';
import CardSelection from '../CardSelection/CardSelection';
import BankBlue from '../../../../../assets/images/module/general/Bank.svg';
import Bank from '../../../../../assets/images/module/general/BankGrey.svg';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Autoload from '../../../../Autoload';
import accounting from 'accounting';
import TruncatedTextHandler from '../../../../../components/TruncatedTextHandler';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

export class SelectAccountsForBankConnectionModal extends Component {
    state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    hover1: false,
    hover2: false,
    selectedType: null,
    accounts: [
        {
            id: 0,
            iban: 'xxxx-xxxx-xxxx-xxxx-xxxx',
            firstName: 'Jhon',
            lastName: 'Doe',
            accountBalance: 2000
        },
        {
            id: 1,
            iban: 'xxxx-xxxx-xxxx-xxxx-xxxx',
            firstName: 'Mr.X',
            lastName: 'XX',
            accountBalance: 2300
        },
        {
            id: 2,
            iban: 'xxxx-xxxx-xxxx-xxxx-xxxx',
            firstName: 'Mr.Y',
            lastName: 'YY',
            accountBalance: 200
        },
    ],
    selectedAccounts: []
    };
    
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        const {bankConnectionData, bankAccounts} = this.props;
        let {selectedAccounts} = this.state;

        selectedAccounts= bankAccounts.filter((account)=> bankConnectionData.bankAccounts.find((bankAccount)=> bankAccount.id === account.id) !== undefined);
        this.setState({selectedAccounts});
    }

    handleMouseEnter=(type)=>{
        this.setState({[type]: true});
    }

    handleMouseLeave=(type)=>{
        this.setState({[type]: false});
    }

    renderTableHead=()=>{
        const {bankAccounts} = this.props;
        const {selectedAccounts, accounts} = this.state;
        return(
        <TableHead>
            <TableRow>
                <TableCell className='banks-table-header' style={{maxWidth: '30px', paddingRight: 0}}>
                    <Checkbox
                    checked={bankAccounts.length === selectedAccounts.length}
                    indeterminate={selectedAccounts.length > 0 && bankAccounts.length !== selectedAccounts.length}
                    classNames={{input: 'tenants-list-checkbox-rental-income-input', inner: 'tenants-list-checkbox-rental-income-inner'}}
                    styles={{ 
                        input: {
                            cursor: 'pointer'
                        }
                    }}
                    onChange={()=> bankAccounts.length === selectedAccounts.length ? this.setState({selectedAccounts: []}) : this.setState({selectedAccounts: bankAccounts})}
                    />
                </TableCell>
                <TableCell className='banks-table-header'>Kontoinhaber</TableCell>
                <TableCell className='banks-table-header'>IBAN</TableCell>
                <TableCell className='banks-table-header'>Art</TableCell>
                <TableCell className='banks-table-header' style={{textAlign: 'right'}}>Saldo</TableCell>
            </TableRow>
        </TableHead>
        )
    }

    getBankType=(type)=>{
        switch (type) {
          case 'Checking':
            return 'Girokonto';
          case 'Savings':
            return 'Sparkonto';
          case 'CreditCard':
            return 'Kreditkarte';
          case 'Security':
            return 'Bürgschaft';
          case 'Loan':
            return 'Darlehen';
          case 'Membership':
            return 'Mitgliedschaft';
          case 'Bausparen':
            return 'Bausparkonto';
          default:
            return type;
        }
      }

    renderTableBody=()=>{
        const {bankAccounts, currentSubscription, buyPremiumModalOpen, bankConnectionData} = this.props;
        const {accounts, selectedAccounts} = this.state;
        return(
            <TableBody>
                {bankAccounts
                .map((account)=>{
                let disableRow= bankConnectionData.bankAccounts.find((bankAccount)=> bankAccount.id === account.id) !== undefined;
                return (<TableRow 
                    className={`bank-account-cards ${disableRow ? 'banks-table-row-disabled' : 'banks-table-row'} ${selectedAccounts.find((selectedAccount)=> selectedAccount.id === account.id) !== undefined && 'banks-table-row-selected'}`} 
                    onClick={()=>{
                        if(disableRow === false){
                            if(selectedAccounts.find((selectedAccount)=> selectedAccount.id === account.id) !== undefined){
                                this.setState({selectedAccounts: selectedAccounts.filter((selectedAccount)=> selectedAccount.id !== account.id)});
                            }else if(currentSubscription?.subscriptionName === 'Premium' && selectedAccounts.length > 0){
                                buyPremiumModalOpen();
                            }else{
                                this.setState({selectedAccounts: [...selectedAccounts, account]});
                            }
                        }
                    }}>
                        
                        <TableCell className='banks-table-cell' style={{maxWidth: '30px', paddingRight: 0, display: 'flex'}}>
                            <Tooltip styles={{root: {width: '100%'}}} disabled={disableRow === false} zIndex={99999} label={'Dieses Konto wurde bereits in hellohousing hinzugefügt.'}>
                                <Checkbox
                                checked={selectedAccounts.find((selectedAccount)=> selectedAccount.id === account.id) !== undefined}
                                classNames={{input: 'tenants-list-checkbox-rental-income-input', inner: 'tenants-list-checkbox-rental-income-inner'}}
                                styles={{ 
                                    input: {
                                        cursor: 'pointer'
                                    }
                                }}
                                disabled={disableRow}
                                onChange={()=> selectedAccounts.find((selectedAccount)=> selectedAccount.id === account.id) !== undefined ? this.setState({selectedAccounts: selectedAccounts.filter((selectedAccount)=> selectedAccount.id !== account.id)}) : this.setState({selectedAccounts: [...selectedAccounts, account]})}
                                />
                            </Tooltip>
                        </TableCell>
                        <TableCell className='banks-table-cell'>
                            <Tooltip styles={{root: {width: '100%'}}} disabled={disableRow === false} zIndex={99999} label={'Dieses Konto wurde bereits in hellohousing hinzugefügt.'}>
                                {account.accountHolderName}
                            </Tooltip>
                        </TableCell>
                        <TableCell className='font-weight-bold'>
                            <Tooltip styles={{root: {width: '100%'}}} disabled={disableRow === false} zIndex={99999} label={'Dieses Konto wurde bereits in hellohousing hinzugefügt.'}>
                                {account.iban}
                            </Tooltip>
                        </TableCell>
                        <TableCell style={{fontWeight: '400'}}>
                            <Tooltip styles={{root: {width: '100%'}}} disabled={disableRow === false} zIndex={99999} label={'Dieses Konto wurde bereits in hellohousing hinzugefügt.'}>
                                {this.getBankType(account.accountType)}
                            </Tooltip>
                        </TableCell>
                        <TableCell style={{textAlign: 'right', fontWeight: '400', whiteSpace: 'nowrap'}}>
                            <Tooltip styles={{root: {width: '100%'}}} disabled={disableRow === false} zIndex={99999} label={'Dieses Konto wurde bereits in hellohousing hinzugefügt.'}>
                                {accounting.formatMoney(account.balance, '€', 2, '.', ',', '%v %s')}
                            </Tooltip>
                        </TableCell>
                    </TableRow>);
                })}
            </TableBody>
        )
    }

    postBankAccountProperties=()=>{
        const {selectedAccounts} = this.state;
        const {bankConnectionData} = this.props;
        const filteredSelectedAccounts = selectedAccounts.filter((selectedAccount)=> bankConnectionData.bankAccounts.find((bankAccount)=> bankAccount.id === selectedAccount.id) === undefined);
        
        filteredSelectedAccounts.map((selectedAccount, index)=> 
        Autoload.HttpRequestAxios.HttpRequest(
            Autoload.Setting.finance_api_base_url + 'bankAccountProperties',
            'POST',
            {
                bankAccountId: selectedAccount.id,
                propertyId: this.props.propertySelected.id
            },
            null,
            false,
            true,
            false,
            this,
            'handlePostBankAccountProperties',
            {eol: filteredSelectedAccounts.length === (index + 1)},
            false,
            '',
            {
            // 'Content-Type': 'application/json',
            Authorization: Autoload.Utils.getAuthToken(),
            }
        ));
    }

    handlePostBankAccountProperties(response){
        const {selectedAccounts} = this.state;
        if(response.other.eol){
            if(response.status && response.statuscode === 200){
                showNotification({
                    message: selectedAccounts.length > 1 ? 'Bankkonten erfolgreich hinzugefügt.' : 'Bankkonto erfolgreich hinzugefügt.',
                    icon: <img src={GreenTick} alt='green-tick'/>,
                    id: 'bankAccountsAdded'
                });
            }
            this.props.getBankAccountsByPropertyId();
        }

        if(response.statuscode !== 200){
            showNotification({
              message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
              icon: <img src={RedCross} alt='red-cross'/>,
              id: 'PostBankAccountPropertiesError'
            });
        }
    }
    
    render() {
    const { open, toggle, className, propertySelected, bankAccounts, openAddBankModal, updateBankConnectionImportNewAccounts, bankConnectionData } = this.props;
    const { selectedAccounts, isMobile } = this.state;

    console.log('bankAccounts:  ', bankAccounts);
    return (
        <CustomModal
        heading={'Konten auswählen'}
        headingClassName={'update-received-rent-modal-heading'}
        open={open}
        toggle={() => {
            toggle();
        }}
        className={`${className}`}
        style={{minWidth: isMobile ? 'auto' : '750px'}}
        // style={{maxWidth: '450px'}}
        >
        <Stack>
            <Stack>
                {bankAccounts.length > 0 &&
                <Group>
                    <span style={{fontFamily: 'Inter, sans-serif', fontWeight: '300'}}>Bitte wählen Sie die Bankkonten, die Sie zur Immobilie <span style={{fontWeight: 'bold'}}>{propertySelected?.name}</span> hinzufügen wollen.</span>
                </Group>}
                {bankAccounts.length > 0 ?
                <Stack spacing={0}>
                    <TableContainer style={{maxHeight: '380px'}}>
                        <Table style={{borderCollapse: 'separate', borderSpacing: '0px 8px'}}>
                            {this.renderTableHead()}
                            {this.renderTableBody()}
                        </Table>
                    </TableContainer>
                    <Group noWrap spacing={0}>
                        <Group noWrap style={{fontSize: '12px', fontWeight: '400', color: '#5555FF', cursor: 'pointer', margin: '5px 0px'}} spacing={5} onClick={()=> updateBankConnectionImportNewAccounts()}>Weitere Konten dieser Bankverbindung für die Verwendung in hellohousing freigeben. <img src={RightArrowPartner} style={{marginTop: '2px', width: '12px'}}/></Group>
                    </Group>
                </Stack>
                :
                <Group position='center' align='center'>
                    <EmptyStateContainer
                    heading={'Keine Bankkonten gefunden.'}
                    subheading={'Erstellen Sie eine neue Bankverbindung, um weitere Konten hinzuzufügen.'}
                    showBtn={true}
                    btnText={'Neue Bankverbindung erstellen'}
                    btnHandler={()=> openAddBankModal()}
                    icon={Bank}
                    styleMainContainer={{margin: '20px 0px'}}
                    rootStyle={{width: '100%'}}
                    />
                </Group>}
            </Stack>
            <Group position='apart'>
                <div>
                    <ColorButton
                    text={'Abbrechen'}
                    className='gray-btn'
                    onClick={()=> toggle()}
                    />
                </div>
                <div>
                    <ColorButton
                    text={'Hinzufügen'}
                    className='purple-btn'
                    onClick={()=> this.postBankAccountProperties()}
                    disabled={selectedAccounts.filter((selectedAccount)=> bankConnectionData.bankAccounts.find((bankAccount)=> bankAccount.id === selectedAccount.id) === undefined).length === 0}
                    />
                </div>
            </Group>
        </Stack>
        </CustomModal>
    );
    }
}

export default SelectAccountsForBankConnectionModal;
