import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import MeterIcon from '../../../../../assets/images/module/general/blueEmptyMeter.svg';
import { Checkbox, Group, Loader, Stack, TextInput } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';
import { NumericFormat } from 'react-number-format';
import Autoload from '../../../../Autoload';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import dayjs from 'dayjs';
import accounting from 'accounting';
import MeterReadingsForMeterLineChart from '../MeterCharts/MeterReadingsForMeterLineChart';

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

export class ViewMeterReadingsForMeterModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    meterReadings: []
  };
  
  constructor(props) {
    super(props);
    dayjs.extend(utc);
    dayjs.extend(timezone);
  }

  componentDidMount(){
    this.getMeterReadingsForMeter();
  }

  getMeterReadingsForMeter=()=>{
    const {selectedMeter} = this.props;
    this.setState({meterReadingLoader: true});
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.meter_api_base_url + 'meterReadings/forMeter?meterId=' + selectedMeter.id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetMeterReadingsForMeter',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleGetMeterReadingsForMeter(response){
    console.log('handleGetMeterReadingsForMeter: ', response);
    if(response.status && response.statuscode === 200){
      this.setState({meterReadings: response.result.filter((meterReading)=> meterReading.currentValue !== null)
        .filter((meterReading)=> (dayjs(meterReading.meterReadingDate).isBefore(dayjs()) || dayjs(meterReading.meterReadingDate).isSame(dayjs())))
        .sort((a, b) => {
        const dateA = new Date(a.meterReadingDate);
        const dateB = new Date(b.meterReadingDate);
        return dateB - dateA;
      }), meterReadingLoader: false});
    }
  }

  renderTableHeader=()=> {
    return(
      <TableHead>
        <TableRow>
          {/* <TableCell className='meter-logs-detail-meter-reading-table-header-cell'>Zählernummer</TableCell> */}
          <TableCell className='meter-logs-detail-meter-reading-table-header-cell'>Ablesezeitpunkt</TableCell>
          <TableCell className='meter-logs-detail-meter-reading-table-header-cell' style={{textAlign: 'right'}}>Wert</TableCell>
          <TableCell className='meter-logs-detail-meter-reading-table-header-cell' width={'5%'}>Einheit</TableCell>
        </TableRow>
      </TableHead>
    )
  }

  renderTableBody=()=>{
    const {meterReadings} = this.state;
    console.log('meterReadings: ', meterReadings);
    return(
      <TableBody>
        {meterReadings.length > 0 ?
        meterReadings.sort((a,b)=> new Date(b.meterReadingDate) - new Date(a.meterReadingDate)).map((meterReading)=>
        <TableRow key={meterReading?.id} className='meter-logs-detail-meter-reading-table-body-row'>
          {/* <TableCell className='meter-logs-detail-meter-reading-table-body-cell'>
            {meterReading?.meter?.meterNo ? meterReading?.meter?.meterNo : '-'}
          </TableCell> */}
          <TableCell className='meter-logs-detail-meter-reading-table-body-cell'>
            {dayjs.utc(meterReading?.meterReadingDate).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('DD.MM.YYYY (HH:mm)')}
          </TableCell>
          <TableCell style={{textAlign: 'right'}} className='meter-logs-detail-meter-reading-table-body-cell'>
            {(meterReading?.currentValue !== null || meterReading?.currentValue !== undefined) ? accounting.formatNumber(meterReading?.currentValue, 2, '.', ',') : '-'}
          </TableCell>
          <TableCell className='meter-logs-detail-meter-reading-table-body-cell'>
            {meterReading?.meter?.allocationKey?.units ? meterReading?.meter?.allocationKey?.units : '-'}
          </TableCell>
        </TableRow>)
        :
        <TableRow>
          <TableCell colSpan={4}>
            <EmptyStateContainer
            heading={'Keine Zählerwerte vorhanden.'}
            subheading={'Führen Sie Ablesungen durch, um Zählerwerte zu erfassen.'}
            icon={MeterIcon}
            showBtn={true}
            btnText={'Ablesung durchführen'}
            btnHandler={()=> this.props.history.push('/meter/reading')}
            styleMainContainer={{margin: '30px 0px'}}
            />
          </TableCell>
        </TableRow>}
      </TableBody>
    )
  }
  
  render() {
    const { open, toggle, className, selectedMeter } = this.props;
    const { meterReadingLoader, meterReadings, isMobile } = this.state;
    console.log('selectedMeter: ', selectedMeter);
    return (
      <CustomModal
        heading={`Zählerwerte`}
        headingClassName={'update-received-rent-modal-heading'}
        open={open}
        toggle={() => {
          toggle();
        }}
        className={`${className}`}
        style={{minWidth: !isMobile && '700px'}}
      >
        <Stack>
            {meterReadings.length > 0 && 
            <Group style={{width: '100%', marginBottom: '20px'}}>
              <MeterReadingsForMeterLineChart meterReadings={meterReadings}/>
            </Group>}
            <Group>
              {meterReadingLoader ? 
              <Group style={{width: '100%', height: '350px'}} position='center' align={'center'}>
                <Loader/>
              </Group>
              :
              <Stack style={{width: '100%'}}>
                <Group><span className='add-allocation-sub-headings'>Zählerablesungen</span></Group>
                <TableContainer style={{maxHeight: '350px'}}>
                  <Table stickyHeader>
                    {meterReadings.length > 0 && this.renderTableHeader()}
                    {this.renderTableBody()}
                  </Table>
                </TableContainer>
              </Stack>}
            </Group>
            <Group position='right'>
                <div>
                    <ColorButton
                    text={'Schließen'}
                    className='gray-btn'
                    onClick={()=> toggle()}
                    />
                </div>
            </Group>
        </Stack>
      </CustomModal>
    );
  }
}

export default ViewMeterReadingsForMeterModal;
