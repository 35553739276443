import React, { Component } from 'react';
import { Checkbox, Group, Stack, TextInput } from '@mantine/core';
import 'chartjs-plugin-style';
import { Chart as ChartJS, LinearScale, RadialLinearScale, BarElement, BarController, CategoryScale, Decimation, Filler, Legend, Title, Tooltip, LineElement, PointElement, LineController } from "chart.js";
import { Bar, Chart, Line } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import accounting from 'accounting';
import dayjs from 'dayjs';

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

export class MeterReadingsForMeterLineChart extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    chartData: null,
    chartOptions: null,
    formatedDates: []
  };
  
  constructor(props) {
    super(props);
    ChartJS.register(LinearScale, RadialLinearScale, BarElement, BarController, CategoryScale, LineElement, PointElement, LineController, Decimation, Filler, Legend, Title, Tooltip);
    dayjs.extend(utc);
    dayjs.extend(timezone);
  }
  
  getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');
  
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
      tooltipEl.style.borderRadius = '3px';
      tooltipEl.style.color = 'white';
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(-50%, 0)';
      tooltipEl.style.transition = 'all .1s ease';
  
      const table = document.createElement('table');
      table.style.margin = '0px';
  
      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }
  
    return tooltipEl;
  };

  externalTooltipHandler = (context, unit) => {
    // Tooltip Element
    const {formatedDates} = this.state;
    const {chart, tooltip, dataIndex} = context;
    const tooltipEl = this.getOrCreateTooltip(chart);

    console.log('externalTooltipHandler: ', tooltip.dataPoints[0]?.dataIndex);

    console.log('externalTooltipHandlerData: ', tooltip.dataPoints[0]?.raw);
    const value = tooltip.dataPoints[0]?.raw?.y;

    tooltipEl.style.marginTop = '10px'
    tooltipEl.style.backgroundColor = 'white';
    tooltipEl.style.color = '#11263C'
    tooltipEl.style.boxShadow = '0 3px 3px rgba(0,0,0,.2)'
    
    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }
  
    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map(b => b.lines);

      console.log('bodyLines: ', dataIndex);
  
      const tableHead = document.createElement('thead');
  
      const tableBody = document.createElement('tbody');
  
        const tr = document.createElement('tr');
        tr.style.backgroundColor = 'inherit';
        tr.style.borderWidth = 0;
  
        const td = document.createElement('td');
        td.style.borderWidth = 0;

        const div = document.createElement('div');
        div.style.display= 'flex';
        div.style.flexDirection= 'column';
  
        const text = 'Wert';
        const span1 = document.createElement('span');
        span1.style.color= '#5041BC';
        span1.style.fontSize= '9px';
        span1.append(text);


        console.log('length: ', bodyLines.toString().split(': '));
        const span2 = document.createElement('span');
        span2.style.color= '#11263C';
        span2.style.fontSize= '16px';
        span2.style.fontWeight= 'bold';
        span2.append(value ? (`${accounting.formatNumber(value, 2, '.', ',')} ${unit}`) : '-');
        

        const span3 = document.createElement('span');
        span3.style.color= 'rgb(60, 60, 67, 0.6)';
        span3.style.fontSize= '10px';
        span3.append(formatedDates[tooltip.dataPoints[0]?.dataIndex]); //tooltip.title

        div.append(span1);
        div.append(span2);
        div.append(span3);
        td.appendChild(div);
        tr.appendChild(td);
        tableBody.appendChild(tr);
    
  
      const tableRoot = tooltipEl.querySelector('table');
  
      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }
  
      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }
  
    const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
  
    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.borderRadius='10px';
    tooltipEl.style.whiteSpace = 'nowrap';
    tooltipEl.style.left = this.props.isMobile ? '320px' : positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = this.props.isMobile ? '0px' : positionY + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
    tooltipEl.style.marginTop = !this.props.isMobile && (context.tooltip.caretY <= 40 ? '-90px' : '-110px');
    tooltipEl.style.marginLeft = !this.props.isMobile && (context.chart.width - context.tooltip.caretX <= 100 ? '-100px' : '0px');
    tooltipEl.style.transition = 'all 0.5s ease 0s';
    tooltipEl.style.zIndex = 9999999;
  };

  componentDidMount(){
    const {meterReadings} = this.props;
    const chartData = this.prepareChartData(meterReadings);
    const chartOptions= {
        //locale: 'de',
        bezierCurve: false,
        responsive: true,
        layout: {
            padding: {
              top: 40, // Add 20px padding at the top
            },
        },
        plugins: {
            legend: {
              display: false, // Hide the legend
            },
            tooltip: {
                enabled: false,
                position: 'nearest',
                external: (context)=>  this.externalTooltipHandler(context, meterReadings[0]?.meter?.allocationKey?.units)
            }
        },
        scales: {
            x: {
                ticks: {
                  display: true, // Ensure labels (ticks) are displayed
                  maxTicksLimit: 5,
                  font: {
                    size: 12, // Customize font size for better visibility
                  },
                },
                grid: {
                  drawOnChartArea: false, // Disable grid lines inside the chart
                  drawTicks: true, // Ensure tick marks are still drawn
                },
            },
            y: {
                ticks: {
                    maxTicksLimit: 10, // Limit y-axis to 5 ticks
                }
            },
        },
      };
    this.setState({chartData, chartOptions});
  }

  prepareChartData = (data) => {
    // Extract raw data for each month
    console.log('prepareChartData: ', data);
    const monthData = data.sort((a, b) => new Date(a.meterReadingDate) - new Date(b.meterReadingDate)).map(entry => {
        const date = new Date(entry.meterReadingDate);
        const formatedDate = dayjs.utc(entry?.meterReadingDate).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('DD.MM.YYYY (HH:mm)')
        const monthLabel = `${date.toLocaleString("de-DE", { month: "short" , year: 'numeric'})}`;//`${date.toLocaleString("de-DE", { month: "short" })} ${date.getFullYear()}`;
        return { monthLabel, value: entry.currentValue || 0, formatedDate };
    });

    // Sort months chronologically by their labels
    const sortedMonths = [...new Set(monthData.map(entry => entry.monthLabel))]

    const formatedDates = monthData.map(entry => entry.formatedDate);

    this.setState({formatedDates});
    // [...new Set(monthData.map(entry => entry.monthLabel))]
    //     .sort((a, b) => new Date(a) - new Date(b));

    let width, height, gradient;

    function getGradient(ctx, chartArea) {
        const chartWidth = chartArea.right - chartArea.left;
        const chartHeight = chartArea.bottom - chartArea.top;
        if (!gradient || width !== chartWidth || height !== chartHeight) {
            // Create the gradient because this is either the first render
            // or the size of the chart has changed
            width = chartWidth;
            height = chartHeight;
            gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
            gradient.addColorStop(0, '#E3E3FC');
            gradient.addColorStop(0.5, '#5555FF');
            gradient.addColorStop(1, '#422F8A');
        }

        return gradient;
    }

    return {
        labels: sortedMonths,
        datasets: [
            {
                label: "Wert",
                data: monthData.map(entry => ({
                    x: entry.monthLabel,
                    y: entry.value,
                })),
                pointHoverRadius: 6,
                pointHoverBackgroundColor: 'rgb(66, 47, 138, 0.87)',
                pointHoverBorderColor: '#ffffff',
                pointHoverBorderWidth: 2,
                pointBackgroundColor: 'rgb(66, 47, 138, 0.87)',
                pointBorderColor: '#ffffff',
                pointRadius: 4,
                pointBorderWidth: 2,
                fill: true,
                backgroundColor: function(context) {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 600);

                    gradient.addColorStop(0, 'rgb(116, 89, 217, 0.1)');
                    gradient.addColorStop(1, 'rgb(255, 255, 255, 0)');

                    return gradient;
                },
                borderColor: function(context) {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;

                    if (!chartArea) {
                        // This case happens on initial chart load
                        return;
                    }
                    return getGradient(ctx, chartArea);
                },
                tension: 0,
            },
        ],
    };
};


//   prepareChartData=(data)=> {
//     const monthData = {};
  
//     // Process each entry
//     data.forEach(entry => {
//         const date = new Date(entry.meterReadingDate);
//         const monthLabel = `${date.toLocaleString("de-DE", { month: "short" })} ${date.getFullYear()}`;
      
//         // Aggregate data by month
//         if (!monthData[monthLabel]) {
//             monthData[monthLabel] = 0;
//         }
//         monthData[monthLabel] += entry.currentValue || 0;
//     });
  
//     // Sort months chronologically
//     const sortedMonths = Object.keys(monthData).sort((a, b) => new Date(b) - new Date(a));
  
//     let width, height, gradient;
//     function getGradient(ctx, chartArea) {
//     const chartWidth = chartArea.right - chartArea.left;
//     const chartHeight = chartArea.bottom - chartArea.top;
//     if (!gradient || width !== chartWidth || height !== chartHeight) {
//         // Create the gradient because this is either the first render
//         // or the size of the chart has changed
//         width = chartWidth;
//         height = chartHeight;
//         gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
//         gradient.addColorStop(0, '#E3E3FC');
//         gradient.addColorStop(0.5, '#5555FF');
//         gradient.addColorStop(1, '#422F8A');
//     }

//     return gradient;
//     }
//     return {
//       labels: sortedMonths,
//       datasets: [
//         {
//             label: "Wert",
//             data: sortedMonths.map(month => monthData[month]),
//             pointHoverRadius: 6,
//             pointHoverBackgroundColor: 'rgb(66, 47, 138, 0.87)',
//             pointHoverBorderColor: '#ffffff',
//             pointHoverBorderWidth: 2,
//             pointBackgroundColor: 'rgb(66, 47, 138, 0.87)',
//             pointBorderColor: '#ffffff',
//             pointRadius: 4,
//             pointBorderWidth: 2,
//             fill: true,
//             backgroundColor: function(context) {
//                 const chart = context.chart;
//                 const {ctx, chartArea} = chart;
//                 var gradient = ctx.createLinearGradient(0, 0, 0, 600);
                
//                 gradient.addColorStop(0, 'rgb(116, 89, 217, 0.1)');   
//                 gradient.addColorStop(1, 'rgb(255, 255, 255, 0)');
                

//                 return gradient;
//             },
//             //borderColor: 'rgb(116, 89, 217, 0.1)',
//             borderColor: function(context) {
//                 const chart = context.chart;
//                 const {ctx, chartArea} = chart;
        
//                 if (!chartArea) {
//                 // This case happens on initial chart load
//                 return;
//                 }
//                 return getGradient(ctx, chartArea);
//             },
//             tension: 0.4,
//             // segment: {
//             //     backgroundColor: ctx => sectionCurrentYear(ctx, props.highConsumption ? 'rgb(255, 203, 203, 0.5)' : 'rgb(232, 244, 234, 0.65)', segmentEndMonth, segmentStartMonth)
//             // },
//         },
//       ],
//     };
//   }
  
  render() {
    const {chartData, chartOptions} = this.state;
    const {meterReadings} = this.props;
    
    const unitPlugin = {
        id: "unitPlugin",
        afterDraw(chart) {
          const { ctx, scales } = chart;
          const yScale = scales.y; // Access the Y-axis scale
      
          if (!yScale) return; // Ensure the Y-axis exists
      
          // Get the position at the top-right of the Y-axis
          const xPosition = yScale.right - 11; // Slightly to the right of the axis
          const yPosition = yScale.top - 25; // Align with the top of the Y-axis
      
          // Draw the unit text
          ctx.save();
          ctx.font = "300 12px Arial"; // Set font style
          ctx.fillStyle = "rgb(19, 19, 92)"; // Set text color
          ctx.textAlign = "left"; // Align text to the left
          ctx.fillText(meterReadings[0]?.meter?.allocationKey?.units, xPosition, yPosition); // Customize unit text
          ctx.restore();
        },
    };      
    return (
      <Stack style={{width: '100%'}}>
        {chartData &&
        (meterReadings[0]?.meter?.allocationKey?.units ?
        <Line
        id='meter-readings-for-meter-chart' 
        plugins={[unitPlugin]}
        height={170}
        data={chartData} options={chartOptions}/>
        :
        <Line
        id='meter-readings-for-meter-chart' 
        height={170}
        data={chartData} options={chartOptions}/>)}
      </Stack>
    );
  }
}

export default MeterReadingsForMeterLineChart;
