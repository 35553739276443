import React, { Component } from 'react';
import CustomModal from './index';
import { Row, Col, Form } from 'react-bootstrap';
import { AddUnit, EditUnit } from '../../../../../redux/action/Tenant/TenantActions';
import { setTenantFiles, setAddTenantDocument } from '../../../../../redux/action/General/generalActions';
import { connect } from 'react-redux';
import Autoload from '../../../../Autoload';
import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import calendar from '../../../../../assets/images/module/general/CalendarIcon.svg';
import TenantSingleIcon from '../../../../../assets/images/module/general/TenantSingleIcon.svg';
import TenantSingleIconBlue from '../../../../../assets/images/module/general/TenantSingleIconBlue.svg';
import buildingIcon from '../../../../../assets/images/module/general/building_icon.svg';
import buildingIconBlue from '../../../../../assets/images/module/general/building_icon_blue.svg';
import SelfIcon from '../../../../../assets/images/module/general/SelfIcon.svg';
import SelfIconBlue from '../../../../../assets/images/module/general/SelfIconBlue.svg';
import GoldenLockIcon from '../../../../../assets/images/module/general/GoldenLockIcon.svg';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import { DropFile, ValueText, ColorButton } from '../../../../index';
import moment from 'moment';
import CardSelection from '../CardSelection/CardSelection';
import { Group, Popover, Stack } from '@mantine/core';
import { showNotification } from '@mantine/notifications';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const { invalidEmail, emptyFieldValidation, invalidPhone, invalidSize, invalidAlphaNumeric } = Autoload.MainHelper;

class AddTenantModal extends Component {
  initialState = {
    setDate: '',
    moveoutSetDate: '',
    type: 'private',
    fullname: {
      value: '',
      err: false,
      msg: '',
    },
    num: {
      value: '',
      err: false,
      msg: '',
    },
    email: {
      value: '',
      err: false,
      msg: '',
    },
    moveIn: {
      value: '',
      isSelected: false,
      err: false,
      msg: 'Ungültiges Einzugsdatum',
    },
    moveOut: {
      value: '',
      isSelected: false,
      // err: false,
      // msg: '',
    },
    net: {
      value: Autoload.MainHelper.ConverterCurrencyDe(0 + '', true, 'de'),
      err: false,
      msg: '',
    },
    advance: {
      value: Autoload.MainHelper.ConverterCurrencyDe(0 + '', true, 'de'),
      err: false,
      msg: '',
    },
    selectedFile: null,
    selectedFileErr: { state: false, msg: 'No file selected' },
    fileArray: [],
    notes: '',
    hover1: false,
    hover2: false,
    hover3: false,
    hoverOnStarter: false,
    error: []
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }
  componentDidMount() {
    console.log(this.props.rentalUnits, 'rental units', this.props.rentalId, 'component did mount');
    let filterArr = this.props.rentalUnits.filter((item) => item.id === this.props.rentalId);
    // console.log(filterArr[0].tenantsData, 'filterArr.tenantsData;');
    let arr = filterArr[0].tenantsData;
    console.log(arr, 'filterArr on add ');
    var abc = arr.filter((item) => item.vacant === false);
    let setDate = this.props.emptyTenantEdit ?
    this.props.emptyTenantGapsData.moveIn && new Date(moment(this.props.emptyTenantGapsData.moveIn, 'DD.MM.YYYY').format('YYYY-MM-DDTHH:mm:ss'))
    :
      arr.length === 1 && arr[0].vacant === true
        ? null
        : abc.length > 0
        ? abc[0].out === ''
          ? moment(abc[0].in, 'DD.MM.YYYY').add(1, 'days').format('YYYY-MM-DDTHH:mm:ss')
          : moment(abc[0].out, 'DD.MM.YYYY').add(1, 'days').format('YYYY-MM-DDTHH:mm:ss')
        : new Date();
    let moveoutSetDate = moment(setDate, 'YYYY-MM-DDTHH:mm:ss').add(1, 'days').format('YYYY-MM-DDTHH:mm:ss');
    // console.log(abc, setDate, moveoutSetDate, 'abc abc abc');
    this.setState({
      setDate: setDate,
      moveoutSetDate: moveoutSetDate,
    });
    (this.props.emptyTenantGapsData.moveIn || this.props.emptyTenantGapsData.moveOut) && 
    this.setState({
      moveIn: {...this.state.moveIn, value: this.props.emptyTenantGapsData.moveIn && new Date(moment(this.props.emptyTenantGapsData.moveIn, 'DD.MM.YYYY').format('YYYY-MM-DDTHH:mm:ss')), isSelected: this.props.emptyTenantGapsData.moveIn && true},
      moveOut: {...this.state.moveOut, value: this.props.emptyTenantGapsData.moveOut && new Date(moment(this.props.emptyTenantGapsData.moveOut, 'DD.MM.YYYY').format('YYYY-MM-DDTHH:mm:ss')), isSelected: this.props.emptyTenantGapsData.moveOut && true},
    })
  }

  resetForm = () => {
    this.setState({ ...this.initialState });
  };

  onInputChange = (field, value) => {
    this.setState({ [field]: { ...this.state[field], value: value, err: false } });
  };

  onDirectFieldChange = (field, value) => {
    this.setState({ [field]: value });
  };

  onDateChange = (field, date) => {
    this.setState({ [field]: { ...this.state[field], value: date, isSelected: true, err: false } });
  };

  // handleFileChange = (acceptedFile, rejectedFile) => {
  //   if (acceptedFile.length > 0) {
  //     this.setState({ fileArray: [...this.state.fileArray, acceptedFile[0]] }, () => {
  //       // console.log('filess', acceptedFile, rejectedFile, this.state.fileArray, this.state.fileArray.length)
  //     });
  //   }
  //   if (rejectedFile.length > 0) {
  //     rejectedFile[0].errors.forEach((err) => {
  //       if (err.code === 'file-too-large') {
  //         this.setState({ selectedFileErr: { state: true, msg: lang.general.text_err_file_size } });
  //       }
  //       if (err.code === 'file-invalid-type') {
  //         this.setState({ selectedFileErr: { state: true, msg: lang.general.text_err_file_type } });
  //       }
  //     });
  //   } else {
  //     this.setState({ selectedFileErr: { state: false } });
  //   }
  // };

  handleFileChange = (acceptedFile, rejectedFile) => {
    // console.log(acceptedFile)
    if (acceptedFile.length > 0) {
      let files = [...this.state.fileArray, acceptedFile[0]];
      this.setState({ fileArray: files });
    }
    // if (rejectedFile[0]) {
    //   rejectedFile[0].errors.forEach((err) => {
    //     if (err.code === 'file-too-large') {
    //       this.setState({ selectedFileErr: { state: true, msg: 'File is larger than 5mb' } });
    //     }
    //     if (err.code === 'file-invalid-type') {
    //       this.setState({ selectedFileErr: { state: true, msg: 'File must be jpg or pdf' } });
    //     }
    //   });
    // }
    if (rejectedFile.length > 0) {
      console.log('rejectedFile: ', rejectedFile);
      rejectedFile.map((file, index)=>
      file.errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          this.setState({error: [...this.state.error, {fileName: file.file.name, message: 'Datei zu groß'}]}, ()=> 
          {
            if(rejectedFile.length === (index+1)){
              showNotification({
                message: 
                <Stack spacing={2}>
                  <Group>Es ist ein Fehler aufgetreten. Das Dokument ist zu groß. Maximal erlaubte Dokumentgröße ist 20 MB.</Group>
                  {this.state.error.map((err)=> 
                    <Group noWrap spacing={2}>
                      <span style={{
                        // maxWidth: '290px', 
                        // overflowX: 'hidden', 
                        // textOverflow: 'ellipsis', 
                        // whiteSpace: 'nowrap'
                        }}>
                          {`Dateiname: ${err.fileName}`}
                      </span>
                      {/* <span>{'->'}</span>
                      <span style={{whiteSpace: 'nowrap', color: '#db1738'}}>{`${err.message}`}</span> */}
                    </Group>)}
                </Stack>,
                icon: <img src={RedCross} alt='red-cross'/>,
                id: 'fileSelectionError',
                autoClose: false
              });
              this.setState({error: []});
            }
          })
        }
        if (err.code === 'file-invalid-type') {
          this.setState({error: [...this.state.error, {fileName: file.file.name, message: 'Dateityp ungültig'}]}, ()=> 
          {
            if(rejectedFile.length === (index+1)){
              showNotification({
                message: 
                <Stack spacing={2}>
                  {this.state.error.map((err)=> <Group noWrap spacing={2}><span style={{maxWidth: '290px', overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{`Dateiname: ${err.fileName}`}</span><span>{'->'}</span><span style={{whiteSpace: 'nowrap', color: '#db1738'}}>{`${err.message}`}</span></Group>)}
                </Stack>,
                icon: <img src={RedCross} alt='red-cross'/>,
                id: 'fileSelectionError',
                autoClose: false
              });
              this.setState({error: []});
            }
          });
        }
      }));
      // let {files} = this.state;
      // files.filter()
    } else {
      this.setState({ selectedFileErr: { state: false } });
    }
  };

  removeFile = () => {
    this.setState({ fileArray: [] }, () => console.log('filtered', this.state.fileArray));
  };

  validateData = (data, field, fieldName, fieldType) => {
    let invalidErr, emptyErr;
    if (fieldType == 'amount') {
      emptyErr = emptyFieldValidation(data.value.replaceAll('€', '').trim());
    } else {
      emptyErr = emptyFieldValidation(data.value);
    }
    switch (fieldType) {
      case 'email':
        invalidErr = invalidEmail(data.value);
        break;
      case 'name':
        invalidErr = invalidAlphaNumeric(data.value.trim());
        break;
      case 'phone':
        invalidErr = invalidPhone(data.value);
        break;
      case 'amount':
        invalidErr = invalidSize(data.value.replaceAll('€', '').trim());
        break;
      case 'date':
      invalidErr = data.value === null || data.value.length === 0;
      break;
      default:
        break;
    }
    if (emptyErr) {
      this.setState({ [field]: { ...this.state[field], err: true, msg: fieldType === 'date' ? 'Ungültiges Einzugsdatum' : `${lang.general.text_enter} ${fieldName}` } });
      return true;
    } else if (invalidErr) {
      this.setState({ [field]: { ...this.state[field], err: true, msg: fieldType === 'date' ? 'Ungültiges Einzugsdatum' : `${lang.general.text_invalid} ${fieldName}` } });
      return true;
    } else {
      this.setState({ [field]: { ...this.state[field], err: false, msg: '' } });
      return false;
    }
  };
  validateMoveOutDate = () => {
    // // ;
    const { moveIn, moveOut } = this.state;
    if (moveOut.value === '') {
      console.log('empty', moveOut);

      return false;
    }
    console.log('dates', moveIn, moveOut);
    // // ;
    console.log('check date', moment(moveOut.value).isSameOrAfter(moment(moveIn.value)));
    if (moment(moveOut.value).isSameOrAfter(moment(moveIn.value))) {
      this.setState({ moveOut: { ...moveOut, err: false, msg: '' } });
      return false;
    } else {
      this.setState({ moveOut: { ...moveOut, err: true, msg: `${lang.general.text_invalid} move out date` } });
      return true;
    }
  };

  handleSubmit = (e) => {
    const { fullname, num, email, moveIn, moveOut, net, advance } = this.state;
    console.log('moveIn: ', moveIn);
    e.preventDefault();
    let nameErr = this.validateData(fullname, 'fullname', 'full name');
    let phoneErr = false;
    // let phoneErr = this.validateData(num, 'num', 'phone number', 'phone');
    let emailErr = false;
    // let emailErr = this.validateData(email, 'email', 'email address', 'email');
    let netErr = this.validateData(net, 'net', 'net rent', 'amount');
    let advanceErr = this.validateData(advance, 'advance', 'advance', 'amount');
    let startErr = this.validateData(moveIn, 'moveIn', lang.tenant.text_move_in_date, 'date');
    // // ;
    let endErr = this.validateMoveOutDate();
    console.log('endErr', endErr);

    if (nameErr || phoneErr || emailErr || netErr || advanceErr || startErr || endErr) {
      console.log('invalid data');
      // alert('Invalid data');
    } else {
      this.addTenantApi();
      this.props.showAllTenants(true);
    }
  };

  //api call function
  addTenantApi = () => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    console.log('rental unit id', this.props.rentalId);
    let btnelementcancel = document.getElementById('add-tenant-modal-footer-cancel');
    let btnelementadd = document.getElementById('add-tenant-modal-footer-add');

    const { fullname, num, email, moveIn, moveOut, type, notes } = this.state;
    // // ;
    let data = JSON.stringify({
      tenantId: null,
      rentalUnitId: this.props.rentalId,
      fullName: fullname.value,
      contractType: type,
      vacant: false,
      phone: num.value,
      email: email.value,
      moveInDate: moveIn.value == '' ? '' : moment(moveIn.value).format('YYYY-MM-DDTHH:mm:ss'),
      moveOutDate:
        moveOut.value == ''
          ? null //moment(moveIn.value).format('YYYY-MM-DDTHH:mm:ss')
          : moment(moveOut.value).format('YYYY-MM-DDTHH:mm:ss'),
      notes: notes,
    });
    console.log('data api add tenant', data);

    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'tenants',
      'POST',
      data,
      btnelementadd.children[0],
      false,
      true,
      false,
      this,
      'handleAddTenant',
      { cancelbtn: btnelementcancel.children[0] },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handleAddTenant = (response) => {
    console.log(response, 'add tenant');
    if (response.status === false) {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
      var loader = document.getElementById('hello-housing-loader');
      loader.style.display = 'none';
    } else {
      //call api for rent post
      const { net, advance } = this.state;
      let data = JSON.stringify({
        rentId: null,
        tenantId: response.result.id,
        startDate: moment(this.state.moveIn.value).format('YYYY-MM-DDTHH:mm:ss'), //moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
        // endDate: moment(this.state.moveOut.value === '' ? this.state.moveIn.value : this.state.moveOut.value).format(
        //   'YYYY-MM-DDTHH:mm:ss'
        // ), //moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
        endDate: this.state.moveOut.value ? moment(this.state.moveOut.value).format('YYYY-MM-DDTHH:mm:ss') : null,
        netRent: Autoload.MainHelper.ConverterLocaleNumber(net.value, 'de'),
        prePayments: Autoload.MainHelper.ConverterLocaleNumber(advance.value, 'de'),
      });
      window.Intercom("update", {
        api_base: "https://api-iam.intercom.io",
        app_id: "vvrs5bx3",
        'Tenant Created': true
      });
      window.Intercom('trackEvent', 'tenant-created');
      // console.log('rent api data', data)
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.property_api_base_url + 'rents',
        'POST',
        data,
        null,
        false,
        true,
        false,
        this,
        'handleAddRentResponse',
        { cancelbtn: response.other.cancelbtn, tenantId: response.result.id },
        false,
        '',
        {
          'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    }
  };
  handleAddRentResponse = (response) => {
    if (response.status === false) {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
      var loader = document.getElementById('hello-housing-loader');
      loader.style.display = 'none';
    } else {
      // // ;
      if (this.state.fileArray.length > 0) {
        // let files = this.state.fileArray.map((file)=> {
        //   return {file, tenantId: response.other.tenantId}
        // });
        // this.props.setTenantFiles(files)
        // this.props.setAddTenantDocument(true);
        // var elem = document.getElementById("uploadTenantDocs");
        // if (typeof elem.onclick == "function") {
        //     elem.onclick.apply(elem);
            
        //     // response.other.cancelbtn.click();
        //     // this.props.updateStore();
        //     var loader = document.getElementById('hello-housing-loader');
        //     loader.style.display = 'none';
            
        //     this.props.toggle();
        // }

       this.state.fileArray.forEach((file, i) => {
        //   //call doc post api
        // let fileData = new FormData();
        // fileData.append('tenantId', response.other.tenantId);
        // fileData.append('file', file);
          
          

        //   // console.log('doc api data', fileData);
        this.uploadDocument(file, response.other.tenantId, response.other.cancelbtn);
        // Autoload.HttpRequestAxios.HttpRequest(
        //   Autoload.Setting.property_api_base_url + 'tenantDocuments',
        //   'POST',
        //   fileData,
        //   null,
        //   false,
        //   true,
        //   false,
        //   this,
        //   'handleAddDocResponse',
        //   { cancelbtn: response.other.cancelbtn },
        //   false,
        //   '',
        //   {
        //     'Content-Type': 'application/json',
        //     Authorization: Autoload.Utils.getAuthToken(),
        //   }
        // );


        //   //pass a prop to api handler function to check when loop is done so that we can close modal
        });
      } else {
        response.other.cancelbtn.click();
        this.props.updateStore();

        loader = document.getElementById('hello-housing-loader');
        loader.style.display = 'none';
      }
    }
  };
  uploadDocument=(file, tenantId, cancelbtn)=>{
    const {propertySelected} = this.props
    if(file){
      try{
          const data = new FormData();
          data.append('file', file);
          data.append('accountId', propertySelected.accountId);
          data.append('propertyId', propertySelected.id);
          data.append('name', file.name);
          data.append('isDirectory', false);
          data.append('isReadOnly', false);
          data.append('isShared', false);
          data.append('fileType', 'TenantDocument');
          data.append('referencedObjectId', tenantId);
          //currentFolderId !== null && currentFolderId !== 0 && data.append('parentDirectoryId', currentFolderId);
    
          Autoload.HttpRequestAxios.HttpRequest(
            Autoload.Setting.document_api_base_url + 'documents',
            'POST',
            data,
            null,
            false,
            true,
            false,
            this,
            'handleAddDocResponse',
            { cancelbtn: cancelbtn },
            false,
            '',
            {
              Authorization: Autoload.Utils.getAuthToken(),
            }
          );
      }catch(e){
        console.log('myWorkerError_loop:', e);
      }
    }
  }
  handleAddDocResponse = (response) => {
    // // ;
    console.log('add doc res', response);
    if (response.status === false) {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
      var loader = document.getElementById('hello-housing-loader');
      loader.style.display = 'none';
    } else {
      response.other.cancelbtn.click();
      this.props.updateStore();

      loader = document.getElementById('hello-housing-loader');
      loader.style.display = 'none';
    }
  };
  handleEnterKeyPress = (e) => {
    if (e.charCode == 13) {
      e.preventDefault();
      this.handleSubmit(e);
    }
  };

  handleMouseEnter=(type)=>{
    this.setState({[type]: true});
  }

  handleMouseLeave=(type)=>{
      this.setState({[type]: false});
  }

  render() {
    const { className, open, toggle, currentSubscription, accountData, isMobile } = this.props;
    const { fullname, num, email, moveIn, moveOut, type, net, advance, hover1, hover2, hover3, hoverOnStarter } = this.state;
    var moveOutDateRestriction = new Date(this.state.moveIn.value);
    moveOutDateRestriction.setDate(moveOutDateRestriction.getDate() + 1);
    var moveInDateRestriction = new Date(this.state.moveOut.value);
    moveInDateRestriction.setDate(moveInDateRestriction.getDate() - 1);
    // console.log(this.props.rentalUnits, this.props.unitData, 'add tenant');
    return (
      <CustomModal
        heading={lang.tenant.text_add_tenant}
        headingClassName={'tenant-management-add-tenant'}
        open={open}
        toggle={() => {
          this.resetForm();
          toggle();
        }}
        className={`${className} ${'add-tenant-modal'}`}
        //styleBody={{width: '800px'}}
      >
        <div>
          <Form onSubmit={this.handleSubmit} noValidate>
            <p className="section-heading"> {lang.tenant.text_add_tenant_as}</p>
            <Form.Group>
              <Group noWrap className='tenant-type-selection-add-tenant' style={{overflowX: 'scroll'}}>
                <div>
                  <CardSelection
                  title={'Privater Mieter'}
                  subText={'Privater Mieter oder gewerblicher Mieter ohne MwSt.-Ausweis.'}
                  type={'private'}
                  selectedType={type}
                  onClick={()=> this.onDirectFieldChange('type', 'private')}
                  hover={hover1}
                  hoverName={'hover1'}
                  handleMouseEnter={(hoverName)=> this.handleMouseEnter(hoverName)}
                  handleMouseLeave={(hoverName)=> this.handleMouseLeave(hoverName)}
                  icon={TenantSingleIcon}
                  blueIcon={TenantSingleIconBlue}
                  />
                </div>
                <div>
                  <Popover
                        opened={currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER' && hoverOnStarter}
                        onClose={() => this.setState({hoverOnStarter: false})}
                        position="top"
                        placement="center"
                        withArrow
                        trapFocus={true}
                        closeOnEscape={false}
                        transition="pop-top-left"
                        width={300}
                        zIndex={99999}
                        target={
                          <div onMouseEnter={()=> this.setState({hoverOnStarter: true})} onMouseLeave={()=> this.setState({hoverOnStarter: false})}>
                            <CardSelection
                            title={'Gewerbe'}
                            subText={'Gewerbliche Mieter mit MwSt.-Ausweis verwalten.'}
                            type={'commercial'}
                            selectedType={type}
                            onClick={()=> currentSubscription?.subscriptionKey.split('_')[0] !== 'STARTER' && this.onDirectFieldChange('type', 'commercial')}
                            hover={hover2}
                            hoverName={'hover2'}
                            handleMouseEnter={(hoverName)=> currentSubscription?.subscriptionKey.split('_')[0] !== 'STARTER' && this.handleMouseEnter(hoverName)}
                            handleMouseLeave={(hoverName)=> currentSubscription?.subscriptionKey.split('_')[0] !== 'STARTER' && this.handleMouseLeave(hoverName)}
                            icon={buildingIcon}
                            blueIcon={buildingIconBlue}
                            disabled={currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER'}
                            paidFeature={currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER'}
                            />
                          </div>
                        }
                      >
                        <Stack style={{padding: '10px'}} spacing={15} onMouseEnter={()=> this.setState({hoverOnStarter: true})} onMouseLeave={()=> this.setState({hoverOnStarter: false})}>
                          <Group>
                            <span style={{color: 'rgb(85, 85, 255)', fontSize: '1rem'}}>Gewerbliche Mieter abrechnen</span>
                          </Group>
                          <Group style={{justifyContent: 'center'}} noWrap>
                            <img src={GoldenLockIcon} width='18px'/>
                            <span style={{color: '#5f5f7a', fontSize: '11px', fontWeight: '400'}}>Verwalten Sie gewerbliche Mieter mit MwSt.-Ausweis mit hellohousing <span onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})} style={{textDecoration: 'underline', fontWeight: '500', cursor: 'pointer'}}>Premium</span>.</span>
                          </Group>
                          <Group>
                              <ColorButton
                              text={currentSubscription ? (currentSubscription?.subscriptionName === 'Starter' ? (accountData.isPremiumPlanTested === false ? 'Jetzt freischalten' : 'Jetzt freischalten') : currentSubscription?.subscriptionName === 'Premium' ? (accountData.isProfessionalPlanTested === false ? 'Jetzt freischalten' : 'Jetzt freischalten') : 'Jetzt freischalten') : 'Jetzt freischalten'}
                              onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})}
                              className={'purple-btn'}
                              />
                          </Group>
                        </Stack>
                  </Popover>
                </div>
                <div>
                  <CardSelection
                  title={'Selbstnutzer'}
                  subText={'Selbstnutzer ohne Mietzahlung und Nebenkostenvorauszahlung.'}
                  type={'self'}
                  selectedType={type}
                  onClick={()=> this.onDirectFieldChange('type', 'self')}
                  hover={hover3}
                  hoverName={'hover3'}
                  handleMouseEnter={(hoverName)=> this.handleMouseEnter(hoverName)}
                  handleMouseLeave={(hoverName)=> this.handleMouseLeave(hoverName)}
                  icon={SelfIcon}
                  blueIcon={SelfIconBlue}
                  iconStyle={{width: '25px'}}
                  />
                </div>
              </Group>
            </Form.Group>
            
            <p className="section-heading"> {'Mieterdaten'}</p>
            <Row className="row-margin-top">
              <Col xs={6}>
                <Form.Group>
                  <Form.Label className="form-check-label-tenant"> {type === 'commercial' ? 'Firma' : lang.general.text_full_name} </Form.Label>
                  <Form.Control
                    id="tenant-full-name-modal"
                    name="tenant-full-name-modal"
                    isInvalid={fullname.err}
                    type="text"
                    placeholder=""
                    value={fullname.value}
                    // onFocus={() => this.onTouch('fullname')}
                    // onBlur={() => this.onTextBlur(fullname, 'fullname', 'full name')}
                    onChange={(e) => this.onInputChange('fullname', e.target.value)}
                    onKeyPress={this.handleEnterKeyPress}
                  />
                  <Form.Control.Feedback className="err" type="invalid">
                    {' '}
                    {/* {fullname.msg}{' '} */}
                    Eingabe Vor- und Nachname
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group>
                  <Form.Label className="form-check-label-tenant"> {lang.general.text_phone_number} </Form.Label>
                  <Form.Control
                    id="tenant-phone-modal"
                    name="tenant-phone-modal"
                    isInvalid={num.err}
                    type="text"
                    placeholder=""
                    value={num.value}
                    // onFocus={() => this.onTouch('num')}
                    // onBlur={() => this.onTextBlur(num, 'num', 'phone number', 'phone')}
                    onChange={(e) => this.onInputChange('num', e.target.value)}
                    onKeyPress={this.handleEnterKeyPress}
                  />
                  <Form.Control.Feedback className="err" type="invalid">
                    {' '}
                    {num.msg}{' '}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="row-margin-top">
              <Col xs={12}>
                <Form.Group>
                  <Form.Label className="form-check-label-tenant"> {lang.general.text_email} </Form.Label>
                  <Form.Control
                    id="tenant-email-modal"
                    name="tenant-email-modal"
                    isInvalid={email.err}
                    type="email"
                    placeholder=""
                    value={email.value}
                    // onFocus={() => this.onTouch('email')}
                    // onBlur={() => this.onTextBlur(email, 'email', 'email address', 'email')}
                    onChange={(e) => this.onInputChange('email', e.target.value)}
                    onKeyPress={this.handleEnterKeyPress}
                  />
                  <Form.Control.Feedback className="err" type="invalid">
                    {' '}
                    {email.msg}{' '}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <p className="section-heading occupancy-dates"> {lang.tenant.text_occupancy_dates} </p>
            <Row className="row-margin-top">
              <Col xs={6}>
                <Form.Group>
                  <Form.Label className="form-check-label-tenant"> {lang.tenant.text_move_in_date} </Form.Label>
                  <div className={`calendar-input ${moveIn.err ? 'calender-invalid' : ''}`}>
                    <img src={calendar} alt="calendar" />
                    <DatePicker
                      locale="de"
                      withPortal={isMobile}
                      formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
                      dateFormat="dd.MM.yyyy"
                      customInput={<Form.Control required type="text" placeholder="TT.MM.JJJJ" />}
                      style={{ display: 'block' }}
                      placeholderText={!moveIn.isSelected && 'TT.MM.JJJJ'}
                      selected={moveIn.isSelected && moveIn.value}
                      onChange={(e) => this.onDateChange('moveIn', e)}
                      minDate={new Date(this.state.setDate)}
                      maxDate={moveOut.isSelected && moveInDateRestriction}
                      popperClassName={'tenant-move-in-date-picker-popper'}
                    />
                  </div>
                  {moveIn.err && <div className="error err "> {moveIn.msg} </div>}
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group>
                  <Form.Label className="form-check-label-tenant"> {lang.tenant.text_move_out_date} </Form.Label>
                  <div className="calendar-input">
                    <img src={calendar} alt="calendar" />
                    <DatePicker
                      locale="de"
                      withPortal={isMobile}
                      formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
                      dateFormat="dd.MM.yyyy"
                      customInput={<Form.Control required type="text" placeholder="TT.MM.JJJJ" />}
                      style={{ display: 'block !important' }}
                      placeholderText={!moveOut.isSelected && 'TT.MM.JJJJ'}
                      selected={moveOut.isSelected && moveOut.value}
                      onChange={(e) => this.onDateChange('moveOut', e)}
                      minDate={moveIn.isSelected ? moveOutDateRestriction : new Date(this.state.moveoutSetDate)}
                      maxDate={this.props.emptyTenantEdit && this.props.emptyTenantGapsData.moveOut && new Date(moment(this.props.emptyTenantGapsData.moveOut, 'DD.MM.YYYY').format('YYYY-MM-DDTHH:mm:ss'))}
                    />
                  </div>
                  {moveOut.err && <div className="error err"> {moveOut.msg} </div>}
                </Form.Group>
              </Col>
            </Row>
            <p className="section-heading">
              {' '}
              {lang.tenant.text_payments}{' '}
              <span className="form-check-label-tenant">
                {'('}
                {lang.tenant.text_billed_monthly}
                {')'}
              </span>{' '}
            </p>
            <Row className="row-margin-top">
              <Col xs={6}>
                <Form.Group>
                  <Form.Label className="form-check-label-tenant"> {lang.tenant.text_net_rent} </Form.Label>
                  <Form.Control
                    type="text"
                    id="net-rent-modal"
                    name="net-rent-modal"
                    isInvalid={net.err}
                    placeholder="0,00€"
                    value={net.value}
                    onChange={(e) => this.onInputChange('net', e.target.value)}
                    // onFocus={(e) => this.removeSymbol('net',e.target.value, '€')}
                    // onBlur={(e) => this.addSymbol('net',e.target.value, '€')}
                    onBlur={(e) =>
                      this.onInputChange(
                        'net',
                        Autoload.MainHelper.ConverterCurrencyDe(this.state.net.value + '', true, 'de')
                      )
                    }
                    onKeyPress={this.handleEnterKeyPress}
                    disabled={type === 'self'}
                  />
                  <Form.Control.Feedback className="err" type="invalid">
                    {/* {' '}
                    {net.msg}{' '} */}
                    Eingabe Kaltmiete{' '}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group>
                  <Form.Label className="form-check-label-tenant"> {lang.tenant.text_advance_payments} </Form.Label>
                  <Form.Control
                    type="text"
                    id="adv-rent-modal"
                    name="adv-rent-modal"
                    isInvalid={advance.err}
                    placeholder="0,00€"
                    value={advance.value}
                    onChange={(e) => this.onInputChange('advance', e.target.value)}
                    // onFocus={(e) => this.removeSymbol('advance',e.target.value, '€')}
                    // onBlur={(e) => this.addSymbol('advance',e.target.value, '€')}
                    onBlur={(e) =>
                      this.onInputChange(
                        'advance',
                        Autoload.MainHelper.ConverterCurrencyDe(this.state.advance.value + '', true, 'de')
                      )
                    }
                    onKeyPress={this.handleEnterKeyPress}
                    disabled={type === 'self'}
                  />
                  <Form.Control.Feedback className="err" type="invalid">
                    Eingabe Vorauszahlung
                    {/* {advance.msg} */}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="tenant-doc">
                  <p className="section-heading"> {lang.tenant.text_documents} </p>
                  <DropFile
                    className="dropfile-tenant"
                    // customProps={{ multiple: true }}
                    accept={null}
                    //accept={'audio/*,video/*,image/*,application/*,application/pdf,text/*,.csv,.zip,.docx,.doc,.xls,.xlm,.dbf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pptx,.ppt'}
                    handleDrop={this.handleFileChange}
                    file={this.state.fileArray}
                    removeFile={this.removeFile}
                    hideSecondText={true}
                    maxSize={100000000}
                    multiSelect={true}
                  />
                  {this.state.selectedFileErr.state && (
                    <ValueText className="error" text={this.state.selectedFileErr.msg} />
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Form.Group>
                  <Form.Label className="form-check-label-tenant"> {lang.tenant.text_notes} </Form.Label>
                  <Form.Control
                    id="tenant-note-modal"
                    name="tenant-note-modal"
                    // isInvalid={false}
                    type="textarea"
                    placeholder=""
                    value={this.state.notes}
                    onChange={(e) => this.onDirectFieldChange('notes', e.target.value)}
                    as="textarea"
                    style={{ height: 108 }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="center-spacing add-tenant-modal-footer" id="add-tenant-modal-footer">
              <div id="add-tenant-modal-footer-cancel">
                <ColorButton
                  onClick={(e) => {
                    e.preventDefault();
                    this.resetForm();
                    toggle();
                  }}
                  className="gray-btn"
                  text={lang.general.text_cancel}
                />
              </div>
              <div id="add-tenant-modal-footer-add">
                <ColorButton
                  buttonConfig={{ type: 'submit' }}
                  className="purple-btn"
                  text={'Hinzufügen'}
                />
              </div>
            </div>
          </Form>
        </div>
        <button style={{display: 'none'}} id='upload'/>
      </CustomModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rentalUnits: state.tenantDataReducer.rentalUnits,
    propertySelected: state.generalReducer.property,
    currentSubscription: state.generalReducer.currentSubscription,
    accountData: state.generalReducer.accountData,
  };
};
const actions = {
  AddUnit,
  EditUnit,
  setTenantFiles,
  setAddTenantDocument
};

export default connect(mapStateToProps, actions)(AddTenantModal);
