import React, { Component } from 'react';
import Autoload from '../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import { showNotification } from '@mantine/notifications';
import CopyIcon from '../../../../neoverv/components/SVGs/copyIcon';
import { Box, Group, Overlay, Stack } from '@mantine/core';
import TenantAccessDetails from './TenantAccessDetails';
import TenantAdministrateConsumption from './TenantAdministrateConsumption';
import TenantPortalPreview from './TenantPortalPreview';
import TenantAdministrationList from './TenantAdministrationList';
import { ColorButton } from '../../../../neoverv';
import TenantAdministrateDocuments from './TenantAdministrateDocuments';
import TenantAdministrateMessages from './TenantAdministrateMessages';
import {
  changeCurrentProperty,
} from '../../../../redux/action/General/generalActions';
import TenantAdministrateTilesSection from '../TenantPortal/TenantAdministrateTilesSection';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const MOBILE_SIZE = 992;
const TABLET_SIZE = 1420;

export class TenantAdministration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= MOBILE_SIZE,
      isTablet: window.innerWidth <= TABLET_SIZE,
      propertyData: null
    };

  }

  componentDidMount = () => {
    window.addEventListener('resize', this.handleResize);
    this.getPropertyData();
  };

  getPropertyData =()=>{
    Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.property_api_base_url + 'properties/' + this.props.propertySelected.id,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        'handle_get_property_data',
        null,
        false,
        '',
        {
          'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
  }

  handle_get_property_data(response){
    if(response.status && response.statuscode === 200){
      this.setState({propertyData: response.result}, ()=> this.updateProperties(response.result));
    }
  }

  updateProperties(updated_property_response) {
    let {propertySelected} = this.props;
    propertySelected={
      ...propertySelected,
      ...updated_property_response,
      propertyName: Autoload.MainHelper.checkProperty(updated_property_response, 'name'),
      name: Autoload.MainHelper.checkProperty(updated_property_response, 'name'),
      propertyAdress: Autoload.MainHelper.checkProperty(updated_property_response, 'propertyAdress'),
      propertyAddress: Autoload.MainHelper.checkProperty(
        Autoload.MainHelper.checkProperty(updated_property_response, 'propertyAdress'),
        'streetno'
      ),
      propertyCity: Autoload.MainHelper.checkProperty(
        Autoload.MainHelper.checkProperty(updated_property_response, 'propertyAdress'),
        'city'
      ),
      propertyZipCode: Autoload.MainHelper.checkProperty(
        Autoload.MainHelper.checkProperty(updated_property_response, 'propertyAdress'),
        'zipCode'
      ),
      propertyNoOfUnits: Autoload.MainHelper.checkProperty(updated_property_response, 'numberOfRentalUnits'),
      propertyAreaOfUnits: Autoload.MainHelper.checkProperty(updated_property_response, 'totalRentingSpace'),
      totalRentingSpace: Autoload.MainHelper.checkProperty(updated_property_response, 'totalRentingSpace'),
      numberOfRentalUnits: Autoload.MainHelper.checkProperty(updated_property_response, 'numberOfRentalUnits'),
      cutOffDate: {
        day: 
          Autoload.MainHelper.checkProperty(
          Autoload.MainHelper.checkProperty(updated_property_response, 'cutOffDate'),
          'day',
          '31'
          ),
        month: 
          parseInt(
            Autoload.MainHelper.checkProperty(
              Autoload.MainHelper.checkProperty(updated_property_response, 'cutOffDate'),
              'month',
              '12'
            )
          )
      },
      pictureLocation: Autoload.MainHelper.checkProperty(updated_property_response, 'pictureLocation'),
      totalOccupantsCount: Autoload.MainHelper.checkProperty(updated_property_response, 'totalOccupantsCount'),
      isConsumptionSectionHidden:	Autoload.MainHelper.checkProperty(updated_property_response, 'isConsumptionSectionHidden'),
      isDocumentSectionHidden:	Autoload.MainHelper.checkProperty(updated_property_response, 'isDocumentSectionHidden'),
      isMessageSectionHidden:	Autoload.MainHelper.checkProperty(updated_property_response, 'isMessageSectionHidden'),
    }

    this.props.changeCurrentProperty(propertySelected);
  }

  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
    if (window.innerWidth <= TABLET_SIZE) {
      this.setState({ isTablet: true });
    } else {
      this.setState({ isTablet: false });
    }
  };
  
  render() {
    const {isMobile, isTablet, propertyData} = this.state;
    const {currentSubscription, accountData, propertySelected} = this.props;
    console.log('propertySelected: ', propertySelected);
    return (
      <Box sx={{ height: '100%', position: 'relative', marginTop: currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER' && '40px'}}>
        {(currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER' || currentSubscription?.subscriptionKey.split('_')[0] === 'PREMIUM') &&
            <Stack align={'center'} style={{ zIndex: 50, position: 'absolute', left: 0, right: 0, marginRight: 'auto', marginLeft: 'auto', marginTop: '200px'}}>
              <Stack spacing={0}>
                <span style={{color: '#0E0E1D', fontSize: '25px', fontWeight: 'bold', textAlign: 'center'}}>Ihr eigenes Mieterportal bereitstellen</span>
                <span style={{color: '#5555FF', fontSize: '25px', fontWeight: 'bold', textAlign: 'center'}}>Mit hellohousing Professional.</span>
              </Stack>
              <Group>
                <div>
                  <ColorButton
                  text={currentSubscription ? (currentSubscription?.subscriptionName === 'Starter' ? (accountData.isPremiumPlanTested === false ? 'Jetzt freischalten' : 'Jetzt freischalten') : currentSubscription?.subscriptionName === 'Premium' ? (accountData.isProfessionalPlanTested === false ? 'Jetzt freischalten' : 'Jetzt freischalten') : 'Jetzt freischalten') : 'Jetzt freischalten'}
                  className='purple-btn'
                  onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})}
                  />
                </div>
              </Group>
              <Group style={{width: '460px'}}>
                <span style={{color: '#615C7E', fontSize: '12px', textAlign: 'center'}}>Richten Sie mit wenigen Klicks Ihr eigenes Mieterportal ein und stellen Sie Ihren Mietern monatlich Verbrauchsinformationen bereit.</span>
              </Group>
              <Group>
                <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px', cursor: 'pointer'}} onClick={()=> window.Intercom('showArticle', '7238555')}>Mieterportal kennenlernen</span>
              </Group>
            </Stack>}
          {(currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER' || currentSubscription?.subscriptionKey.split('_')[0] === 'PREMIUM') && 
          <Overlay opacity={0.5} blur={4} zIndex={5} color={'#F7F7FA'}/>}
          <Stack align={'flex-start'}>
              <Group grow position='left' style={{width: '100%'}}>
                  <TenantAdministrationList history={this.props.history} isMobile={isMobile} isTablet={isTablet} />
              </Group>
              <Group grow position='left' style={{width: '100%'}}>
                  <TenantAdministrateConsumption propertyData={propertyData} isMobile={isMobile} history={this.props.history} isTablet={isTablet} getPropertyData={()=> this.getPropertyData()} />
              </Group>
              <Group grow position='left' style={{width: '100%'}}>
                  <TenantAdministrateDocuments propertyData={propertyData} isMobile={isMobile} history={this.props.history} isTablet={isTablet} getPropertyData={()=> this.getPropertyData()} />
              </Group>
              <Group grow position='left' style={{width: '100%'}}>
                  <TenantAdministrateMessages propertyData={propertyData} isMobile={isMobile} history={this.props.history} isTablet={isTablet} getPropertyData={()=> this.getPropertyData()} />
              </Group>
              <Group grow position='left' style={{width: '100%'}}>
                  <TenantAdministrateTilesSection propertyData={propertyData} isMobile={isMobile} history={this.props.history} isTablet={isTablet} getPropertyData={()=> this.getPropertyData()} />
              </Group>
          </Stack>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
    currentSubscription: state.generalReducer.currentSubscription,
    accountData: state.generalReducer.accountData
  };
};

const actions = {
  changeCurrentProperty
}

export default connect(mapStateToProps, actions)(TenantAdministration);
